import React, { useState } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputTextarea } from 'primereact/inputtextarea';
import { Card } from 'primereact/card';
import { InputSwitch } from 'primereact/inputswitch';
import { Message } from 'primereact/message';

const UserSettingsForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    // Financial Settings
    incomeTaxRate: 24,
    downPayment: 25,
    loanTerm: 30,
    holdingPeriod: 30,
    monthlyInvestmentBudget: 5000,
    
    // Investment Preferences
    propertyTypes: [],
    investmentStrategy: 'buy_and_hold',
    targetRoi: 12,
    maxPurchasePrice: 500000,
    preferredLocations: '',
    
    // Risk Profile
    riskTolerance: 'moderate',
    maxLeverageRatio: 75,
    
    // Alert Thresholds
    capRateThreshold: 7,
    cashOnCashThreshold: 8,
    priceDropAlertPercentage: 5,
    
    // Property Characteristics
    minBedrooms: 3,
    minBathrooms: 2,
    minSquareFeet: 1000,
    propertyAge: 'any',
    
    // Notifications
    emailAlerts: true,
    pushNotifications: true,
    alertFrequency: 'daily',
    
    // Additional Preferences
    renovationPreference: 'move_in_ready',
    propertyManagementPreference: 'self_managed',
    notes: ''
  });

  const propertyTypes = [
    { label: 'Single Family', value: 'single_family' },
    { label: 'Multi Family', value: 'multi_family' },
    { label: 'Commercial', value: 'commercial' },
    { label: 'Mixed Use', value: 'mixed_use' },
    { label: 'Industrial', value: 'industrial' },
    { label: 'Vacation Rental', value: 'vacation_rental' },
    { label: 'Mobile Home', value: 'mobile_home' },
    { label: 'Land', value: 'land' }
  ];

  const investmentStrategies = [
    { label: 'Buy and Hold', value: 'buy_and_hold' },
    { label: 'Fix and Flip', value: 'fix_and_flip' },
    { label: 'BRRRR Strategy', value: 'brrrr' },
    { label: 'Wholesale', value: 'wholesale' },
    { label: 'Rental Income', value: 'rental_income' },
    { label: 'Value Add', value: 'value_add' }
  ];

  const riskToleranceOptions = [
    { label: 'Conservative', value: 'conservative' },
    { label: 'Moderate', value: 'moderate' },
    { label: 'Aggressive', value: 'aggressive' }
  ];

  const handleInputChange = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError('');
    setSuccess(false);
  };

  const validateForm = () => {
    if (!formData.incomeTaxRate || !formData.downPayment) {
      setError('Required fields are missing');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsLoading(true);
    
    try {
      const response = await fetch('/api/settings', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (!response.ok) throw new Error('Failed to save settings');
      setSuccess(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const renderFinancialSettings = () => (
    <div className="grid">
      <div className="col-12 md:col-6">
        <div className="field">
          <label htmlFor="incomeTaxRate" className="font-bold block mb-2">
            Income Tax Rate (%)
          </label>
          <InputNumber
            id="incomeTaxRate"
            value={formData.incomeTaxRate}
            onValueChange={(e) => handleInputChange('incomeTaxRate', e.value)}
            mode="decimal"
            min={0}
            max={100}
            className="w-full"
          />
        </div>
      </div>
      <div className="col-12 md:col-6">
        <div className="field">
          <label htmlFor="downPayment" className="font-bold block mb-2">
            Down Payment (%)
          </label>
          <InputNumber
            id="downPayment"
            value={formData.downPayment}
            onValueChange={(e) => handleInputChange('downPayment', e.value)}
            mode="decimal"
            min={0}
            max={100}
            className="w-full"
          />
        </div>
      </div>
      <div className="col-12 md:col-6">
        <div className="field">
          <label htmlFor="monthlyInvestmentBudget" className="font-bold block mb-2">
            Monthly Investment Budget
          </label>
          <InputNumber
            id="monthlyInvestmentBudget"
            value={formData.monthlyInvestmentBudget}
            onValueChange={(e) => handleInputChange('monthlyInvestmentBudget', e.value)}
            mode="currency"
            currency="USD"
            className="w-full"
          />
        </div>
      </div>
      <div className="col-12 md:col-6">
        <div className="field">
          <label htmlFor="maxPurchasePrice" className="font-bold block mb-2">
            Maximum Purchase Price
          </label>
          <InputNumber
            id="maxPurchasePrice"
            value={formData.maxPurchasePrice}
            onValueChange={(e) => handleInputChange('maxPurchasePrice', e.value)}
            mode="currency"
            currency="USD"
            className="w-full"
          />
        </div>
      </div>
    </div>
  );

  const renderInvestmentPreferences = () => (
    <div className="grid">
      <div className="col-12">
        <div className="field">
          <label htmlFor="investmentStrategy" className="font-bold block mb-2">
            Investment Strategy
          </label>
          <Dropdown
            id="investmentStrategy"
            value={formData.investmentStrategy}
            options={investmentStrategies}
            onChange={(e) => handleInputChange('investmentStrategy', e.value)}
            className="w-full"
          />
        </div>
      </div>
      <div className="col-12">
        <div className="field">
          <label htmlFor="riskTolerance" className="font-bold block mb-2">
            Risk Tolerance
          </label>
          <Dropdown
            id="riskTolerance"
            value={formData.riskTolerance}
            options={riskToleranceOptions}
            onChange={(e) => handleInputChange('riskTolerance', e.value)}
            className="w-full"
          />
        </div>
      </div>
      <div className="col-12">
        <div className="field">
          <label htmlFor="targetRoi" className="font-bold block mb-2">
            Target ROI (%)
          </label>
          <InputNumber
            id="targetRoi"
            value={formData.targetRoi}
            onValueChange={(e) => handleInputChange('targetRoi', e.value)}
            mode="decimal"
            min={0}
            className="w-full"
          />
        </div>
      </div>
      <div className="col-12">
        <div className="field">
          <label htmlFor="preferredLocations" className="font-bold block mb-2">
            Preferred Locations
          </label>
          <InputTextarea
            id="preferredLocations"
            value={formData.preferredLocations}
            onChange={(e) => handleInputChange('preferredLocations', e.target.value)}
            rows={3}
            className="w-full"
          />
        </div>
      </div>
    </div>
  );

  const renderAlertSettings = () => (
    <div className="grid">
      <div className="col-12 md:col-6">
        <div className="field">
          <label htmlFor="capRateThreshold" className="font-bold block mb-2">
            Cap Rate Threshold (%)
          </label>
          <InputNumber
            id="capRateThreshold"
            value={formData.capRateThreshold}
            onValueChange={(e) => handleInputChange('capRateThreshold', e.value)}
            mode="decimal"
            min={0}
            className="w-full"
          />
        </div>
      </div>
      <div className="col-12 md:col-6">
        <div className="field">
          <label htmlFor="cashOnCashThreshold" className="font-bold block mb-2">
            Cash on Cash Threshold (%)
          </label>
          <InputNumber
            id="cashOnCashThreshold"
            value={formData.cashOnCashThreshold}
            onValueChange={(e) => handleInputChange('cashOnCashThreshold', e.value)}
            mode="decimal"
            min={0}
            className="w-full"
          />
        </div>
      </div>
      <div className="col-12">
        <div className="field">
          <div className="flex align-items-center justify-content-between">
            <label className="font-bold">Email Alerts</label>
            <InputSwitch
              checked={formData.emailAlerts}
              onChange={(e) => handleInputChange('emailAlerts', e.value)}
            />
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="field">
          <div className="flex align-items-center justify-content-between">
            <label className="font-bold">Push Notifications</label>
            <InputSwitch
              checked={formData.pushNotifications}
              onChange={(e) => handleInputChange('pushNotifications', e.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const renderPreferences = () => (
    <div className="grid">
      <div className="col-12 md:col-6">
        <div className="field">
          <label htmlFor="minBedrooms" className="font-bold block mb-2">
            Minimum Bedrooms
          </label>
          <InputNumber
            id="minBedrooms"
            value={formData.minBedrooms}
            onValueChange={(e) => handleInputChange('minBedrooms', e.value)}
            mode="decimal"
            min={0}
            className="w-full"
          />
        </div>
      </div>
      <div className="col-12 md:col-6">
        <div className="field">
          <label htmlFor="minBathrooms" className="font-bold block mb-2">
            Minimum Bathrooms
          </label>
          <InputNumber
            id="minBathrooms"
            value={formData.minBathrooms}
            onValueChange={(e) => handleInputChange('minBathrooms', e.value)}
            mode="decimal"
            min={0}
            className="w-full"
          />
        </div>
      </div>
      <div className="col-12">
        <div className="field">
          <label htmlFor="propertyManagementPreference" className="font-bold block mb-2">
            Property Management Preference
          </label>
          <Dropdown
            id="propertyManagementPreference"
            value={formData.propertyManagementPreference}
            options={[
              { label: 'Self Managed', value: 'self_managed' },
              { label: 'Property Manager', value: 'property_manager' },
              { label: 'Hybrid Approach', value: 'hybrid' }
            ]}
            onChange={(e) => handleInputChange('propertyManagementPreference', e.value)}
            className="w-full"
          />
        </div>
      </div>
      <div className="col-12">
        <div className="field">
          <label htmlFor="notes" className="font-bold block mb-2">
            Additional Notes
          </label>
          <InputTextarea
            id="notes"
            value={formData.notes}
            onChange={(e) => handleInputChange('notes', e.target.value)}
            rows={3}
            className="w-full"
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex align-items-center justify-content-center">
<form onSubmit={handleSubmit} className="p-fluid">
      <TabView>
        <TabPanel header="Financial Settings">
          <Card title="Financial Settings" subTitle="Configure your basic financial parameters">
            {renderFinancialSettings()}
          </Card>
        </TabPanel>
        <TabPanel header="Investment Preferences">
          <Card title="Investment Strategy" subTitle="Define your investment approach and preferences">
            {renderInvestmentPreferences()}
          </Card>
        </TabPanel>
        <TabPanel header="Alerts & Thresholds">
          <Card title="Alert Thresholds" subTitle="Set your notification preferences and thresholds">
            {renderAlertSettings()}
          </Card>
        </TabPanel>
        <TabPanel header="Additional Preferences">
          <Card title="Additional Preferences" subTitle="Set your property and management preferences">
            {renderPreferences()}
          </Card>
        </TabPanel>
      </TabView>

      {error && <Message severity="error" text={error} className="w-full mt-4" />}
      {success && <Message severity="success" text="Settings saved successfully!" className="w-full mt-4" />}

      <Button 
        type="submit" 
        label={isLoading ? 'Saving...' : 'Save All Settings'} 
        icon={isLoading ? 'pi pi-spinner pi-spin' : 'pi pi-save'}
        disabled={isLoading}
        className="w-full mt-4"
      />
    </form>
    </div>
    
  );
};

export default UserSettingsForm;