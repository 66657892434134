import React from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const ReturnComposition = ({ returnMetrics = {} }) => {
  const chartData = [{
    name: 'Return Components',
    cashflow: returnMetrics?.cashflowReturn || 0,
    appreciation: returnMetrics?.appreciationReturn || 0,
    equity: returnMetrics?.equityReturn || 0
  }];

  return (
   <div className="surface-card p-4 border-round shadow-1">
    <div className="flex justify-content-between align-items-center mb-4">
    <h2 className="text-xl font-medium mb-3">Return Composition</h2>
    </div>

      <div className="surface-card p-3 border-round">
        <div style={{ width: '100%', height: '400px' }}>
          <ResponsiveContainer>
            <BarChart
              data={chartData}
              margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
            >
              <CartesianGrid 
                strokeDasharray="3 3" 
                stroke="#f0f0f0"
              />
              <XAxis 
                dataKey="name"
              />
              <YAxis 
                formatter={(value) => `${value.toFixed(1)}%`}
                tick={{ fontSize: 12 }}
                label={{ 
                  value: 'Return (%)', 
                  angle: -90, 
                  position: 'insideLeft',
                  style: { fontSize: '12px' }
                }}
              />
              <Tooltip 
                formatter={(value) => [`${value.toFixed(1)}%`, '']}
                contentStyle={{ fontSize: 12 }}
              />
              <Legend />
              <Bar 
                dataKey="cashflow" 
                fill="#4338ca" 
                name="Cash Flow Return"
              />
              <Bar 
                dataKey="appreciation" 
                fill="#16a34a" 
                name="Appreciation Return"
              />
              <Bar 
                dataKey="equity" 
                fill="#eab308" 
                name="Equity Return"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Optional Help Text */}
        <div className="text-center mt-2">
          <span className="text-500 text-xs">* Based on projected returns over the investment period</span>
        </div>
      </div>
    </div>
  );
};

export default ReturnComposition;