import React, { useState, useEffect } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import { Ripple } from 'primereact/ripple';
import { Tooltip } from 'primereact/tooltip';
import './stickymenu.css'

const StickyMenu = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const items = [
    { label: 'Property Details', id: 'property-details' },
    { label: 'Financial Inputs', id: 'financial-inputs' },
    { label: 'Key Metrics', id: 'key-metrics' },
    { label: 'Monthly Analysis', id: 'monthly-analysis' },
    { label: 'Expense Breakdown', id: 'expense-breakdown' },
    { label: '30-Year Projections', id: 'projections' },
    { label: 'Debt Analysis', id: 'debt-analysis' },
    { label: 'Return Analysis', id: 'return-analysis' },
    { label: 'Max Price', id: 'max-price-analysis' }
  ];

  useEffect(() => {
    const handleScroll = () => {
      const sections = items.map(item => 
        document.getElementById(item.id)
      );
      
      const scrollPosition = window.scrollY + 100; // Offset for the sticky header

      sections.forEach((section, index) => {
        if (section) {
          const sectionTop = section.offsetTop;
          const sectionBottom = sectionTop + section.offsetHeight;
          
          if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
            setActiveIndex(index);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (id, index) => {
    const element = document.getElementById(id);
    if (element) {
      const yOffset = -70; // Adjust based on your header height
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
    setActiveIndex(index);
  };

  return (
    <div className="surface-card p-0 sticky top-0 z-5 shadow-1">
      <div className="overflow-x-auto">
        <TabMenu 
          model={items.map((item, index) => ({
            label: item.label,
            command: () => scrollToSection(item.id, index)
          }))}
          activeIndex={activeIndex}
          className="border-none"
        />
      </div>
      <Ripple />
      <Tooltip target=".p-tabmenu .p-menuitem-link" position="bottom" />
    </div>
  );
};

export default StickyMenu;