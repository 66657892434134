 // Options for the Dropdown
 export const statusList = [
    { label: "Active", value: "1" },
    { label: "Sold", value: "2" },
    { label: "All", value: "" },
  ];

  // Options for the Dropdown
  export  const filtersList = [
    { label: "Select", value: "0" },
    { label: "Positive Cash Flow", value: "1" },
    { label: "1 Percent", value: "2" },
  ];

  export  const propertyTypeOptions = [
    { label: "Single Family", value: "1" },
    { label: "Condo", value: "2" },
    { label: "Townhouse", value: "3" },
    { label: "Multi-Family", value: "4" },
  ];