import React, { useRef, useState } from "react";
import http from "../http-common";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast'; // Add this import
import { Loader2 } from "lucide-react";


const Register = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const toast = useRef(null);

  const header = (
    <div className="text-center mt-4 mb-8">
      <h1 className="text-4xl font-bold text-gray-900 mb-2">
        Find Your Next Investment Property
      </h1>
      <p className="text-gray-600 text-lg">
        Join thousands of investors discovering cash-flowing properties daily
      </p>
    </div>
  );

  const handleSubmit = async (e) => {
    try {
      const response = await http.post(
        "/user/register",
        {
          username,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data;

       // Show success message
       toast.current.show({
        severity: 'success',
        summary: 'Registration Successful',
        detail: 'Welcome! Check your email for next steps.',
        life: 3000
      });

      // Store token in localStorage
      localStorage.setItem("token", data.token);
       // Short delay before redirect to ensure user sees the success message
       setTimeout(() => {
        window.location.href = "/search";
      }, 2000);
    } catch (error) {
      console.error("Registration failed:", error);
      toast.current.show({
        severity: 'error',
        summary: 'Registration Failed',
        detail: 'Please try again or contact support.',
        life: 3000
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      {/* <div className="p-grid p-nogutter landing-page">
        <div className="p-col-12 p-md-6 p-lg-6 left-section">
          <h1 className="headline">Supercharge Your Next Research for Cashflowing Rental Property</h1>
        </div>
      </div> */}
      {header}
      <div className="flex align-items-center justify-content-center">
        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          
          <div>
            <label htmlFor="email" className="block text-900 font-medium mb-2">
              Email
            </label>
            <InputText
              id="email"
              type="text"
              placeholder="Email address"
              className="w-full mb-3"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />

            <label
              htmlFor="password"
              className="block text-900 font-medium mb-2"
            >
              Password
            </label>
            <InputText
              id="password"
              type="password"
              placeholder="Password"
              className="w-full mb-3"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              label="Register"
              icon="pi pi-user"
              className="w-full"
              onClick={handleSubmit}
              disabled={isLoading}
            > {isLoading && (
              <Loader2 className="w-5 h-5 animate-spin" />
            )}</Button>
          </div>
          <div className="mt-6 text-center text-gray-600 text-sm">
            Already have an account?{' '}
            <a href="/login" className="text-blue-600 hover:text-blue-700 font-medium">
              Sign in
            </a>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Register;
