import React from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const MaxPriceAnalysis = ({ maxPriceAnalysis, setMaxPriceAnalysis }) => {
  const handleTargetMetricChange = (value, metric) => {
    setMaxPriceAnalysis(prev => ({
      ...prev,
      targetMetrics: {
        ...prev.targetMetrics,
        [metric]: value || 0
      }
    }));
  };

  return (
    <div className="surface-card p-4 border-round shadow-1">
    <div className="flex justify-content-between align-items-center mb-4">
    <h2 className="text-xl font-medium mb-3">Maximum Purchase Price Analysis</h2>
    </div>

      <div className="surface-card p-3 border-round">
        {/* Target Inputs Row */}
        <div className="grid">
          <div className="col-12 md:col-4 p-2">
            <div className="bg-gray-50 p-3 border-round">
              <span className="block text-600 text-sm mb-2">Target Cap Rate (%)</span>
              <InputNumber
                value={maxPriceAnalysis?.targetMetrics?.capRate}
                onValueChange={(e) => handleTargetMetricChange(e.value, 'capRate')}
                mode="decimal"
                minFractionDigits={0}
                maxFractionDigits={2}
                size="small"
                className="w-full"
              />
            </div>
          </div>

          <div className="col-12 md:col-4 p-2">
            <div className="bg-gray-50 p-3 border-round">
              <span className="block text-600 text-sm mb-2">Target Cash on Cash (%)</span>
              <InputNumber
                value={maxPriceAnalysis?.targetMetrics?.cashOnCash}
                onValueChange={(e) => handleTargetMetricChange(e.value, 'cashOnCash')}
                mode="decimal"
                minFractionDigits={0}
                maxFractionDigits={2}
                size="small"
                className="w-full"
              />
            </div>
          </div>

          <div className="col-12 md:col-4 p-2">
            <div className="bg-gray-50 p-3 border-round">
              <span className="block text-600 text-sm mb-2">Target IRR (%)</span>
              <InputNumber
                value={maxPriceAnalysis?.targetMetrics?.irr}
                onValueChange={(e) => handleTargetMetricChange(e.value, 'irr')}
                mode="decimal"
                minFractionDigits={0}
                maxFractionDigits={2}
                size="small"
                className="w-full"
              />
            </div>
          </div>
        </div>

        {/* Results Section */}
        <div className="mt-3">
          <div className="grid">
            <div className="col-12 md:col-3 p-2">
              <div className="bg-green-50 p-3 border-round">
                <span className="block text-600 text-sm">Recommended Price</span>
                <span className="block text-900 text-xl mt-2">
                  ${Math.round(maxPriceAnalysis?.recommendedPrice || 0).toLocaleString()}
                </span>
              </div>
            </div>

            <div className="col-12 md:col-3 p-2">
              <div className="bg-blue-50 p-3 border-round">
                <span className="block text-600 text-sm">Max Price (Cap Rate)</span>
                <span className="block text-900 text-xl mt-2">
                  ${Math.round(maxPriceAnalysis?.maxPrices?.byCapRate || 0).toLocaleString()}
                </span>
              </div>
            </div>

            <div className="col-12 md:col-3 p-2">
              <div className="bg-purple-50 p-3 border-round">
                <span className="block text-600 text-sm">Max Price (Cash on Cash)</span>
                <span className="block text-900 text-xl mt-2">
                  ${Math.round(maxPriceAnalysis?.maxPrices?.byCashOnCash || 0).toLocaleString()}
                </span>
              </div>
            </div>

            <div className="col-12 md:col-3 p-2">
              <div className="bg-indigo-50 p-3 border-round">
                <span className="block text-600 text-sm">Max Price (IRR)</span>
                <span className="block text-900 text-xl mt-2">
                  ${Math.round(maxPriceAnalysis?.maxPrices?.byIRR || 0).toLocaleString()}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Price Sensitivity Analysis */}
        <div className="mt-4">
          <h3 className="text-lg font-medium mb-3">Price Sensitivity Analysis</h3>
          
          <div style={{ width: '100%', height: '400px' }}>
            <ResponsiveContainer>
              <LineChart 
                data={maxPriceAnalysis?.priceRange || []}
                margin={{ top: 20, right: 50, left: 20, bottom: 40 }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                <XAxis 
                  dataKey="variation" 
                  label={{ value: 'Price Variation (%)', position: 'bottom' }}
                />
                <YAxis 
                  yAxisId="left" 
                  label={{ value: 'Cap Rate (%)', angle: -90, position: 'insideLeft' }}
                />
                <YAxis 
                  yAxisId="right" 
                  orientation="right" 
                  label={{ value: 'Cash on Cash (%)', angle: 90, position: 'insideRight' }}
                />
                <Tooltip 
                  formatter={(value) => `${value.toFixed(2)}%`}
                  contentStyle={{ fontSize: 12 }}
                />
                <Legend 
                  verticalAlign="bottom" 
                  height={36}
                  wrapperStyle={{
                    paddingTop: '30px',
                    fontSize: '12px'
                  }}
                />
                <Line 
                  yAxisId="left"
                  type="monotone" 
                  dataKey="capRate" 
                  stroke="#4338ca" 
                  name="Cap Rate"
                  dot={false}
                  strokeWidth={2}
                />
                <Line 
                  yAxisId="right"
                  type="monotone" 
                  dataKey="cashOnCash" 
                  stroke="#16a34a" 
                  name="Cash on Cash"
                  dot={false}
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Optional Help Text */}
        <div className="text-center mt-2">
          <span className="text-500 text-xs">* Adjust target metrics to see maximum purchase prices</span>
        </div>
      </div>
    </div>
  );
};

export default MaxPriceAnalysis;