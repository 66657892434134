import React from 'react';

const ExpenseBreakdown = ({ calculations = {} }) => {
  // Calculate operating expenses only (excluding mortgage)
  const operatingExpenses = calculations.monthlyOperatingExpenses || 0;

  return (
    <div className="surface-card p-4 border-round shadow-1">
      <div className="flex justify-content-between align-items-center mb-4">
        <h2 className="text-xl m-0 font-medium">Monthly Operating Expenses</h2>
        <div className="text-blue-700 font-medium bg-blue-50 px-3 py-1 rounded">
          ${operatingExpenses.toFixed(2)}/mo
        </div>
      </div>

      <div className="grid">
        {/* Left Column */}
        <div className="col-12 md:col-6">
          <div className="surface-100 p-3 border-round mb-3">
            <div className="flex justify-content-between align-items-center">
              <span className="text-700">Property Tax</span>
              <span className="text-900 font-medium">${(calculations.monthlyTaxes || 0).toFixed(2)}</span>
            </div>
          </div>
          
          <div className="surface-100 p-3 border-round mb-3">
            <div className="flex justify-content-between align-items-center">
              <span className="text-700">Maintenance</span>
              <span className="text-900 font-medium">${(calculations.monthlyMaintenance || 0).toFixed(2)}</span>
            </div>
          </div>
          
          <div className="surface-100 p-3 border-round mb-3">
            <div className="flex justify-content-between align-items-center">
              <span className="text-700">Property Management</span>
              <span className="text-900 font-medium">${(calculations.monthlyManagement || 0).toFixed(2)}</span>
            </div>
          </div>

          {calculations.monthlyHOA > 0 && (
            <div className="surface-100 p-3 border-round mb-3">
              <div className="flex justify-content-between align-items-center">
                <span className="text-700">HOA</span>
                <span className="text-900 font-medium">${calculations.monthlyHOA.toFixed(2)}</span>
              </div>
            </div>
          )}
        </div>

        {/* Right Column */}
        <div className="col-12 md:col-6">
          <div className="surface-100 p-3 border-round mb-3">
            <div className="flex justify-content-between align-items-center">
              <span className="text-700">Insurance</span>
              <span className="text-900 font-medium">${(calculations.monthlyInsurance || 0).toFixed(2)}</span>
            </div>
          </div>
          
          <div className="surface-100 p-3 border-round mb-3">
            <div className="flex justify-content-between align-items-center">
              <span className="text-700">Vacancy</span>
              <span className="text-900 font-medium">${(calculations.monthlyVacancy || 0).toFixed(2)}</span>
            </div>
          </div>

          {calculations.monthlyUtilities > 0 && (
            <div className="surface-100 p-3 border-round mb-3">
              <div className="flex justify-content-between align-items-center">
                <span className="text-700">Utilities</span>
                <span className="text-900 font-medium">${calculations.monthlyUtilities.toFixed(2)}</span>
              </div>
            </div>
          )}

          {calculations.monthlyOther > 0 && (
            <div className="surface-100 p-3 border-round mb-3">
              <div className="flex justify-content-between align-items-center">
                <span className="text-700">Other</span>
                <span className="text-900 font-medium">${calculations.monthlyOther.toFixed(2)}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="text-center mt-3">
        <span className="text-500 text-xs">* Estimated monthly expenses based on market averages</span>
      </div>
    </div>
  );
};

export default ExpenseBreakdown;