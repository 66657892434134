import React, { useState, useRef, useEffect } from "react";
import {
  Home,
  TrendingUp,
  Users,
  DollarSign,
  Share2,
  Download,
} from "lucide-react";
import http from "../http-common";
import { useParams, useLocation, useSearchParams } from "react-router-dom";

const PropertyInfluencer = () => {
  const [propertyData, setPropertyData] = useState(null);
  const [calculatedMetrics, setCalculatedMetrics] = useState(null);
  const [demographicsMetrics, setDemographicsMetrics] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const contentRef = useRef(null);
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  //const shareRef = useRef(null);

  // Function to fetch property data
  const fetchPropertyData = async (id) => {
    // Method 3: Using useSearchParams (for query parameters)
    try {
      const response = await http.get(`/extracts/${id}`);
      setPropertyData(response.data);
      calculateMetrics(response.data);
      const propertyData = {
        zipCode: response.data.zipCode,
        city: response.data.city,
        state: response.data.state,
      };

      //getDemographics(propertyData);
    } catch (error) {
      console.error("Error fetching property data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  async function getDemographics(propertyData) {
    try {
      const demographics = await fetchDemographics(propertyData);
      console.log("Demographics data:", demographics);
      return demographics;
    } catch (error) {
      console.error("Failed to fetch demographics:", error);
    }
  }

  const calculateReturns = ({
    purchasePrice,
    downPaymentPercent,
    interestRate,
    loanTermYears,
    monthlyRent,
    vacancyRatePercent,
    propertyTaxRate,
    hoafee,
    monthlyMortgagePayment,
    annualMaintenanceCosts,
    annualVacancyLoss,
  }) => {
    // Use provided mortgage payment
    const monthlyMortgage = monthlyMortgagePayment;

    // Calculate monthly property tax
    const monthlyPropertyTax =
      (purchasePrice * parseFloat(propertyTaxRate?.replace("%", "") || 0)) /
      100 /
      12;

    // Insurance is 0.5% of purchase price annually
    const monthlyInsurance = (purchasePrice * 0.005) / 12;

    // Use provided maintenance and vacancy costs or calculate from percentages
    const monthlyMaintenance = annualMaintenanceCosts
      ? annualMaintenanceCosts / 12
      : monthlyRent * 0.1; // Default to 10% of rent if not provided

    const monthlyVacancy = monthlyRent * (vacancyRatePercent / 100);

    // Property management is 10% of monthly rent
    const monthlyPropertyManagement = monthlyRent * 0.1;

    // Calculate total monthly expenses
    const totalMonthlyExpenses =
      monthlyMortgage +
      monthlyPropertyTax +
      monthlyInsurance +
      monthlyMaintenance +
      monthlyVacancy +
      hoafee +
      monthlyPropertyManagement;

    // Calculate cash flows
    const monthlyCashFlow = monthlyRent - totalMonthlyExpenses;
    const annualCashFlow = monthlyCashFlow * 12;

    // Calculate ROI metrics
    const downPayment = purchasePrice * (downPaymentPercent / 100);
    const cashOnCash = (annualCashFlow / downPayment) * 100;
    const noi = monthlyRent * 12 - totalMonthlyExpenses * 12;
    const capRate = (noi / purchasePrice) * 100;

    return {
      monthlyMortgage,
      monthlyPropertyTax,
      monthlyInsurance,
      monthlyMaintenance,
      monthlyVacancy,
      monthlyHOA: hoafee,
      monthlyPropertyManagement,
      monthlyCashFlow,
      annualCashFlow,
      cashOnCash,
      capRate,
      demographics: {
        populationGrowth: "+1.2%",
        medianIncome: "$67,266",
        medianAge: "33.2",
        employmentRate: "96.2%",
        renterOccupied: "48.2%",
        rentGrowth: "5.4%",
      },
    };
  };

  const calculateDefaultMortgage = (price, interestRate) => {
    const downPaymentPercent = 25;
    const downPayment = price * (downPaymentPercent / 100);
    const loanAmount = price - downPayment;
    const monthlyRate = interestRate / 100 / 12;
    const numberOfPayments = 30 * 12; // 30 year fixed

    const monthlyPayment =
      (loanAmount *
        (monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments))) /
      (Math.pow(1 + monthlyRate, numberOfPayments) - 1);

    return Math.round(monthlyPayment);
  };

  // Calculate all required metrics
  const calculateMetrics = (data) => {

    const monthlyRent = (data.rentalestimate !== null && data.rentalestimate !== "") ? 
  data.rentalestimate :
  (data.fmr !== null && data.fmr !== "" ? data.fmr : 0);

    const returns = calculateReturns({
      purchasePrice: data.price,
      downPaymentPercent: 25,
      interestRate: data.latestMortgageRates?.pmms30 || 7.5,
      loanTermYears: 30,
      monthlyRent: monthlyRent,
      vacancyRatePercent: data.rental_vacancy_rate ?? 6,
      propertyTaxRate: data.propertyTaxes?.Property_Tax_Rate || "0%",
      hoafee: data.hoa ? Number(data.hoa.replace(/[^0-9.-]+/g, "")) : 0,
      monthlyMortgagePayment:
        calculateDefaultMortgage(
          data.price,
          data.latestMortgageRates?.pmms30 || 7.5
        ),
      annualMaintenanceCosts:
         parseFloat(data.fmr) * 12 * 0.1, // 18% of annual rent
      annualVacancyLoss:
         parseFloat(data.fmr) * 12 * data.rental_vacancy_rate, // 6% national vacancy rate
    });

    setCalculatedMetrics({ returns });
  };

  useEffect(() => {
    fetchPropertyData(id);
  }, [id]);

  const generateFullAddress = (propertyData) => {
    if (!propertyData) {
      return "Address not available";
    }

    const addressParts = [];

    // Street address (number + street)
    if (propertyData.streetNumber && propertyData.street) {
      addressParts.push(`${propertyData.streetNumber} ${propertyData.street}`);
    }

    // City
    if (propertyData.city) {
      addressParts.push(propertyData.city);
    }

    // State and ZIP code
    const stateZip = [];
    if (propertyData.state) {
      stateZip.push(propertyData.state);
    }
    if (propertyData.zipCode) {
      stateZip.push(propertyData.zipCode);
    }

    if (stateZip.length > 0) {
      addressParts.push(stateZip.join(" "));
    }

    // Return full address or fallback message
    return addressParts.length > 0
      ? addressParts.join(", ")
      : "Address information incomplete";
  };

  const exportAsJPEG = async () => {
    const script = document.createElement("script");
    script.src =
      "https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js";
    script.async = true;

    script.onload = async () => {
      if (contentRef.current && window.html2canvas) {
        try {
          const canvas = await window.html2canvas(contentRef.current, {
            backgroundColor: "#f9fafb",
            scale: 2,
            logging: false,
            useCORS: true,
          });

          const link = document.createElement("a");
          link.download = `${propertyData.extractid}.jpg`;
          link.href = canvas.toDataURL("image/jpeg", 0.9);
          link.click();
        } catch (err) {
          console.error("Error exporting image:", err);
          alert(
            "Could not export image. Please try taking a screenshot instead."
          );
        }
      }
    };

    document.body.appendChild(script);
  };

  if (isLoading || !propertyData || !calculatedMetrics) {
    return <div className="p-4">Loading property analysis...</div>;
  }

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  async function fetchDemographics(property) {
    const CLAUDE_API_KEY = "your-api-key"; // Replace with your actual API key
    const CLAUDE_API_URL =
      "https://ipmc-api.onrender.com/api/proxy/claude-completions";

    const prompt = `Please analyze and provide detailed market demographics data for ${property.city}, ${property.state}, zipcode ${property.zipCode} and surrounding areas within 5 miles. Generate the data in this exact JSON format:
  
  {
    "zipCode": "${property.zipCode}",
    "city": "${property.city}",
    "state": "${property.state}",
    "metrics": {
      "populationGrowth": {
        "value": number,
        "label": "Population Growth",
        "unit": "% Annual"
      },
      "medianHouseholdIncome": {
        "value": number,
        "label": "Median Household Income",
        "unit": "USD"
      },
      "medianAge": {
        "value": number,
        "label": "Median Age",
        "unit": "years"
      },
      "employmentRate": {
        "value": number,
        "label": "Employment Rate",
        "unit": "%"
      },
      "renterOccupied": {
        "value": number,
        "label": "Renter Occupied",
        "unit": "%"
      },
      "averageRentGrowth": {
        "value": number,
        "label": "Average Rent Growth",
        "unit": "% Annual"
      }
    }
  }
  
  Please provide accurate demographic data for this specific location and ensure all numbers are realistic and up-to-date. The response should be valid JSON that can be parsed directly.`;

    try {
      const response = await fetch(CLAUDE_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": CLAUDE_API_KEY,
          "anthropic-version": "2023-06-01",
        },
        body: JSON.stringify({
          model: "claude-3-opus-20240229",
          max_tokens: 1000,
          temperature: 0,
          messages: [
            {
              role: "user",
              content: prompt,
            },
          ],
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Extract the JSON from Claude's response
      // Claude usually wraps the JSON in text, so we need to parse it out
      const jsonMatch = data.text.match(/\{[\s\S]*\}/);
      if (!jsonMatch) {
        throw new Error("No JSON found in response");
      }

      const demographicsData = JSON.parse(jsonMatch[0]);
      setDemographicsMetrics(demographicsData);
      return demographicsData;
    } catch (error) {
      console.error("Error fetching demographics data:", error);
      throw error;
    }
  }

  const ShareButton = ({ propertyData, contentRef }) => {
    const [showShareOptions, setShowShareOptions] = useState(false);
    const [isGeneratingImage, setIsGeneratingImage] = useState(false);

    const shareText = `Check out this ${propertyData.bedrooms} bed, ${
      propertyData.bathrooms
    } bath ${propertyData.property_type.toLowerCase()} in ${
      propertyData.city
    }, ${propertyData.state}! Listed at $${parseInt(
      propertyData.price
    ).toLocaleString()}`;

    const generateImage = async () => {
      if (!window.html2canvas) {
        try {
          await new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src =
              "https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js";
            script.async = true;
            script.onload = resolve;
            script.onerror = reject;
            document.body.appendChild(script);
          });
        } catch (err) {
          console.error("Failed to load html2canvas:", err);
          return null;
        }
      }

      try {
        const canvas = await window.html2canvas(contentRef.current, {
          backgroundColor: "#f9fafb",
          scale: window.devicePixelRatio || 2,
          logging: false,
          useCORS: true,
          allowTaint: true,
        });

        // Convert canvas to blob
        const blob = await new Promise((resolve) => {
          canvas.toBlob(resolve, "image/jpeg", 0.9);
        });

        return blob;
      } catch (err) {
        console.error("Error generating image:", err);
        return null;
      }
    };

    const shareToSocialMedia = async (platform) => {
      setIsGeneratingImage(true);
      try {
        const imageBlob = await generateImage();
        if (!imageBlob) {
          throw new Error("Failed to generate image");
        }

        // Create file from blob
        const imageFile = new File([imageBlob], "property-analysis.jpg", {
          type: "image/jpeg",
        });

        if (platform === "native" && navigator.share) {
          await navigator.share({
            title: `Property Analysis: ${propertyData.city}, ${propertyData.state}`,
            text: shareText,
            files: [imageFile],
          });
        } else {
          // Platform specific sharing
          const url = URL.createObjectURL(imageBlob);

          switch (platform) {
            case "facebook":
              // Facebook requires a server-hosted image, so we'll guide users
              alert(
                "To share on Facebook:\n1. Save the generated image\n2. Open Facebook\n3. Create a new post with the saved image"
              );
              const link = document.createElement("a");
              link.href = url;
              link.download = "property-analysis.jpg";
              link.click();
              break;

            case "twitter":
              // Twitter web intent with text
              window.open(
                `https://twitter.com/intent/tweet?text=${encodeURIComponent(
                  shareText
                )}`,
                "_blank"
              );
              // Also download the image for the user
              const twitterLink = document.createElement("a");
              twitterLink.href = url;
              twitterLink.download = "property-analysis.jpg";
              twitterLink.click();
              break;

            case "whatsapp":
              // WhatsApp web sharing
              window.open(
                `https://wa.me/?text=${encodeURIComponent(shareText)}`,
                "_blank"
              );
              const whatsappLink = document.createElement("a");
              whatsappLink.href = url;
              whatsappLink.download = "property-analysis.jpg";
              whatsappLink.click();
              break;

            case "email":
              // Email sharing
              window.location.href = `mailto:?subject=${encodeURIComponent(
                `Property Analysis: ${propertyData.city}, ${propertyData.state}`
              )}&body=${encodeURIComponent(shareText)}`;
              const emailLink = document.createElement("a");
              emailLink.href = url;
              emailLink.download = "property-analysis.jpg";
              emailLink.click();
              break;
          }

          // Cleanup
          setTimeout(() => URL.revokeObjectURL(url), 1000);
        }
      } catch (err) {
        console.error("Error sharing:", err);
        alert(
          "There was an error sharing. Please try saving the image and sharing manually."
        );
      } finally {
        setIsGeneratingImage(false);
        setShowShareOptions(false);
      }
    };

    const shareOptions = [
      {
        id: "native",
        label: "Share",
        icon: "M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92-1.31-2.92-2.92-2.92z",
      },
      {
        id: "facebook",
        label: "Facebook",
        icon: "M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z",
      },
      {
        id: "twitter",
        label: "Twitter",
        icon: "M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z",
      },
      {
        id: "whatsapp",
        label: "WhatsApp",
        icon: "M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z",
      },
    ];

    return (
      <div style={{ position: "relative" }}>
        <button
          onClick={() => shareToSocialMedia("native")}
          disabled={isGeneratingImage}
          style={{
            background: "linear-gradient(to right, #9333ea, #2563eb)",
            color: "white",
            padding: "0.75rem 1.5rem",
            borderRadius: "9999px",
            border: "none",
            cursor: isGeneratingImage ? "wait" : "pointer",
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            fontSize: "1rem",
            fontWeight: "bold",
            opacity: isGeneratingImage ? 0.7 : 1,
          }}
        >
          {isGeneratingImage ? "Generating..." : "Share Analysis"}
        </button>

        {showShareOptions && (
          <div
            style={{
              position: "absolute",
              top: "100%",
              left: "0",
              marginTop: "0.5rem",
              background: "white",
              borderRadius: "0.75rem",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              padding: "1rem",
              zIndex: 50,
              minWidth: "200px",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "0.75rem",
              }}
            >
              {shareOptions.map(({ id, label, icon }) => (
                <button
                  key={id}
                  onClick={() => shareToSocialMedia(id)}
                  disabled={isGeneratingImage}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    padding: "0.5rem",
                    borderRadius: "0.5rem",
                    background: "#f3f4f6",
                    color: "#374151",
                    border: "none",
                    cursor: isGeneratingImage ? "wait" : "pointer",
                    fontSize: "0.875rem",
                    opacity: isGeneratingImage ? 0.7 : 1,
                  }}
                >
                  <svg
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                  >
                    <path d={icon} />
                  </svg>
                  <span>{label}</span>
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      style={{
        maxWidth: "32rem",
        background: "#f9fafb",
        minHeight: "100vh",
        padding: "1rem",
        margin: "1rem",
      }}
    >
      <h1 style={{ fontSize: "1.5rem", marginBottom: "1rem" }}>
        Property Analysis Influencer Video Generator
      </h1>
      <div ref={contentRef}>
        <div style={{ margin: "1rem" }}>
          {/* Header Section */}
          <div
            style={{
              background: "linear-gradient(to right, #2563eb, #9333ea)",
              padding: "1.5rem",
              color: "white",
              borderRadius: "1rem",
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                marginBottom: "1rem",
              }}
            >
              <div
                style={{
                  background: "white",
                  width: "3rem",
                  height: "3rem",
                  borderRadius: "9999px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Home style={{ color: "#2563eb" }} size={24} />
              </div>
              <div>
                <h2 style={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                  Alex Rivera
                </h2>
                <p>Your Cash Flow Compass</p>
              </div>
            </div>
            <p style={{ fontSize: "1.125rem" }}>
              Hey there, future investors! Alex Rivera here, and today we're
              diving deep into an exciting property analysis in{" "}
              {propertyData.city}.
            </p>
          </div>

          {/* Property Overview */}
          <div style={{ marginBottom: "1.5rem" }}>
            <h2
              style={{
                fontSize: "1.25rem",
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              Property Overview
            </h2>
            <div
              style={{
                background: "white",
                borderRadius: "1rem",
                padding: "1.5rem",
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <Home style={{ color: "#2563eb" }} size={24} />
                <div>
                  <p style={{ fontSize: "0.875rem", color: "#6b7280" }}>
                    Location
                  </p>
                  <p style={{ fontSize: "1.125rem" }}>
                    {generateFullAddress(propertyData)}
                  </p>
                </div>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <DollarSign style={{ color: "#059669" }} size={24} />
                <div>
                  <p style={{ fontSize: "0.875rem", color: "#6b7280" }}>
                    Purchase Price
                  </p>
                  <p style={{ fontSize: "1.125rem" }}>
                    {formatCurrency(propertyData.price)}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Cash Flow Analysis */}
          <div style={{ marginBottom: "1.5rem" }}>
            <h2
              style={{
                fontSize: "1.25rem",
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              Cash Flow Analysis
            </h2>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.75rem",
              }}
            >
              <div
                style={{
                  background: "#eff6ff",
                  padding: "1rem",
                  borderRadius: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <p>Monthly Cash Flow</p>
                    <p
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: "#2563eb",
                      }}
                    >
                      {formatCurrency(
                        calculatedMetrics.returns.monthlyCashFlow
                      )}
                    </p>
                  </div>
                  <TrendingUp style={{ color: "#2563eb" }} />
                </div>
              </div>

              <div
                style={{
                  background: "#ecfdf5",
                  padding: "1rem",
                  borderRadius: "0.5rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <p>Annual Cash Flow</p>
                    <p
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: "#059669",
                      }}
                    >
                      {formatCurrency(calculatedMetrics.returns.annualCashFlow)}
                    </p>
                  </div>
                  <DollarSign style={{ color: "#059669" }} />
                </div>
              </div>

              <div
                style={{
                  background: "#f5f3ff",
                  padding: "1rem",
                  borderRadius: "0.5rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <p>Cash on Cash</p>
                    <p
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: "#7c3aed",
                      }}
                    >
                      {calculatedMetrics.returns.cashOnCash.toFixed(2)}%
                    </p>
                  </div>
                  <Users style={{ color: "#7c3aed" }} />
                </div>
              </div>

              <div
                style={{
                  background: "#eff6ff",
                  padding: "1rem",
                  borderRadius: "0.5rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <p>Cap Rate</p>
                    <p
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: "#2563eb",
                      }}
                    >
                      {calculatedMetrics.returns.capRate.toFixed(2)}%
                    </p>
                  </div>
                  <TrendingUp style={{ color: "#2563eb" }} />
                </div>
              </div>
            </div>
          </div>

          {/* Monthly Expenses Breakdown */}
          <div style={{ marginBottom: "1.5rem" }}>
            <h2
              style={{
                fontSize: "1.25rem",
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              Monthly Expenses Breakdown
            </h2>
            <div
              style={{
                background: "white",
                borderRadius: "0.5rem",
                padding: "1.5rem",
                boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.75rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0.75rem",
                    background: "#f9fafb",
                    borderRadius: "0.5rem",
                  }}
                >
                  <span>Mortgage</span>
                  <span>
                    {formatCurrency(calculatedMetrics.returns.monthlyMortgage)}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0.75rem",
                    background: "#f9fafb",
                    borderRadius: "0.5rem",
                  }}
                >
                  <span>Taxes</span>
                  <span>
                    {formatCurrency(
                      calculatedMetrics.returns.monthlyPropertyTax
                    )}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0.75rem",
                    background: "#f9fafb",
                    borderRadius: "0.5rem",
                  }}
                >
                  <span>Insurance</span>
                  <span>
                    {formatCurrency(calculatedMetrics.returns.monthlyInsurance)}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0.75rem",
                    background: "#f9fafb",
                    borderRadius: "0.5rem",
                  }}
                >
                  <span>Maintenance</span>
                  <span>
                    {formatCurrency(
                      calculatedMetrics.returns.monthlyPropertyTax
                    )}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0.75rem",
                    background: "#f9fafb",
                    borderRadius: "0.5rem",
                  }}
                >
                  <span>Vacancy</span>
                  <span>
                    {formatCurrency(calculatedMetrics.returns.monthlyVacancy)}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0.75rem",
                    background: "#f9fafb",
                    borderRadius: "0.5rem",
                  }}
                >
                  <span>HOA</span>
                  <span>
                    {formatCurrency(calculatedMetrics.returns.monthlyHOA)}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0.75rem",
                    background: "#f9fafb",
                    borderRadius: "0.5rem",
                  }}
                >
                  <span>Property Management</span>
                  <span>
                    {formatCurrency(
                      calculatedMetrics.returns.monthlyPropertyManagement
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Demographics Analysis */}
          {/* <div style={{ marginBottom: "1.5rem" }}>
            <h2
              style={{
                fontSize: "1.25rem",
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              Market Demographics
            </h2>
            <div
              style={{
                background: "white",
                borderRadius: "1rem",
                padding: "1.5rem",
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.75rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0.75rem",
                    background: "#f9fafb",
                    borderRadius: "0.75rem",
                  }}
                >
                  <span>Population Growth</span>
                  <span style={{ color: "#059669" }}>
                    +{demographicsMetrics?.metrics.populationGrowth.value}%
                    Annual
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0.75rem",
                    background: "#f9fafb",
                    borderRadius: "0.75rem",
                  }}
                >
                  <span>Median Household Income</span>
                  <span style={{ color: "#2563eb" }}>
                    $
                    {demographicsMetrics?.metrics.medianHouseholdIncome.value.toLocaleString()}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0.75rem",
                    background: "#f9fafb",
                    borderRadius: "0.75rem",
                  }}
                >
                  <span>Median Age</span>
                  <span style={{ color: "#2563eb" }}>
                    {demographicsMetrics?.metrics.medianAge.value} years
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0.75rem",
                    background: "#f9fafb",
                    borderRadius: "0.75rem",
                  }}
                >
                  <span>Employment Rate</span>
                  <span style={{ color: "#059669" }}>
                    {demographicsMetrics?.metrics.employmentRate.value}%
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0.75rem",
                    background: "#f9fafb",
                    borderRadius: "0.75rem",
                  }}
                >
                  <span>Renter Occupied</span>
                  <span style={{ color: "#2563eb" }}>
                    {demographicsMetrics?.metrics.renterOccupied.value}%
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0.75rem",
                    background: "#f9fafb",
                    borderRadius: "0.75rem",
                  }}
                >
                  <span>Average Rent Growth</span>
                  <span style={{ color: "#059669" }}>
                    +{demographicsMetrics?.metrics.averageRentGrowth.value}%
                    Annual
                  </span>
                </div>
              </div>
            </div>
          </div> */}

          {/* Outro Message */}
          <div
            style={{
              background: "linear-gradient(to right, #9333ea, #2563eb)",
              padding: "1.5rem",
              color: "white",
              borderRadius: "0.5rem",
              marginBottom: "1.5rem",
            }}
          >
            <p style={{ fontSize: "1.125rem", marginBottom: "1rem" }}>
              Remember: Cash flow is king, but smart analysis is the kingdom!
            </p>
            <p>
              Don't forget to smash that like button and hit subscribe to join
              our real estate investing family! See you in the next one!
            </p>
          </div>
        </div>
      </div>
      {/* Share Button */}
      <div
        style={{
          position: "fixed",
          bottom: "2rem",
          right: "2rem",
          display: "flex",
          gap: "1rem",
        }}
      >
        <button
          onClick={exportAsJPEG}
          style={{
            background: "linear-gradient(to right, #059669, #0d9488)",
            color: "white",
            padding: "0.75rem 1.5rem",
            borderRadius: "9999px",
            border: "none",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          <Download size={20} />
          Export JPEG
        </button>
        {/* <button
          onClick={() => {
            if (navigator.share) {
              navigator.share({
                title: "Property Analysis",
                text: "Check out this property analysis from Cash Flow Rover!",
                url: window.location.href,
              });
            } else {
              alert(
                "To share on Facebook: \n1. Take a screenshot of this analysis\n2. Open Facebook\n3. Create a new post with the screenshot"
              );
            }
          }}
          style={{
            background: "linear-gradient(to right, #9333ea, #2563eb)",
            color: "white",
            padding: "0.75rem 1.5rem",
            borderRadius: "9999px",
            border: "none",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          Share Analysis
        </button> */}

        {/* <ShareButton 
        propertyData={propertyData} 
        contentRef={contentRef} 
      /> */}
      </div>
    </div>
  );
};

export default PropertyInfluencer;
