import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import ExpenseBreakdown from "./search-page/expensebreakdown.component";
import CashFlowAnalysis from "./search-page/cashflowanalysis.component";
import KeyMetrics from "./search-page/kpi.component";
import Projections from "./search-page/30yrprojections.componenrts";
import PropertyDetails from "./search-page/propertydetails.coponent";
import DebtAnalysis from "./search-page/debtanalysis.component";
import ReturnAnalysis from "./search-page/returnanalysis.component";
import MaxPriceAnalysis from "./search-page/maxpriceanalysis.component";
import ReturnComposition from "./search-page/returncomposition.component";
import { InputNumber } from "primereact/inputnumber";
import StickyMenu from "./search-page/stickymenu.component";
import DemographicsData from "./search-page/demogrtaphics.component";
import FinancialParameters from "./search-page/financialparameters.component";
import PDFReport from "./search-page/lenderreport.component";
import LenderPDFReport from "./search-page/lenderreport.component";
import InvestorPDFReport from "./search-page/invsetorreport.component";
import GenerateReportSection from "./search-page/generatereportsection.component";

const PropertyAnalysis = ({
  initialData,
  demographicsData,
  onClose,
  onUpdateData,
}) => {
  const [propertyDetails, setPropertyDetails] = useState(
    initialData.propertyDetails
  );
  const [financialParams, setFinancialParams] = useState(
    initialData.financialParams
  );
  const [demographics, setDemographics] = useState(demographicsData);

  // When local state changes, notify parent component
  useEffect(() => {
    onUpdateData({
      propertyDetails,
      financialParams,
    });
  }, [propertyDetails, financialParams]);

  const [calculations, setCalculations] = useState({
    monthlyMortgage: 0,
    monthlyTaxes: 0,
    monthlyInsurance: 0,
    monthlyMaintenance: 0,
    monthlyVacancy: 0,
    monthlyManagement: 0,
    monthlyCashFlow: 0,
    noi: 0,
    capRate: 0,
    cashOnCashReturn: 0,
    totalMonthlyExpenses: 0,
  });

  const [projections, setProjections] = useState({
    yearlyData: [],
    totalAppreciation: 0,
    totalCashFlow: 0,
    irr: 0,
    averageReturn: 0,
  });

  const [debtAnalysis, setDebtAnalysis] = useState({
    amortizationSchedule: [],
    totalInterestPaid: 0,
    totalEquityBuildup: 0,
    loanToValue: 0,
    debtServiceCoverageRatio: 0,
  });

  const [returnMetrics, setReturnMetrics] = useState({
    irr: 0,
    totalReturn: 0,
    equityMultiple: 0,
    paybackPeriod: 0,
    cashflowReturn: 0,
    appreciationReturn: 0,
    equityReturn: 0,
  });

  const [maxPriceAnalysis, setMaxPriceAnalysis] = useState({
    targetMetrics: {
      capRate: 6,
      cashOnCash: 8,
      irr: 15,
    },
    maxPrices: {
      byCapRate: 0,
      byCashOnCash: 0,
      byIRR: 0,
    },
    recommendedPrice: 0,
    priceRange: [],
  });

  const [scenarioAnalysis, setScenarioAnalysis] = useState({
    scenarios: {
      conservative: {
        appreciationRate: 2,
        rentGrowthRate: 1.5,
        expenseGrowthRate: 3,
        vacancyRate: 8,
        maintenanceRate: 2,
        irr: 0,
        cashOnCash: 0,
        totalReturn: 0,
      },
      base: {
        appreciationRate: 3,
        rentGrowthRate: 2,
        expenseGrowthRate: 2.5,
        vacancyRate: 5,
        maintenanceRate: 1,
        irr: 0,
        cashOnCash: 0,
        totalReturn: 0,
      },
      optimistic: {
        appreciationRate: 4,
        rentGrowthRate: 3,
        expenseGrowthRate: 2,
        vacancyRate: 3,
        maintenanceRate: 0.5,
        irr: 0,
        cashOnCash: 0,
        totalReturn: 0,
      },
    },
    sensitivity: {
      purchasePrice: [],
      monthlyRent: [],
      interestRate: [],
      downPayment: [],
    },
  });

  // Calculation functions
  const calculateMortgage = (price, downPayment, rate, term) => {
    const loanAmount = price * (1 - downPayment / 100);
    const monthlyRate = rate / 100 / 12;
    const payments = term * 12;
    return (
      (loanAmount * (monthlyRate * Math.pow(1 + monthlyRate, payments))) /
      (Math.pow(1 + monthlyRate, payments) - 1)
    );
  };

  const updateCalculations = () => {
    // Base mortgage calculation
    const monthlyMortgage = calculateMortgage(
      propertyDetails.purchasePrice,
      financialParams.downPayment,
      financialParams.interestRate,
      financialParams.loanTerm
    );

    // Monthly fixed expenses based on purchase price
    const monthlyTaxes =
      (propertyDetails.purchasePrice * financialParams.propertyTaxRate) /
      100 /
      12;
    const monthlyInsurance =
      (propertyDetails.purchasePrice * financialParams.insuranceRate) /
      100 /
      12;
    const monthlyMaintenance =
      (propertyDetails.monthlyRent * financialParams.maintenanceRate) /
      100;

    // Monthly expenses based on rental income
    const monthlyVacancy =
      (propertyDetails.monthlyRent * financialParams.vacancyRate) / 100;
    const monthlyManagement =
      (propertyDetails.monthlyRent * financialParams.propertyManagementRate) /
      100;

    // Fixed monthly expenses
    const monthlyHOA = financialParams.hoaMonthly || 0;
    const monthlyUtilities = financialParams.utilitiesMonthly || 0;
    const monthlyOther = financialParams.otherMonthlyExpenses || 0;

    // Calculate monthly operating expenses (excluding mortgage)
    const monthlyOperatingExpenses =
      monthlyTaxes +
      monthlyInsurance +
      monthlyMaintenance +
      monthlyVacancy +
      monthlyManagement +
      monthlyHOA +
      monthlyUtilities +
      monthlyOther;

    // Calculate total monthly expenses (including mortgage)
    const totalMonthlyExpenses = monthlyOperatingExpenses + monthlyMortgage;

    // Monthly cash flow
    const monthlyCashFlow = propertyDetails.monthlyRent - totalMonthlyExpenses;

    // Calculate initial investment
    const downPayment =
      (propertyDetails.purchasePrice * financialParams.downPayment) / 100;
    const closingCosts =
      (propertyDetails.purchasePrice * financialParams.closingCosts) / 100;
    const initialRepairs = financialParams.repairBudget || 0;
    const totalInitialInvestment = downPayment + closingCosts + initialRepairs;

    // Yearly NOI calculation (excluding mortgage)
    const yearlyNOI =
      propertyDetails.monthlyRent *
        12 *
        (1 - financialParams.vacancyRate / 100) -
      (monthlyTaxes +
        monthlyInsurance +
        monthlyMaintenance +
        monthlyManagement +
        monthlyHOA +
        monthlyUtilities +
        monthlyOther) *
        12;

    // Calculate annual debt service
    const annualDebtService = monthlyMortgage * 12;

    // Key metrics
    const capRate = (yearlyNOI / propertyDetails.purchasePrice) * 100;
    const cashOnCashReturn =
      ((monthlyCashFlow * 12) / totalInitialInvestment) * 100;
    const dscr = annualDebtService > 0 ? yearlyNOI / annualDebtService : 0;
    const grossRentMultiplier =
      propertyDetails.purchasePrice / (propertyDetails.monthlyRent * 12);
    const expenseRatio =
      ((totalMonthlyExpenses * 12) / (propertyDetails.monthlyRent * 12)) * 100;

    const breakEvenRatio = (totalMonthlyExpenses * 12) / (propertyDetails.monthlyRent * 12) * 100;


    setCalculations({
      // Monthly expenses
      monthlyMortgage,
      monthlyTaxes,
      monthlyInsurance,
      monthlyMaintenance,
      monthlyVacancy,
      monthlyManagement,
      monthlyHOA,
      monthlyUtilities,
      monthlyOther,
      monthlyOperatingExpenses,
      totalMonthlyExpenses,
      monthlyCashFlow,

      // Investment amounts
      downPayment,
      closingCosts,
      initialRepairs,
      totalInitialInvestment,

      // Yearly metrics
      noi: yearlyNOI,
      capRate,
      cashOnCashReturn,
      dscr,

      // Additional metrics
      grossRentMultiplier,
      expenseRatio,
      breakEvenRatio,

      rentToValue: (propertyDetails.monthlyRent * 12 / propertyDetails.purchasePrice) * 100,
      operatingExpenseRatio: (monthlyOperatingExpenses * 12 / (propertyDetails.monthlyRent * 12)) * 100,
      debtServiceRatio: (monthlyMortgage * 12 / (propertyDetails.monthlyRent * 12)) * 100
    });
  };

  const calculateIRR = (cashFlows, maxIterations = 1000) => {
    const precision = 0.000001;
    let guess = 0.1;

    const npv = (rate) => {
      return cashFlows.reduce((sum, cashFlow, index) => {
        return sum + cashFlow / Math.pow(1 + rate, index);
      }, 0);
    };

    for (let i = 0; i < maxIterations; i++) {
      const npvValue = npv(guess);
      if (Math.abs(npvValue) < precision) {
        return guess * 100;
      }
      guess =
        guess -
        npvValue /
          cashFlows.reduce((sum, cashFlow, index) => {
            return sum - (index * cashFlow) / Math.pow(1 + guess, index + 1);
          }, 0);
    }
    return guess * 100;
  };

  const getRemainingLoanBalance = (year) => {
    const loanAmount =
      propertyDetails.purchasePrice * (1 - financialParams.downPayment / 100);
    const monthlyRate = financialParams.interestRate / 100 / 12;
    const totalPayments = financialParams.loanTerm * 12;
    const monthlyPayment = calculations.monthlyMortgage;
    let balance = loanAmount;

    for (let i = 0; i < year * 12; i++) {
      const interestPayment = balance * monthlyRate;
      const principalPayment = monthlyPayment - interestPayment;
      balance -= principalPayment;
    }

    return balance;
  };

  const generateProjections = () => {
    // Initial investment calculation
    const initialInvestment = calculations.totalInitialInvestment;

    // Base yearly expenses (those that grow with inflation/expense growth rate)
    const baseYearlyExpenses =
      (calculations.monthlyTaxes +
        calculations.monthlyInsurance +
        calculations.monthlyMaintenance +
        calculations.monthlyVacancy +
        calculations.monthlyManagement) *
      12;

    // Fixed yearly expenses (HOA, utilities, etc. - these might have different growth rates)
    const fixedYearlyExpenses =
      (calculations.monthlyHOA +
        calculations.monthlyUtilities +
        calculations.monthlyOther) *
      12;

    const yearlyData = [];
    let propertyValue = propertyDetails.purchasePrice;
    let rent = propertyDetails.monthlyRent;
    let totalCashFlow = 0;

    // Initial cash flow array for IRR calculation
    let cashFlows = [-initialInvestment];

    // Capital expenditure reserve (percentage of property value)
    const capExReserveRate = 0.005; // 0.5% of property value per year

    for (let year = 1; year <= 30; year++) {
      // Property value appreciation
      propertyValue *= 1 + financialParams.appreciationRate / 100;

      // Rent growth
      rent *= 1 + financialParams.rentGrowthRate / 100;
      const yearlyRent = rent * 12;

      // Expense calculations with growth rates
      const yearlyBaseExpenses =
        baseYearlyExpenses *
        Math.pow(1 + financialParams.expenseGrowthRate / 100, year);
      const yearlyFixedExpenses =
        fixedYearlyExpenses *
        Math.pow(1 + financialParams.expenseGrowthRate / 2 / 100, year); // Assume fixed expenses grow at half the rate

      // Mortgage payment (constant)
      const yearlyMortgage = calculations.monthlyMortgage * 12;

      // Capital expenditure reserve
      const capExReserve = propertyValue * capExReserveRate;

      // Calculate total expenses and cash flow
      const totalYearlyExpenses =
        yearlyBaseExpenses +
        yearlyFixedExpenses +
        yearlyMortgage +
        capExReserve;
      const yearlyCashFlow = yearlyRent - totalYearlyExpenses;

      // Track cumulative cash flow
      totalCashFlow += yearlyCashFlow;
      cashFlows.push(yearlyCashFlow);

      // Add sale proceeds in final year
      if (year === 30) {
        const saleProceeds =
          propertyValue * (1 - financialParams.sellingCosts / 100);
        cashFlows[year] += saleProceeds;
        totalCashFlow += saleProceeds;
      }

      // Calculate remaining loan balance and equity
      const remainingLoan = getRemainingLoanBalance(year);
      const equity = propertyValue - remainingLoan;

      // Calculate key metrics
      const yearlyNOI = yearlyRent - (yearlyBaseExpenses + yearlyFixedExpenses);
      const yearlyCapRate = (yearlyNOI / propertyValue) * 100;
      const yearlyCoCReturn = (yearlyCashFlow / initialInvestment) * 100;
      const yearlyDSCR = yearlyNOI / yearlyMortgage;

      yearlyData.push({
        year,
        propertyValue: Math.round(propertyValue),
        yearlyRent: Math.round(yearlyRent),
        yearlyBaseExpenses: Math.round(yearlyBaseExpenses),
        yearlyFixedExpenses: Math.round(yearlyFixedExpenses),
        yearlyMortgage: Math.round(yearlyMortgage),
        capExReserve: Math.round(capExReserve),
        yearlyCashFlow: Math.round(yearlyCashFlow),
        cumCashFlow: Math.round(totalCashFlow),
        equity: Math.round(equity),
        loanBalance: Math.round(remainingLoan),
        yearlyNOI: Math.round(yearlyNOI),
        yearlyCapRate: Number(yearlyCapRate.toFixed(2)),
        yearlyCoCReturn: Number(yearlyCoCReturn.toFixed(2)),
        yearlyDSCR: Number(yearlyDSCR.toFixed(2)),
      });
    }

    // Calculate summary metrics
    const finalYear = yearlyData[yearlyData.length - 1];
    const totalAppreciation =
      finalYear.propertyValue - propertyDetails.purchasePrice;
    const irr = calculateIRR(cashFlows);
    const averageAnnualReturn = totalCashFlow / 30;
    const totalROI =
      ((totalCashFlow + totalAppreciation) / initialInvestment) * 100;

    setProjections({
      yearlyData,
      totalAppreciation,
      totalCashFlow,
      irr,
      averageAnnualReturn,
      totalROI,
      summaryMetrics: {
        initialInvestment,
        finalEquity: finalYear.equity,
        averageCapRate:
          yearlyData.reduce((sum, year) => sum + year.yearlyCapRate, 0) / 30,
        averageCoCReturn:
          yearlyData.reduce((sum, year) => sum + year.yearlyCoCReturn, 0) / 30,
        averageDSCR:
          yearlyData.reduce((sum, year) => sum + year.yearlyDSCR, 0) / 30,
      },
    });
  };

  const generateDebtAnalysis = () => {
    const schedule = [];
    let loanAmount =
      propertyDetails.purchasePrice * (1 - financialParams.downPayment / 100);
    const monthlyRate = financialParams.interestRate / 100 / 12;
    const monthlyPayment = calculations.monthlyMortgage;
    let totalInterest = 0;
    let balance = loanAmount;

    for (let year = 1; year <= 30; year++) {
      let yearlyInterest = 0;
      let yearlyPrincipal = 0;

      for (let month = 1; month <= 12; month++) {
        const interestPayment = balance * monthlyRate;
        const principalPayment = monthlyPayment - interestPayment;

        yearlyInterest += interestPayment;
        yearlyPrincipal += principalPayment;
        balance -= principalPayment;
      }

      totalInterest += yearlyInterest;

      schedule.push({
        year,
        principalPayment: yearlyPrincipal,
        interestPayment: yearlyInterest,
        remainingBalance: balance,
        totalInterest: totalInterest,
        loanToValue:
          (balance /
            (propertyDetails.purchasePrice *
              Math.pow(1 + financialParams.appreciationRate / 100, year))) *
          100,
      });
    }

    setDebtAnalysis({
      amortizationSchedule: schedule,
      totalInterestPaid: totalInterest,
      totalEquityBuildup: loanAmount - balance,
      loanToValue: (balance / propertyDetails.purchasePrice) * 100,
      debtServiceCoverageRatio:
        calculations.noi / (calculations.monthlyMortgage * 12),
    });
  };

  const calculateReturnMetrics = () => {
    const totalInvestment =
      propertyDetails.purchasePrice * (financialParams.downPayment / 100);
    const finalPropertyValue = projections.yearlyData[29]?.propertyValue;
    const totalCashFlow = projections.totalCashFlow;
    const totalReturn =
      totalCashFlow + (finalPropertyValue - propertyDetails.purchasePrice);

    // Find payback period
    let paybackYear = 0;
    for (const yearData of projections.yearlyData) {
      if (yearData.cumCashFlow >= totalInvestment) {
        paybackYear = yearData.year;
        break;
      }
    }

    setReturnMetrics({
      irr: projections.irr,
      totalReturn,
      equityMultiple: (totalInvestment + totalReturn) / totalInvestment,
      paybackPeriod: paybackYear,
      cashflowReturn: (totalCashFlow / totalInvestment) * 100,
      appreciationReturn:
        ((finalPropertyValue - propertyDetails.purchasePrice) /
          totalInvestment) *
        100,
      equityReturn: (debtAnalysis.totalEquityBuildup / totalInvestment) * 100,
    });
  };

  const calculateMaxPrices = () => {
    // Input validation
    if (!propertyDetails.monthlyRent || !propertyDetails.purchasePrice) {
      console.error("Missing required property details");
      return;
    }

    // Constants for calculations
    const MIN_EXPENSE_RATIO = 0.35; // 35% minimum expenses of gross rent
    const MAX_EXPENSE_RATIO = 0.45; // 45% maximum expenses of gross rent
    const MAX_PRICE_MULTIPLIER = 1.5; // Maximum 1.5x current price
    const currentPrice = propertyDetails.purchasePrice;
    const absoluteMaxPrice = currentPrice * MAX_PRICE_MULTIPLIER;
    const yearlyRent = propertyDetails.monthlyRent * 12;

    // Helper function for calculating monthly expenses
    const calculateMonthlyExpenses = (price, yearlyRent) => {
      // Property-based expenses (taxes and insurance)
      const propertyBasedExpenses =
        (price *
          (financialParams.propertyTaxRate + financialParams.insuranceRate)) /
        100;

      // Revenue-based expenses (vacancy and management)
      const revenueBasedExpenses =
        (yearlyRent *
          (financialParams.vacancyRate +
            financialParams.propertyManagementRate)) /
        100;

      // Maintenance (split between property value and revenue)
      const maintenance =
        ((price * 0.5 + yearlyRent * 0.5) * financialParams.maintenanceRate) /
        100;

      const totalYearlyExpenses =
        propertyBasedExpenses + revenueBasedExpenses + maintenance;
      const expenseRatio = totalYearlyExpenses / yearlyRent;

      // Apply expense ratio limits
      if (expenseRatio < MIN_EXPENSE_RATIO) {
        return (yearlyRent * MIN_EXPENSE_RATIO) / 12;
      }
      if (expenseRatio > MAX_EXPENSE_RATIO) {
        return (yearlyRent * MAX_EXPENSE_RATIO) / 12;
      }

      return totalYearlyExpenses / 12;
    };

    // Helper function for calculating cash flow
    const calculateMonthlyCashFlow = (
      price,
      monthlyRent,
      includesTaxBenefit = true
    ) => {
      if (price > absoluteMaxPrice) {
        return 0; // Return 0 for unrealistic prices
      }

      const monthlyMortgage = calculateMortgage(
        price,
        financialParams.downPayment,
        financialParams.interestRate,
        financialParams.loanTerm
      );

      const monthlyExpenses = calculateMonthlyExpenses(price, monthlyRent * 12);

      let taxBenefit = 0;
      if (includesTaxBenefit && financialParams.taxRate) {
        const annualInterest = calculateMortgage(
          price,
          financialParams.downPayment,
          financialParams.interestRate,
          financialParams.loanTerm,
          true
        );
        const annualDepreciation = price * 0.027397; // 27.5 year depreciation
        taxBenefit =
          ((annualInterest + annualDepreciation) * financialParams.taxRate) /
          12;
      }

      return monthlyRent - monthlyMortgage - monthlyExpenses + taxBenefit;
    };

    // Calculate NOI more accurately
    const calculateNOI = (price) => {
      if (price > absoluteMaxPrice) {
        return 0; // Return 0 for unrealistic prices
      }
      const annualExpenses = calculateMonthlyExpenses(price, yearlyRent) * 12;
      return yearlyRent - annualExpenses;
    };

    // Calculate max price based on Cap Rate with limits
    const noi = calculateNOI(currentPrice);
    let maxPriceByCapRate =
      noi / (maxPriceAnalysis.targetMetrics.capRate / 100);
    maxPriceByCapRate = Math.min(maxPriceByCapRate, absoluteMaxPrice);

    // Calculate IRR
    const calculateIRR = (price, holdingPeriod = 5) => {
      const appreciationRate = 0.03; // 3% annual appreciation
      const downPayment = price * (financialParams.downPayment / 100);
      const cashFlows = [-downPayment];
      let loanBalance = price * (1 - financialParams.downPayment / 100);

      for (let year = 1; year <= holdingPeriod; year++) {
        const yearlyCashFlow =
          calculateMonthlyCashFlow(price, propertyDetails.monthlyRent) * 12;

        if (year === holdingPeriod) {
          const salePrice =
            price * Math.pow(1 + appreciationRate, holdingPeriod);
          const sellingCosts = salePrice * 0.06; // 6% selling costs
          const netSaleProceeds = salePrice - sellingCosts - loanBalance;
          cashFlows.push(yearlyCashFlow + netSaleProceeds);
        } else {
          cashFlows.push(yearlyCashFlow);
        }

        // Update loan balance (simplified)
        const yearlyPrincipalPayment =
          calculateMortgage(
            price,
            financialParams.downPayment,
            financialParams.interestRate,
            financialParams.loanTerm
          ) *
          12 *
          0.2; // Approximate principal portion
        loanBalance -= yearlyPrincipalPayment;
      }

      return calculateSimpleIRR(
        cashFlows,
        maxPriceAnalysis.targetMetrics.irr / 100
      );
    };

    // Find max price based on Cash on Cash Return using binary search
    const findMaxPriceByCashOnCash = () => {
      let low = currentPrice * 0.5; // Start at 50% of current price
      let high = Math.min(maxPriceByCapRate * 2, absoluteMaxPrice);
      let iterations = 0;
      const maxIterations = 100;
      const tolerance = 0.01;

      while (low <= high && iterations < maxIterations) {
        const mid = (low + high) / 2;
        const downPayment = mid * (financialParams.downPayment / 100);
        const monthlyCashFlow = calculateMonthlyCashFlow(
          mid,
          propertyDetails.monthlyRent
        );
        const actualCashOnCash = ((monthlyCashFlow * 12) / downPayment) * 100;

        if (
          Math.abs(
            actualCashOnCash - maxPriceAnalysis.targetMetrics.cashOnCash
          ) < tolerance
        ) {
          return Math.min(mid, absoluteMaxPrice);
        }

        if (actualCashOnCash > maxPriceAnalysis.targetMetrics.cashOnCash) {
          low = mid + tolerance;
        } else {
          high = mid - tolerance;
        }
        iterations++;
      }
      return Math.min((low + high) / 2, absoluteMaxPrice);
    };

    const maxPriceByCashOnCash = findMaxPriceByCashOnCash();
    const maxPriceByIRR = Math.min(
      currentPrice * calculateIRR(currentPrice),
      absoluteMaxPrice
    );

    // Generate price range analysis with more accurate metrics
    const priceRange = [];
    const basePrice = propertyDetails.purchasePrice;
    [-20, -15, -10, -5, 0, 5, 10, 15, 20].forEach((variation) => {
      const testPrice = Math.min(
        basePrice * (1 + variation / 100),
        absoluteMaxPrice
      );
      const testNOI = calculateNOI(testPrice);
      const testCapRate = (testNOI / testPrice) * 100;

      const downPayment = testPrice * (financialParams.downPayment / 100);
      const monthlyCashFlow = calculateMonthlyCashFlow(
        testPrice,
        propertyDetails.monthlyRent
      );
      const testCashOnCash = ((monthlyCashFlow * 12) / downPayment) * 100;
      const testIRR = calculateIRR(testPrice);

      priceRange.push({
        variation,
        price: testPrice,
        capRate: testCapRate,
        cashOnCash: testCashOnCash,
        noi: testNOI,
        monthlyCashFlow,
        irr: testIRR,
      });
    });

    // Set recommended price using a more conservative approach
    const recommendedPrice = Math.min(
      currentPrice * 1.1, // Max 10% above current price
      Math.min(maxPriceByCapRate, maxPriceByCashOnCash, maxPriceByIRR)
    );

    setMaxPriceAnalysis((prev) => ({
      ...prev,
      maxPrices: {
        byCapRate: maxPriceByCapRate,
        byCashOnCash: maxPriceByCashOnCash,
        byIRR: maxPriceByIRR,
      },
      recommendedPrice,
      priceRange,
      noi,
      currentPrice,
    }));
  };

  // Helper function for IRR calculation
  const calculateSimpleIRR = (cashFlows, targetRate) => {
    const npv = (rate) => {
      return cashFlows.reduce((sum, cashFlow, index) => {
        return sum + cashFlow / Math.pow(1 + rate, index);
      }, 0);
    };

    // Simple approximation
    const currentNPV = npv(targetRate);
    return targetRate * (1 + currentNPV / Math.abs(cashFlows[0]));
  };

  useEffect(() => {
    updateCalculations();
  }, [propertyDetails, financialParams]);

  useEffect(() => {
    generateProjections();
  }, [propertyDetails, financialParams, calculations]);

  useEffect(() => {
    generateDebtAnalysis();
  }, [calculations.monthlyMortgage]);

  useEffect(() => {
    calculateReturnMetrics();
  }, [projections.yearlyData, debtAnalysis.totalEquityBuildup]);

  useEffect(() => {
    calculateMaxPrices();
  }, [
    calculations.noi,
    propertyDetails.monthlyRent,
    propertyDetails.purchasePrice,
    maxPriceAnalysis.targetMetrics,
  ]);

  const handlePropertyInputChange = (e) => {
    const { name, value } = e.target;
    setPropertyDetails((prev) => ({
      ...prev,
      [name]: parseFloat(value) || 0,
    }));
  };

  const handleFinancialInputChange = (e) => {
    const { name, value } = e.target;
    setFinancialParams((prev) => ({
      ...prev,
      [name]: parseFloat(value) || 0,
    }));
  };

  const calculateScenarios = () => {
    const scenarios = { ...scenarioAnalysis.scenarios };
    const sensitivity = {
      purchasePrice: [],
      monthlyRent: [],
      interestRate: [],
      downPayment: [],
    };

    // Calculate each scenario
    Object.keys(scenarios).forEach((scenarioType) => {
      const scenario = scenarios[scenarioType];
      const tempParams = { ...financialParams };

      // Override parameters for this scenario
      tempParams.appreciationRate = scenario.appreciationRate;
      tempParams.rentGrowthRate = scenario.rentGrowthRate;
      tempParams.expenseGrowthRate = scenario.expenseGrowthRate;
      tempParams.vacancyRate = scenario.vacancyRate;
      tempParams.maintenanceRate = scenario.maintenanceRate;

      // Calculate projections with scenario parameters
      const yearlyData = [];
      let propertyValue = propertyDetails.purchasePrice;
      let rent = propertyDetails.monthlyRent;
      let totalCashFlow = 0;
      let cashFlows = [
        -propertyDetails.purchasePrice * (tempParams.downPayment / 100),
      ];

      const initialYearlyExpenses =
        (calculations.monthlyTaxes +
          calculations.monthlyInsurance +
          calculations.monthlyMaintenance +
          calculations.monthlyVacancy +
          calculations.monthlyManagement) *
        12;

      for (let year = 1; year <= 30; year++) {
        propertyValue *= 1 + tempParams.appreciationRate / 100;
        rent *= 1 + tempParams.rentGrowthRate / 100;

        const yearlyRent = rent * 12;
        const yearlyExpenses =
          initialYearlyExpenses *
          Math.pow(1 + tempParams.expenseGrowthRate / 100, year);
        const yearlyMortgage = calculations.monthlyMortgage * 12;
        const yearlyCashFlow = yearlyRent - yearlyExpenses - yearlyMortgage;

        totalCashFlow += yearlyCashFlow;
        cashFlows.push(yearlyCashFlow);

        if (year === 30) {
          cashFlows[year] +=
            propertyValue * (1 - tempParams.sellingCosts / 100);
        }

        yearlyData.push({
          year,
          propertyValue,
          yearlyRent,
          yearlyCashFlow,
          cumCashFlow: totalCashFlow,
        });
      }

      // Update scenario metrics
      scenarios[scenarioType] = {
        ...scenario,
        irr: calculateIRR(cashFlows),
        cashOnCash:
          (yearlyData[0].yearlyCashFlow /
            ((propertyDetails.purchasePrice * tempParams.downPayment) / 100)) *
          100,
        totalReturn:
          totalCashFlow + (propertyValue - propertyDetails.purchasePrice),
      };
    });

    // Calculate sensitivity analysis
    const baseMetrics = scenarios.base;
    const variations = [-20, -10, -5, 0, 5, 10, 20];

    // Purchase price sensitivity
    variations.forEach((variation) => {
      const modifiedPrice =
        propertyDetails.purchasePrice * (1 + variation / 100);
      const metrics = calculateMetricsWithPrice(modifiedPrice);
      sensitivity.purchasePrice.push({
        variation,
        price: modifiedPrice,
        irr: metrics.irr,
        cashOnCash: metrics.cashOnCash,
      });
    });

    // Monthly rent sensitivity
    variations.forEach((variation) => {
      const modifiedRent = propertyDetails.monthlyRent * (1 + variation / 100);
      const metrics = calculateMetricsWithRent(modifiedRent);
      sensitivity.monthlyRent.push({
        variation,
        rent: modifiedRent,
        irr: metrics.irr,
        cashOnCash: metrics.cashOnCash,
      });
    });

    // Interest rate sensitivity
    [-2, -1, -0.5, 0, 0.5, 1, 2].forEach((variation) => {
      const modifiedRate = financialParams.interestRate + variation;
      const metrics = calculateMetricsWithInterestRate(modifiedRate);
      sensitivity.interestRate.push({
        variation,
        rate: modifiedRate,
        irr: metrics.irr,
        cashOnCash: metrics.cashOnCash,
      });
    });

    setScenarioAnalysis({
      scenarios,
      sensitivity,
    });
  };

  const calculateMetricsWithPrice = (price) => {
    // Similar to generateProjections but with modified price
    // Returns IRR and Cash on Cash Return
    return { irr: 0, cashOnCash: 0 }; // Placeholder
  };

  const calculateMetricsWithRent = (rent) => {
    // Similar to generateProjections but with modified rent
    // Returns IRR and Cash on Cash Return
    return { irr: 0, cashOnCash: 0 }; // Placeholder
  };

  const calculateMetricsWithInterestRate = (rate) => {
    // Similar to generateProjections but with modified interest rate
    // Returns IRR and Cash on Cash Return
    return { irr: 0, cashOnCash: 0 }; // Placeholder
  };

  useEffect(() => {
    calculateReturnMetrics();
    calculateScenarios();
  }, [projections.yearlyData, debtAnalysis.totalEquityBuildup]);

  return (
    <div className="mx-auto mt-4">
      {/* Navigation Menu */}
      <StickyMenu />
      {/* Property Details Section */}
      <section id="property-details" className="mb-4">
        <PropertyDetails
          propertyDetails={propertyDetails}
          handlePropertyInputChange={handlePropertyInputChange}
          demographicsData={demographics}
        />
      </section>

      {/* Financial Inputs Section */}
      <section id="financial-inputs" className="mb-4">
        <FinancialParameters
          financialParams={financialParams}
          handleFinancialInputChange={handleFinancialInputChange}
          propertyDetails={propertyDetails}
          calculations={calculations}
        />
      </section>

      {/* Key Metrics Section */}
      <section id="key-metrics" className="mb-4">
        <KeyMetrics calculations={calculations} />
      </section>

      {/* Monthly Analysis Section */}
      <section id="monthly-analysis" className="mb-4">
        <CashFlowAnalysis
          calculations={calculations}
          propertyDetails={propertyDetails}
        />
      </section>

      {/* Expense Breakdown Section */}
      <section id="expense-breakdown" className="mb-4">
        <ExpenseBreakdown calculations={calculations} />
      </section>

      <section id="projections" className="mb-8">
        <Projections projections={projections} />
      </section>

      {/* Debt Analysis Section */}
      <section id="debt-analysis" className="mb-8">
        <DebtAnalysis debtAnalysis={debtAnalysis} />
      </section>

      {/* Return Analysis Section */}
      <section id="return-analysis" className="mb-8">
        <ReturnAnalysis returnMetrics={returnMetrics} 
        calculations={calculations}
        propertyDetails={propertyDetails}
        financialParams={financialParams}/>
      </section>

      {/* Maximum Purchase Price Analysis */}
      <section id="max-price-analysis" className="mb-8">
        <MaxPriceAnalysis maxPriceAnalysis={maxPriceAnalysis} />
      </section>

      {/* Property Value & Cash Flow Chart */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          Property Value & Cash Flow Projections
        </h2>
        <div className="bg-white rounded shadow p-6">
          <div style={{ width: "100%", height: 400 }}>
            <ResponsiveContainer>
              <LineChart
                data={projections.yearlyData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" />
                <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                <Tooltip formatter={(value) => `${value.toLocaleString()}`} />
                <Legend />
                <Line
                  yAxisId="left"
                  type="monotone"
                  dataKey="propertyValue"
                  stroke="#8884d8"
                  name="Property Value"
                />
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="yearlyCashFlow"
                  stroke="#82ca9d"
                  name="Annual Cash Flow"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </section>

      {/* Return Composition Chart */}
      <section className="mb-8">
        <ReturnComposition returnMetrics={returnMetrics} />
      </section>

      {/* Return Composition Chart */}
      <section className="mb-8">
        <DemographicsData demographicsData={demographics} />
      </section>

      <section className="mb-4">
        <GenerateReportSection   propertyDetails={propertyDetails}
            calculations={calculations}
            financialParams={financialParams}
            projections={projections}
            demographicsData={demographicsData}
          />
                  
      </section>
    </div>
  );
};

export default PropertyAnalysis;
