import React from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const DebtAnalysis = ({ debtAnalysis }) => {
  return (
    <div className="surface-card p-4 border-round shadow-1">
    <div className="flex justify-content-between align-items-center mb-4">
      <h2 className="text-xl m-0 font-medium">Principal vs Interest Payments</h2>
    </div>

      {/* Principal vs Interest Section */}
      <div className="surface-card p-3 border-round mb-3">
        <div className="grid">
          {/* Total Interest Paid */}
          <div className="col-12 md:col-4 p-2">
            <div className="surface-100 p-3 border-round">
              <span className="block text-600 text-sm mb-2">Total Interest Paid</span>
              <span className="text-900 text-xl">
                ${Math.round(debtAnalysis?.totalInterestPaid || 0).toLocaleString()}
              </span>
            </div>
          </div>

          {/* Total Equity Buildup */}
          <div className="col-12 md:col-4 p-2">
            <div className="surface-100 p-3 border-round">
              <span className="block text-600 text-sm mb-2">Total Equity Buildup</span>
              <span className="text-900 text-xl">
                ${Math.round(debtAnalysis?.totalEquityBuildup || 0).toLocaleString()}
              </span>
            </div>
          </div>

          {/* Current Loan to Value */}
          <div className="col-12 md:col-4 p-2">
            <div className="surface-100 p-3 border-round">
              <span className="block text-600 text-sm mb-2">Current Loan to Value</span>
              <span className="text-900 text-xl">
                {(debtAnalysis?.loanToValue || 0).toFixed(2)}%
              </span>
            </div>
          </div>
        </div>

        {/* Chart */}
        <div className="mt-3" style={{ height: '300px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart 
              data={debtAnalysis?.amortizationSchedule || []}
              margin={{ top: 10, right: 30, left: 0, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
              <XAxis 
                dataKey="year" 
                tick={{ fontSize: 12 }}
              />
              <YAxis 
                tick={{ fontSize: 12 }}
                tickFormatter={(value) => `$${(value/1000)}k`}
              />
              <Tooltip 
                formatter={(value) => [`$${value.toLocaleString()}`, '']}
                contentStyle={{ fontSize: 12 }}
              />
              <Legend />
              <Bar 
                dataKey="principalPayment" 
                stackId="a" 
                fill="#4338ca" 
                name="Principal"
              />
              <Bar 
                dataKey="interestPayment" 
                stackId="a" 
                fill="#16a34a" 
                name="Interest"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Additional Metrics */}
        <div className="mt-3">
          <div className="surface-100 p-3 border-round">
            <span className="block text-600 text-sm mb-2">Debt Service Coverage Ratio</span>
            <span className="text-900 text-xl">
              {(debtAnalysis?.debtServiceCoverageRatio || 0).toFixed(2)}
            </span>
          </div>
        </div>
      </div>

      {/* Optional Help Text */}
      <div className="text-center">
        <span className="text-500 text-xs">* Based on current loan terms and property value</span>
      </div>
    </div>
  );
};

export default DebtAnalysis;