import React from "react";
import { InputNumber } from "primereact/inputnumber";

const FinancialParameters = ({
  financialParams = {},
  handleFinancialInputChange,
  propertyDetails,
  calculations,
}) => {
  // Helper to determine if a parameter should be displayed as currency
  const isCurrencyParam = (key) => {
    return [
      "hoaMonthly",
      "utilitiesMonthly",
      "otherMonthlyExpenses",
      "repairBudget",
    ].includes(key);
  };

  // Helper to determine if a parameter is a percentage
  const isPercentageParam = (key) => {
    return !isCurrencyParam(key);
  };

  const getParameterGroup = (key) => {
    if (
      [
        "hoaMonthly",
        "utilitiesMonthly",
        "otherMonthlyExpenses",
        "repairBudget",
      ].includes(key)
    ) {
      return "currency";
    }
    if (key === "loanTerm") {
      return "years";
    }
    return "percentage";
  };

  const getInputMode = (key) => {
    switch (getParameterGroup(key)) {
      case "currency":
        return { mode: "currency", currency: "USD", locale: "en-US" };
      case "years":
        return { mode: "decimal", suffix: " Years" };
      default:
        return { mode: "decimal", suffix: " %" };
    }
  };

  // Enhanced calculate amount function
  const calculateAmount = (key, rate) => {
    if (!propertyDetails?.purchasePrice || !rate) return 0;
    const monthlyRent = propertyDetails.monthlyRent || 0;

    switch (key) {
      case "downPayment":
        return (propertyDetails.purchasePrice * rate) / 100;
      case "closingCosts":
        return (propertyDetails.purchasePrice * rate) / 100;
      case "propertyTaxRate":
        return (propertyDetails.purchasePrice * rate) / 100 / 12; // Monthly
      case "insuranceRate":
        return (propertyDetails.purchasePrice * rate) / 100 / 12; // Monthly
      case "maintenanceRate":
        return (monthlyRent * rate) / 100; // Monthly
      case "vacancyRate":
        return (monthlyRent * rate) / 100; // Monthly
      case "propertyManagementRate":
        return (monthlyRent * rate) / 100; // Monthly
      case "interestRate":
        if (
          propertyDetails?.purchasePrice &&
          rate &&
          financialParams?.loanTerm &&
          financialParams?.downPayment
        ) {
          const loanAmount =
            propertyDetails.purchasePrice *
            (1 - financialParams.downPayment / 100);
          const monthlyRate = rate / 100 / 12;
          const totalMonths = financialParams.loanTerm * 12;

          // Amortization formula: PMT = P * (r(1+r)^n)/((1+r)^n-1)
          const monthlyPayment =
            (loanAmount *
              (monthlyRate * Math.pow(1 + monthlyRate, totalMonths))) /
            (Math.pow(1 + monthlyRate, totalMonths) - 1);

          return monthlyPayment; // This will show as the monthly payment amount
        }
        return 0;
      case "sellingCosts":
        return (propertyDetails.purchasePrice * rate) / 100; // At sale
      default:
        return 0;
    }
  };

  // Format currency
  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  // Format currency with cents for monthly amounts
  const formatCurrencyWithCents = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  // Should show actual amount for this parameter
  const shouldShowAmount = (key) => {
    return [
      "downPayment",
      "closingCosts",
      "propertyTaxRate",
      "insuranceRate",
      "maintenanceRate",
      "vacancyRate",
      "interestRate",
      "propertyManagementRate",
      "sellingCosts",
    ].includes(key);
  };

  // Format amount based on parameter type
  const getFormattedAmount = (key, value) => {
    const amount = calculateAmount(key, value);

    // Parameters that show monthly amounts
    const monthlyParams = [
      "propertyTaxRate",
      "insuranceRate",
      "maintenanceRate",
      "vacancyRate",
      "propertyManagementRate",
      "interestRate",
    ];

    if (monthlyParams.includes(key)) {
      return formatCurrencyWithCents(amount) + "/mo";
    }

    return formatCurrency(amount);
  };

  // Group parameters by type (with null check)
  const paramEntries = Object.entries(financialParams || {});
  const rateParams = paramEntries.filter(
    ([key]) => getParameterGroup(key) !== "currency"
  );
  const currencyParams = paramEntries.filter(
    ([key]) => getParameterGroup(key) === "currency"
  );

  return (
    <section className="mb-4">
      <div className="surface-card p-4 border-round shadow-1">
        <div className="flex justify-content-between align-items-center mb-4">
          <h2 className="text-2xl font-bold mb-4">Financial Parameters</h2>
        </div>

        {/* Rate Parameters */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-3">Rate Parameters</h3>
          <div className="grid">
            {rateParams.map(([key, value]) => (
              <div key={key} className="col-12 md:col-4 p-2">
                <div className="bg-gray-50 p-3 border-round">
                  <div className="flex justify-content-between align-items-center mb-2">
                    <span className="text-600 text-sm">
                      {key === "loanTerm"
                        ? "Loan Term"
                        : `${key
                            .replace(/([A-Z])/g, " $1")
                            .replace(/^./, (str) => str.toUpperCase())}`}
                    </span>
                    {shouldShowAmount(key) && (
                      <span className="text-600 text-sm">
                        {getFormattedAmount(key, value)}
                      </span>
                    )}
                  </div>
                  <InputNumber
                    value={value}
                    onValueChange={(e) =>
                      handleFinancialInputChange({
                        target: { name: key, value: e.value },
                      })
                    }
                    mode="decimal"
                    minFractionDigits={key === "loanTerm" ? 0 : 1}
                    maxFractionDigits={key === "loanTerm" ? 0 : 2}
                    size="small"
                    className="w-full"
                    useGrouping={false}
                    placeholder="0.00"
                    suffix={key === "loanTerm" ? " Years" : " %"}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Monthly Expenses */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-3">Monthly Expenses ($)</h3>
          <div className="grid">
            {currencyParams.map(([key, value]) => (
              <div key={key} className="col-12 md:col-4 p-2">
                <div className="bg-gray-50 p-3 border-round">
                  <span className="block text-600 text-sm mb-2">
                    {key
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
                  </span>
                  <InputNumber
                    value={value}
                    onValueChange={(e) =>
                      handleFinancialInputChange({
                        target: { name: key, value: e.value },
                      })
                    }
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    size="small"
                    className="w-full"
                    placeholder="0.00"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Help Text */}
        <div className="text-center mt-2">
          <span className="text-500 text-xs">
            * Rate parameters are expressed as percentages, expenses as USD
          </span>
        </div>
      </div>
    </section>
  );
};

export default FinancialParameters;
