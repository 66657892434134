import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Slider } from 'primereact/slider';
import { InputNumber } from 'primereact/inputnumber';
import { Message } from 'primereact/message';
import { Chart } from 'primereact/chart';
import { Panel } from 'primereact/panel';
import { Divider } from 'primereact/divider';
import { DollarSign, TrendingUp } from 'lucide-react';

const CashflowScenarioExplorer = () => {
  const [propertyPrice, setPropertyPrice] = useState(300000);
  const [downPaymentPercent, setDownPaymentPercent] = useState(20);
  const [mortgageRate, setMortgageRate] = useState(6.5);
  const [rentalPrice, setRentalPrice] = useState(2000);
  const [expenses, setExpenses] = useState(25);

  const calculateMetrics = () => {
    const downPayment = (downPaymentPercent / 100) * propertyPrice;
    const loanAmount = propertyPrice - downPayment;
    
    const monthlyRate = mortgageRate / 100 / 12;
    const months = 30 * 12;
    const monthlyMortgage = (loanAmount * monthlyRate * Math.pow(1 + monthlyRate, months)) / 
                           (Math.pow(1 + monthlyRate, months) - 1);
    
    const monthlyExpenses = (rentalPrice * expenses) / 100;
    const monthlyCashflow = rentalPrice - monthlyMortgage - monthlyExpenses;
    
    const annualCashflow = monthlyCashflow * 12;
    const roi = (annualCashflow / downPayment) * 100;

    return {
      cashflow: monthlyCashflow,
      roi: roi,
      mortgage: monthlyMortgage,
      totalExpenses: monthlyExpenses
    };
  };

  const metrics = calculateMetrics();

  const getOptimizationTips = () => {
    const rentToPrice = (rentalPrice * 12) / propertyPrice * 100;
    
    return {
      cashflowTips: [
        rentToPrice < 8 ? "Consider properties with higher rent-to-price ratio (aim for >8% annually)" : "Good rent-to-price ratio",
        downPaymentPercent < 25 ? "Increasing down payment will improve monthly cashflow" : "Down payment is in a good range",
        expenses > 30 ? "Look for ways to reduce monthly expenses" : "Expense ratio is reasonable",
      ],
      roiTips: [
        downPaymentPercent > 25 ? "Lower down payment could increase ROI but with higher risk" : "Down payment provides good ROI balance",
        mortgageRate > 6 ? "Consider refinancing or finding better loan terms" : "Mortgage rate is competitive",
      ]
    };
  };

  const tips = getOptimizationTips();

  const chartData = {
    labels: ['Conservative', 'Expected', 'Optimistic'],
    datasets: [
      {
        label: 'Monthly Cashflow',
        data: [
          metrics.cashflow * 0.9,
          metrics.cashflow,
          metrics.cashflow * 1.1
        ],
        borderColor: '#2196F3',
        backgroundColor: '#2196F3',
        tension: 0.4
      }
    ]
  };

  const chartOptions = {
    plugins: {
      legend: {
        labels: {
          color: '#495057'
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#495057'
        },
        grid: {
          color: '#ebedef'
        }
      },
      y: {
        ticks: {
          color: '#495057'
        },
        grid: {
          color: '#ebedef'
        }
      }
    }
  };

  return (
    <div className="container ">
    <div className="p-4">
      <Card className="shadow-lg">
        <div className="text-2xl font-bold mb-4 text-blue-800">Cashflow Scenario Explorer</div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-4">
            <Panel header="Property Details" className="shadow-sm">
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Property Price</label>
                <InputNumber
                  value={propertyPrice}
                  onValueChange={(e) => setPropertyPrice(e.value)}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  className="w-full"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Down Payment: {downPaymentPercent}% (${(propertyPrice * downPaymentPercent / 100).toLocaleString()})
                </label>
                <Slider
                  value={downPaymentPercent}
                  onChange={(e) => setDownPaymentPercent(e.value)}
                  min={3}
                  max={50}
                  className="w-full"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Mortgage Rate: {mortgageRate}%</label>
                <Slider
                  value={mortgageRate}
                  onChange={(e) => setMortgageRate(e.value)}
                  min={3}
                  max={10}
                  step={0.1}
                  className="w-full"
                />
              </div>
            </Panel>

            <Panel header="Income & Expenses" className="shadow-sm">
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Monthly Rent</label>
                <InputNumber
                  value={rentalPrice}
                  onValueChange={(e) => setRentalPrice(e.value)}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  className="w-full"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Monthly Expenses: {expenses}% of Rent</label>
                <Slider
                  value={expenses}
                  onChange={(e) => setExpenses(e.value)}
                  min={15}
                  max={40}
                  className="w-full"
                />
              </div>
            </Panel>
          </div>

          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <Card className="bg-blue-50">
                <div className="flex items-center gap-2 mb-2">
                  <DollarSign className="h-4 w-4 text-blue-600" />
                  <h3 className="font-medium text-blue-800">Monthly Cashflow</h3>
                </div>
                <p className="text-2xl font-bold text-blue-600">
                  ${metrics.cashflow.toFixed(2)}
                </p>
              </Card>
              
              <Card className="bg-green-50">
                <div className="flex items-center gap-2 mb-2">
                  <TrendingUp className="h-4 w-4 text-green-600" />
                  <h3 className="font-medium text-green-800">Cash on Cash ROI</h3>
                </div>
                <p className="text-2xl font-bold text-green-600">
                  {metrics.roi.toFixed(2)}%
                </p>
              </Card>
            </div>

            <Panel header="Optimization Tips" className="shadow-sm mt-4">
              <div className="space-y-4">
                <div>
                  <Message severity="info" text="Cashflow Optimization Tips:" />
                  <ul className="list-disc pl-4 mt-2">
                    {tips.cashflowTips.map((tip, i) => (
                      <li key={i} className="text-sm text-gray-600">{tip}</li>
                    ))}
                  </ul>
                </div>
                <Divider />
                <div>
                  <Message severity="success" text="ROI Optimization Tips:" />
                  <ul className="list-disc pl-4 mt-2">
                    {tips.roiTips.map((tip, i) => (
                      <li key={i} className="text-sm text-gray-600">{tip}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </Panel>

            <Panel header="Cashflow Scenarios" className="shadow-sm mt-4">
              <Chart type="line" data={chartData} options={chartOptions} />
            </Panel>
          </div>
        </div>
      </Card>
    </div>
    </div>
  );
};

export default CashflowScenarioExplorer;