import InvestorPDFReport from "./invsetorreport.component";
import LenderPDFReport from "./lenderreport.component";

const GenerateReportSection = ({
  propertyDetails,
  calculations,
  financialParams,
  projections,
  demographicsData,
}) => {
  return (
    <div className="surface-card p-4 border-round shadow-1">
      <div className="flex justify-content-between align-items-center mb-4">
        <h2 className="text-xl font-medium text-gray-800 m-0">
          Generate Report
        </h2>

        <div className="flex flex-col gap-3">
          <div>
          <LenderPDFReport
              propertyDetails={propertyDetails}
              calculations={calculations}
              financialParams={financialParams}
              projections={projections}
              demographicsData={demographicsData}
            />
          </div>

          <div>
          <InvestorPDFReport
              propertyDetails={propertyDetails}
              calculations={calculations}
              financialParams={financialParams}
              projections={projections}
              demographicsData={demographicsData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateReportSection;
