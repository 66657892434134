import React, { useState, useEffect } from "react";
import { Tree } from "primereact/tree";
import defaultThumbnail from "../../PhotoNotAvailable_Large.gif";
import "../../ListView.css";
import { Paginator } from "primereact/paginator";
import { formatPrice } from "./utils";
import { calculateReturns } from "../../common/helpers/functions";

const ListView = ({ products, onNodeSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    setNodes(buildNodes(products));
  }, [products]);

  const formatCashFlow = (cashFlow) => {
    return cashFlow < 0
      ? `(${Math.abs(cashFlow)?.toFixed(2)})`
      : cashFlow?.toFixed(2);
  };

  const getCashFlowStyle = (cashFlow) => {
    return {
      color: cashFlow < 0 ? 'red' : '#007bff'
    };
  };

  const calcCaprate = (product) => {
    const monthlyRent = (product.rentalestimate !== null && product.rentalestimate !== "") ? 
                   product.rentalestimate :
                   (product.fmr !== null && product.fmr !== "" ? product.fmr : 0);

    const savedSettings = JSON.parse(localStorage.getItem("settings"));
    const results = calculateReturns({
      purchasePrice: product.price,
      downPaymentPercent: savedSettings?.downpayment ?? 25,
      interestRate: product.latestMortgageRates.pmms30,
      loanTermYears: savedSettings?.loanterm ?? 30,
      monthlyRent: monthlyRent,
      vacancyRatePercent: product.rental_vacancy_rate ?? 6,
      maintenancePercent: 10,
      operatingExpensePercent: 10,
      appreciationRate: 3,
      holdingPeriodYears: 5,
      sellingCostsPercent: 6,
      hoafee: product.hoa,
      propertyTaxRate: product.propertyTaxes?.Property_Tax_Rate || "0%",
      insuranceRatePercent: 0.005
    });
    return {
      annualPreTaxCashFlow: results.annualPreTaxCashFlow,
      cashOnCashReturn: results.cashOnCashReturn,
      ROI: results.ROI,
      capRate: results.capRate,
    };
  };

  const buildNodes = (data) => {
    return data.map((product, index) => {
      const capRateResults = calcCaprate(product);
      const monthlyCashFlow = capRateResults
        ? capRateResults.annualPreTaxCashFlow / 12
        : 0;
      const capRate = capRateResults
        ? capRateResults.capRate
        : 0;

      const tags = [];
      
      // Add tags based on conditions
      // Add base cash flow tags
      if (monthlyCashFlow > 0) {
        tags.push({ text: 'Positive Cash Flow', color: 'bg-green-100 text-green-600' });
      }
      if (monthlyCashFlow < 0) {
        tags.push({ text: 'Negative Cash Flow', color: 'bg-red-100 text-red-600' });
      }

      // Add size tag
      if (product.squareFeet > 2000) {
        tags.push({ text: 'Large', color: 'bg-gray-100 text-gray-600' });
      }

      // Add cap rate tags
      if (capRate > 7) {
        tags.push({ text: 'High Cap Rate', color: 'bg-purple-100 text-purple-600' });
      }

      // Add Best Value tag
      if (capRate > 7 && monthlyCashFlow > 0) {
        tags.push({ text: 'Best Value', color: 'bg-yellow-100 text-yellow-600' });
      }

      // Add Starter Home tag
      if (product.price <= 200000 && monthlyCashFlow > 0) {
        tags.push({ text: 'Starter Home', color: 'bg-teal-100 text-teal-600' });
      }

      // Add Multi-Unit tag
      if (product.property_type?.toLowerCase().includes('multi-family')) {
        tags.push({ text: 'Multi-Unit', color: 'bg-indigo-100 text-indigo-600' });
      }

      // Add Low Vacancy Area tag
      if (product.rental_vacancy_rate && product.rental_vacancy_rate < 5) {
        tags.push({ text: 'Low Vacancy Area', color: 'bg-blue-100 text-blue-600' });
      }

      // Add New Listing tag
      if (product.active_days_on_site && product.active_days_on_site < 7) {
        tags.push({ text: 'New Listing', color: 'bg-rose-100 text-rose-600' });
      }

      // Add Low Down Payment tag
      if (product.downPayment <= 50000 && monthlyCashFlow > 0) {
        tags.push({ text: 'Low Down Payment', color: 'bg-blue-100 text-blue-600' });
      }

      // Add High Leverage tag
      const downPaymentPercentage = (product.downPayment / product.price) * 100;
      if (downPaymentPercentage < 20) {
        tags.push({ text: 'High Leverage', color: 'bg-orange-100 text-orange-600' });
      }

      return {
        key: index.toString(),
        label: (
          <div>
            <div className="property-node">
              <div className="thumbnail-container">
                <img
                  src={product.thumbnail || defaultThumbnail}
                  alt="Thumbnail"
                  className="list-thumbnail-img mr-2"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = defaultThumbnail;
                  }}
                />                
              </div>

              <div>
                <div className="address text-600 font-bold text-sm">
                  {product.fullAddress}
                </div>
                <div className="details text-14 mt-1">
                  {product.property_type || "-"} • {product.bedrooms} beds •{" "}
                  {product.bathrooms} baths • {product.squareFeet} Sq.Ft.
                </div>
                <div className="financial mt-1">                  
                  <span className="cf pr-1">
                    Cash-flow: ${formatCashFlow(monthlyCashFlow)}/mo 
                  </span>
                  <span className="cp pr-1">
                    Cap Rate: %{capRate} 
                  </span>                   
                  {formatPrice(product.price)}
                </div>
                {/* New tags section */}
                <div className="mt-1 gap-1 flex flex-wrap">
                  {tags.map((tag, i) => (
                    <span 
                      key={i} 
                      className={`inline-block px-2 py-0.5 text-xs rounded ${tag.color}`}
                    >
                      {tag.text}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ),
        data: product,
        children: [],
      };
    });
  };

  const filteredProducts = products.filter((product) => {
    return product.fullAddress.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const sortedProducts = filteredProducts.sort((a, b) => {
    if (!sortKey) return 0;
    let result = a[sortKey] < b[sortKey] ? -1 : a[sortKey] > b[sortKey] ? 1 : 0;
    return sortOrder * result;
  });

  const paginatedProducts = sortedProducts.slice(first, first + rows);
  const paginatedNodes = buildNodes(paginatedProducts);

  return (
    <div className="card">
      <Tree
        value={paginatedNodes}
        onNodeClick={(e) => onNodeSelect(e.node.data)}
      />
      <Paginator
        first={first}
        rows={rows}
        totalRecords={sortedProducts.length}
        rowsPerPageOptions={[5, 10, 20]}
        onPageChange={(e) => {
          setFirst(e.first);
          setRows(e.rows);
        }}
      />
    </div>
  );
};

export default ListView;