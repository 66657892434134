import React from 'react';

const CashFlowAnalysis = ({ propertyDetails = {}, calculations = {} }) => {
  return (
    <div className="surface-card p-4 border-round shadow-1">
      <div className="flex justify-content-between align-items-center mb-4">
        <h2 className="text-xl m-0 font-medium">Monthly Cash Flow Analysis</h2>
        
      </div>

      <div className="grid">
        <div className="col-12 md:col-6 p-2">
          <div className="surface-100 p-3 border-round mb-3">
            <span className="text-600 text-sm block mb-1">Monthly Rental Income</span>
            <div className="flex justify-content-between align-items-center">
              <span className="text-green-600 text-xl">${(propertyDetails.monthlyRent || 0).toFixed(2)}</span>
              <i className="pi pi-dollar text-green-500"></i>
            </div>
          </div>
        </div>

        <div className="col-12 md:col-6 p-2">
          <div className="bg-gray-50 p-3 border-round">
            <span className="text-600 text-sm block mb-1">Total Monthly Expenses</span>
            <div className="flex justify-content-between align-items-center">
              <span className="text-red-600 text-xl">${(calculations.totalMonthlyExpenses || 0).toFixed(2)}</span>
              <i className="pi pi-wallet text-red-500"></i>
            </div>
          </div>
        </div>

        <div className="col-12 md:col-6 p-2">
          <div className="bg-gray-50 p-3 border-round">
            <span className="text-600 text-sm block mb-1">Monthly Cash Flow</span>
            <div className="flex justify-content-between align-items-center">
              <span className="text-blue-600 text-xl">${(calculations.monthlyCashFlow || 0).toFixed(2)}</span>
              <i className="pi pi-chart-line text-blue-500"></i>
            </div>
          </div>
        </div>

        <div className="col-12 md:col-6 p-2">
          <div className="bg-gray-50 p-3 border-round">
            <span className="text-600 text-sm block mb-1">Net Operating Income (Annual)</span>
            <div className="flex justify-content-between align-items-center">
              <span className="text-yellow-600 text-xl">${(calculations.noi || 0).toFixed(2)}</span>
              <i className="pi pi-chart-bar text-yellow-500"></i>
            </div>
          </div>
        </div>

        
      </div>
      <div className="text-center mt-2">
        <span className="text-500 text-xs">* Based on current market conditions and property performance metrics</span>
      </div>
    </div>
  );
};

export default CashFlowAnalysis;