import React, { useState, useEffect, useRef } from "react";

const StageDemo = () => {
  const [image, setImage] = useState(null);
  const [processedImage, setProcessedImage] = useState(null);
  const [sliderPosition, setSliderPosition] = useState(50);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedEffect, setSelectedEffect] = useState("enhance");
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);

  const sliderRef = useRef(null);

  const effects = [
    {
      id: "furnishEmptyRoom",
      label: "Furnish Empty Room",
      filter: "brightness(1.1) contrast(1.1)",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <rect x="3" y="3" width="18" height="18" rx="2" />
          <path d="M3 12h18" />
          <path d="M12 3v18" />
        </svg>
      ),
    },
    {
      id: "renovateRoom",
      label: "Renovate Room",
      filter: "brightness(1.2) contrast(1.2) saturate(1.1)",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path d="M2 20h20" />
          <path d="M5 20V8h14v12" />
          <path d="M9 16h6" />
        </svg>
      ),
    },
    {
      id: "renovateAndFurnish",
      label: "Renovate & Furnish",
      filter: "brightness(1.3) contrast(1.2) saturate(1.2)",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path d="M3 12l2-2m0 0l7-7 7 7M5 10v10h14V10M9 21V15h6v6" />
        </svg>
      ),
    },
    {
      id: "modifyExterior",
      label: "Modify Exterior",
      filter: "brightness(1.1) contrast(1.15) saturate(1.1)",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path d="M3 21h18" />
          <path d="M5 21V7l7-4 7 4v14" />
          <path d="M9 9h6" />
          <path d="M9 13h6" />
          <path d="M9 17h6" />
        </svg>
      ),
    },
    {
      id: "blueSky",
      label: "Blue Sky",
      filter: "brightness(1.1) contrast(1.2) saturate(1.3)",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <circle cx="12" cy="12" r="4" />
          <path d="M12 2v2" />
          <path d="M12 20v2" />
          <path d="M20 12h2" />
          <path d="M2 12h2" />
        </svg>
      ),
    },
    {
      id: "createKitchenBath",
      label: "Create Kitchen/Bath",
      filter: "brightness(1.2) contrast(1.1) saturate(1.1)",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <rect x="3" y="3" width="18" height="18" rx="2" />
          <path d="M3 9h18" />
          <path d="M9 21V9" />
        </svg>
      ),
    },
    {
      id: "enhanceQuality",
      label: "Enhance Quality",
      filter: "brightness(1.2) contrast(1.2) saturate(1.2)",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <circle cx="12" cy="12" r="3" />
          <path d="M12 5v2M12 17v2M5 12h2M17 12h2" />
        </svg>
      ),
    },
    {
      id: "removeLogo",
      label: "Remove Logo",
      filter: "brightness(1.1) contrast(1.1)",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <circle cx="12" cy="12" r="10" />
          <path d="M15 9l-6 6M9 9l6 6" />
        </svg>
      ),
    },
    {
      id: "declutterSpace",
      label: "Declutter Space",
      filter: "brightness(1.1) contrast(1.1) saturate(1.05)",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path d="M4 4h16" />
          <path d="M4 12h16" />
          <path d="M4 20h16" />
        </svg>
      ),
    },
  ];

  // Add file validation
  const validateFile = (file) => {
    // Check if file exists
    if (!file) {
      throw new Error("Please select an image file.");
    }

    // Check file type
    const validTypes = ["image/jpeg", "image/png", "image/webp"];
    if (!validTypes.includes(file.type)) {
      throw new Error("Please upload a JPEG, PNG, or WebP image.");
    }

    // Check file size (max 5MB)
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
    if (file.size > maxSize) {
      throw new Error("Image size should be less than 5MB.");
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files?.[0];
    setError(null);

    try {
      validateFile(file);

      const reader = new FileReader();
      reader.onload = (event) => {
        const imageData = event.target?.result;
        setImage(imageData);
        
      };

      reader.onerror = () => {
        throw new Error("Failed to read the image file. Please try again.");
      };

      reader.readAsDataURL(file);
    } catch (err) {
      setError(err.message);
      // Clear the file input
      e.target.value = "";
    }
  };

  const processImage = async (imageData, effectId) => {
    setIsProcessing(true);
    setError(null);

    try {
      // Convert base64 to blob
      const response = await fetch(imageData);
      const blob = await response.blob();

      // Create form data
      const formData = new FormData();
      formData.append("image", blob, 'image.jpg');
      formData.append("effectId", effectId);
      
      // Process image
      const result = await fetch("http://localhost:8080/api/replicateproxy/process-image", {
        method: "POST",
        body: formData,
      });

      const data = await result.json();

      // Check for API error response
      if (!result.ok || data.status === "failed") {
        throw new Error(data.error || "Failed to process image");
      }

      // Check if we have valid output
      if (data.status === "succeeded" && Array.isArray(data.output) && data.output.length > 0) {
        setProcessedImage(data.output[0]); // Use first image from output array
      } else {
        throw new Error("No output image received");
      }

    } catch (err) {
      setError(err.message || "Failed to process image. Please try again.");
      setProcessedImage(null);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleProcessClick = () => {
    if (image) {
      processImage(image, selectedEffect);
    }
  };

  const handleEffectChange = (effectId) => {
    setSelectedEffect(effectId); 
  };

  // Slider interaction handlers
  const handleMouseDown = (e) => {
    setIsDragging(true);
    updateSliderPosition(e);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      updateSliderPosition(e);
    }
  };

  const updateSliderPosition = (clientPosition) => {
    if (sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      const x = clientPosition.clientX - rect.left;
      const percentage = (x / rect.width) * 100;
      setSliderPosition(Math.min(Math.max(percentage, 0), 100));
    }
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    updateSliderPosition(e.touches[0]);
  };

  const handleTouchMove = (e) => {
    if (isDragging) {
      updateSliderPosition(e.touches[0]);
    }
  };

  const handleKeyDown = (e) => {
    const STEP = 5;
    if (e.key === "ArrowLeft") {
      setSliderPosition((prev) => Math.max(prev - STEP, 0));
      e.preventDefault();
    } else if (e.key === "ArrowRight") {
      setSliderPosition((prev) => Math.min(prev + STEP, 100));
      e.preventDefault();
    }
  };

  const LoadingIndicator = () => (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "rgba(255, 255, 255, 0.95)",
        borderRadius: "12px",
        padding: "20px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        zIndex: 1000,
        width: "300px",
      }}
    >
      <div style={{ marginBottom: "12px", textAlign: "center" }}>
        <h3 style={{ margin: "0 0 8px 0" }}>Processing Image</h3>
        <p style={{ margin: "0", color: "#666", fontSize: "14px" }}>
          {effects.find((e) => e.id === selectedEffect)?.label}
        </p>
      </div>

      {/* Progress bar */}
      <div
        style={{
          width: "100%",
          height: "6px",
          backgroundColor: "#e9ecef",
          borderRadius: "3px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: `${progress}%`,
            height: "100%",
            backgroundColor: "#007bff",
            transition: "width 0.3s ease",
          }}
        />
      </div>

      <p
        style={{
          margin: "8px 0 0 0",
          textAlign: "center",
          fontSize: "14px",
          color: "#666",
        }}
      >
        {Math.round(progress)}% Complete
      </p>
    </div>
  );

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  useEffect(() => {
    let interval;
    if (isProcessing) {
      interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 90) return prev; // Cap at 90% until complete
          return prev + Math.random() * 10;
        });
      }, 1000);
    } else {
      setProgress(0);
    }
    return () => clearInterval(interval);
  }, [isProcessing]);

  const buttonStyle = (effectId) => ({
    padding: "8px 16px",
    margin: "0 8px 8px 0",
    border: "none",
    borderRadius: "4px",
    backgroundColor: selectedEffect === effectId ? "#007bff" : "#e9ecef",
    color: selectedEffect === effectId ? "white" : "#333",
    cursor: "pointer",
    fontSize: "14px",
    transition: "all 0.2s",
  });

  const LoadingSpinner = () => (
    <svg
      className="loading-spinner"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{
        animation: "spin 1s linear infinite",
        marginRight: "10px",
      }}
    >
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
        fill="none"
        opacity="0.25"
      />
      <path
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );

  // Error message component
  const ErrorMessage = ({ message }) => (
    <div
      style={{
        position: "fixed",
        top: "20px",
        right: "20px",
        backgroundColor: "#fee2e2",
        color: "#dc2626",
        padding: "12px 20px",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        zIndex: 1000,
        display: "flex",
        alignItems: "center",
        maxWidth: "300px",
        animation: "slideIn 0.3s ease-out",
      }}
    >
      <style>
        {`
          @keyframes slideIn {
            from { transform: translateX(100%); opacity: 0; }
            to { transform: translateX(0); opacity: 1; }
          }
        `}
      </style>
      {/* Error Icon */}
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        style={{ marginRight: "8px", flexShrink: 0 }}
      >
        <circle cx="12" cy="12" r="10" />
        <line x1="12" y1="8" x2="12" y2="12" />
        <line x1="12" y1="16" x2="12" y2="16" />
      </svg>
      <div>
        <div style={{ fontWeight: "500", marginBottom: "2px" }}>Error</div>
        <div style={{ fontSize: "14px" }}>{message}</div>
      </div>
      {/* Close button */}
      <button
        onClick={() => setError(null)}
        style={{
          background: "none",
          border: "none",
          padding: "4px",
          marginLeft: "8px",
          cursor: "pointer",
          color: "#dc2626",
        }}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </button>
    </div>
  );

  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
      {/* Show error message if exists */}
      {error && <ErrorMessage message={error} />}
      <div style={{ margin: "20px" }}>
        <div
          style={{
            border: "2px dashed #ccc",
            borderRadius: "8px",
            padding: "20px",
            textAlign: "center",
            backgroundColor: "#f8f9fa",
            cursor: "pointer",
          }}
        >
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: "none" }}
            id="imageInput"
          />
          <label htmlFor="imageInput" style={{ cursor: "pointer" }}>
            <div
              style={{
                marginBottom: "10px",
                color: "#666",
              }}
            >
              <svg
                style={{
                  width: "40px",
                  height: "40px",
                  margin: "0 auto 10px",
                  display: "block",
                  color: "#666",
                }}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                />
              </svg>
              <div style={{ fontSize: "16px", marginBottom: "5px" }}>
                Click to upload an image
              </div>
              <div style={{ fontSize: "14px", color: "#888" }}>
                JPG, PNG files are supported
              </div>
            </div>
          </label>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(140px, 1fr))",
            gap: "10px",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          {effects.map((effect) => (
            <button
              key={effect.id}
              onClick={() => handleEffectChange(effect.id)}
              disabled={isProcessing}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "12px",
                border: "2px solid",
                borderColor:
                  selectedEffect === effect.id ? "#007bff" : "#e9ecef",
                borderRadius: "8px",
                backgroundColor:
                  selectedEffect === effect.id ? "#f0f9ff" : "white",
                color: selectedEffect === effect.id ? "#007bff" : "#666",
                cursor: isProcessing ? "not-allowed" : "pointer",
                transition: "all 0.2s ease",
                opacity: isProcessing ? 0.7 : 1,
              }}
            >
              <div
                style={{
                  marginBottom: "8px",
                  color: selectedEffect === effect.id ? "#007bff" : "#666",
                }}
              >
                {effect.icon}
              </div>
              <span
                style={{
                  fontSize: "13px",
                  fontWeight: selectedEffect === effect.id ? "500" : "normal",
                  textAlign: "center",
                }}
              >
                {effect.label}
              </span>
            </button>
          ))}
        </div>
      </div>

      {isProcessing && <LoadingIndicator />}

      {/* Add Process button */}
      {image && (
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <button
              onClick={handleProcessClick}
              disabled={isProcessing}
              style={{
                backgroundColor: "#007bff",
                color: "white",
                padding: "12px 24px",
                borderRadius: "8px",
                border: "none",
                fontSize: "16px",
                fontWeight: "500",
                cursor: isProcessing ? "not-allowed" : "pointer",
                opacity: isProcessing ? 0.7 : 1,
                transition: "all 0.2s ease",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 auto",
              }}
            >
              {isProcessing ? (
                <>
                  <LoadingSpinner />
                  Processing...
                </>
              ) : (
                "Process Image"
              )}
            </button>
          </div>
        )}

      {isProcessing && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(255, 255, 255, 0.95)",
            boxShadow:
              "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
            borderRadius: "12px",
            padding: "20px 40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
            backdropFilter: "blur(5px)",
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <LoadingSpinner />
          <div style={{ marginLeft: "12px" }}>
            <div
              style={{
                fontWeight: "500",
                marginBottom: "4px",
                color: "#333",
              }}
            >
              Processing Image
            </div>
            <div
              style={{
                fontSize: "14px",
                color: "#666",
              }}
            >
              Applying {effects.find((e) => e.id === selectedEffect)?.label}...
            </div>
          </div>
        </div>
      )}

      {isProcessing && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            zIndex: 999,
          }}
        />
      )}

      {image && !isProcessing && (
        <div
          ref={sliderRef}
          style={{
            position: "relative",
            overflow: "hidden",
            border: "1px solid #ccc",
            borderRadius: "8px",
            cursor: "col-resize",
            touchAction: "none", // Prevent scrolling while dragging on mobile
            WebkitUserSelect: "none",
            userSelect: "none",
          }}
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleMouseUp}
          onKeyDown={handleKeyDown}
          tabIndex="0" // Make it focusable for keyboard control
          role="slider"
          aria-valuenow={sliderPosition}
          aria-valuemin={0}
          aria-valuemax={100}
          aria-label="Image comparison slider"
        >
          {/* Original Image */}
          <img
            src={image}
            alt="Original"
            style={{
              width: "100%",
              height: "auto",
              display: "block",
            }}
          />

          {/* Processed Image */}
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              width: `${sliderPosition}%`,
              overflow: "hidden",
              transition: isDragging ? "none" : "width 0.1s ease-out",
            }}
          >
            <img
              src={processedImage}
              alt="Processed"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                filter: effects.find((e) => e.id === selectedEffect)?.filter,
              }}
            />
          </div>

          {/* Slider Handle */}
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: `${sliderPosition}%`,
              width: "4px",
              backgroundColor: "white",
              transform: "translateX(-50%)",
              cursor: "col-resize",
              transition: isDragging ? "none" : "left 0.1s ease-out",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "40px",
                height: "40px",
                backgroundColor: "white",
                borderRadius: "50%",
                boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                transition: "transform 0.2s ease",
                transform: isDragging
                  ? "translate(-50%, -50%) scale(1.1)"
                  : "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  width: "2px",
                  height: "20px",
                  backgroundColor: "#666",
                  borderRadius: "1px",
                }}
              />
            </div>
          </div>

          {/* Labels with enhanced visibility */}
          <div
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
              background: "rgba(0,0,0,0.75)",
              color: "white",
              padding: "6px 12px",
              borderRadius: "20px",
              fontSize: "14px",
              fontWeight: "500",
              boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
            }}
          >
            Original
          </div>
          <div
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "rgba(0,0,0,0.75)",
              color: "white",
              padding: "6px 12px",
              borderRadius: "20px",
              fontSize: "14px",
              fontWeight: "500",
              boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
            }}
          >
            Enhanced
          </div>
        </div>
      )}
    </div>
  );
};

export default StageDemo;
