import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { useMatchMedia } from "primereact/hooks";

const Projections = ({ projections = {} }) => {
  const [chartData, setChartData] = useState([]);
  const isMobile = useMatchMedia("(max-width: 768px)");

  useEffect(() => {
    // Debug log
    console.log("Raw projections data:", projections?.yearlyData);

    if (projections?.yearlyData?.length > 0) {
      setChartData(projections.yearlyData);
    }
  }, [projections]);

  // Custom chart height based on screen size
  const chartHeight = isMobile ? 300 : 400;

  // Responsive font sizes
  const fontSize = isMobile ? 10 : 12;

  return (
    <div className="surface-card p-4 border-round shadow-1">
      <div className="flex justify-content-between align-items-center mb-4">
        <h2 className="text-xl m-0 font-medium">30-Year Projections</h2>
      </div>

      {/* Chart Section */}
      <div className="w-full mb-4">
        <div className="h-64">
          <div
            style={{ width: "100%", height: chartHeight, minHeight: "250px" }}
          >
            {chartData.length > 0 ? (
              <ResponsiveContainer>
                <LineChart
                  data={chartData}
                  margin={
                    isMobile
                      ? { top: 5, right: 20, left: 0, bottom: 5 }
                      : { top: 10, right: 30, left: 10, bottom: 5 }
                  }
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="year"
                    type="number"
                    domain={[1, 30]}
                    tick={{ fontSize }}
                    interval={isMobile ? 4 : 2}
                  />
                  <YAxis
                    yAxisId="property"
                    orientation="left"
                    tickFormatter={(value) => `$${(value / 1000).toFixed(0)}k`}
                    tick={{ fontSize }}
                    width={isMobile ? 45 : 60}
                  />
                  <YAxis
                    yAxisId="rent"
                    orientation="right"
                    tickFormatter={(value) => `$${(value / 1000).toFixed(0)}k`}
                    tick={{ fontSize }}
                    width={isMobile ? 45 : 60}
                  />
                  <Tooltip
                    formatter={(value) => [`$${value.toLocaleString()}`]}
                    labelFormatter={(label) => `Year ${label}`}
                    contentStyle={{ fontSize: fontSize + 2 }}
                  />
                  <Legend
                    wrapperStyle={{ fontSize }}
                    verticalAlign={isMobile ? "bottom" : "top"}
                    height={36}
                  />
                  <Line
                    yAxisId="property"
                    type="monotone"
                    dataKey="propertyValue"
                    stroke="#4338ca"
                    name="Property Value"
                    strokeWidth={isMobile ? 1.5 : 2}
                    dot={false}
                  />
                  <Line
                    yAxisId="rent"
                    type="monotone"
                    dataKey="yearlyRent"
                    stroke="#16a34a"
                    name="Yearly Rent"
                    strokeWidth={isMobile ? 1.5 : 2}
                    dot={false}
                  />
                  <Line
                    yAxisId="rent"
                    type="monotone"
                    dataKey="yearlyCashFlow"
                    stroke="#dc2626"
                    name="Cash Flow"
                    strokeWidth={isMobile ? 1.5 : 2}
                    dot={false}
                    strokeDasharray="5 5"
                  />
                </LineChart>
              </ResponsiveContainer>
            ) : (
              <div className="flex align-items-center justify-content-center h-full">
                <span className="text-500">Loading chart data...</span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Metrics Grid */}
      <div className="grid">
        <div className="col-12 md:col-4 p-2">
          <div className="surface-100 p-3 border-round">
            <span className="text-600 text-sm block mb-2">
              Total Appreciation
            </span>
            <span className="text-900 text-xl">
              ${Math.round(projections.totalAppreciation || 0).toLocaleString()}
            </span>
          </div>
        </div>

        <div className="col-12 md:col-4 p-2">
          <div className="surface-100 p-3 border-round">
            <span className="text-600 text-sm block mb-2">Total Cash Flow</span>
            <span className="text-900 text-xl">
              ${Math.round(projections.totalCashFlow || 0).toLocaleString()}
            </span>
          </div>
        </div>

        <div className="col-12 md:col-4 p-2">
          <div className="surface-100 p-3 border-round">
            <span className="text-600 text-sm block mb-2">
              Average Annual Return
            </span>
            <span className="text-900 text-xl">
              ${Math.round(projections.averageReturn || 0).toLocaleString()}
            </span>
          </div>
        </div>
      </div>

      {/* Disclaimer */}
      <div className="text-center mt-2">
        <span className="text-500 text-xs">
          * Projections based on historical market data and current property
          metrics
        </span>
      </div>
    </div>
  );
};

export default Projections;
