import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { ProgressBar } from "primereact/progressbar";
import { Tag } from "primereact/tag";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";

const PropertyTaxesTable = () => {
  const [propertyTaxes, setPropertyTaxes] = useState([]);
  const [searchState, setSearchState] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(20);
  const [first, setFirst] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState("");

  useEffect(() => {
    fetchPropertyTaxes();
  }, [first, rows, sortField, sortOrder]);

  const fetchPropertyTaxes = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://ipmc-api.onrender.com/api/lookup/property-taxes-state`
      );
      const data = await response.json();
      setPropertyTaxes(data);
      setTotalRecords(data.length);
      setLoading(false);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch data",
        life: 3000,
      });
      setLoading(false);
    }
  };

  const [summaryStats, setSummaryStats] = useState({
    averageRate: 0,
    highestRate: { state: "", rate: 0 },
    lowestRate: { state: "", rate: 100 },
  });

  const header = (
    <div className="flex justify-between items-center">
      <h3 className="m-0">Property Taxes by State</h3>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search all fields..."
          className="p-2"
        />
      </span>
    </div>
  );

  const taxRateTemplate = (rowData) => {
    const rate = Number(rowData.Property_Tax_Rate.replace("%", ""));
    const nationalAvg = summaryStats.averageRate;

    let severity = "success";
    if (rate > 2.0) severity = "danger";
    else if (rate > 1.8) severity = "warning";
    else if (rate > 1.5) severity = "info";

    return (
      <div className="flex align-items-center gap-2">
        <Tag
          severity={severity}
          value={`${rate}%`}
          className="w-16 text-center"
        />
        {rate > nationalAvg ? (
          <i
            className="pi pi-arrow-up text-red-500"
            style={{ fontSize: "0.8rem" }}
          />
        ) : (
          <i
            className="pi pi-arrow-down text-green-500"
            style={{ fontSize: "0.8rem" }}
          />
        )}
      </div>
    );
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const costCalculatorTemplate = (rowData) => {
    const homeValue = Number(rowData.Average_Home_Value.replace(/[$,]/g, ""));
    const rate = Number(rowData.Property_Tax_Rate.replace("%", ""));

    const calculations = {
      downPayment: homeValue * 0.2,
      monthlyMortgage: (homeValue * 0.8 * 0.07) / 12, // 7% rate
      monthlyTax: (homeValue * rate) / 100 / 12,
      insurance: (homeValue * 0.005) / 12,
      pmi: homeValue * 0.8 > 647200 ? (homeValue * 0.001) / 12 : 0,
    };

    return (
      <div className="flex gap-2">
        <i
          className="pi pi-calculator text-green-500 cursor-pointer"
          data-pr-tooltip={`
            Monthly Cost Breakdown:
            • Mortgage (20% down): ${formatCurrency(
              calculations.monthlyMortgage
            )}
            • Property Tax: ${formatCurrency(calculations.monthlyTax)}
            • Insurance (est.): ${formatCurrency(calculations.insurance)}
            • PMI (if applicable): ${formatCurrency(calculations.pmi)}
            • Total Monthly: ${formatCurrency(
              calculations.monthlyMortgage +
                calculations.monthlyTax +
                calculations.insurance +
                calculations.pmi
            )}
          `}
        />
        <Tooltip target=".pi-calculator" position="left" />
      </div>
    );
  };

  const footer = (
    <div className="text-sm text-gray-600">
      Total States: {propertyTaxes.length} • Average Tax Rate:{" "}
      {summaryStats.averageRate.toFixed(2)}%
    </div>
  );

  const navigate = useNavigate();

  const navigateToDetails = (State) => {
    navigate(`/leads?state=${State}`);
  };

  const stateBodyTemplate = (rowData) => {
    return (
      <a
        href={`/leads?state=${rowData.StateAbb}`}
        onClick={(e) => {
          e.preventDefault();
          navigateToDetails(rowData.StateAbb);
        }}
        className="text-blue-600 hover:text-blue-800 hover:underline"
      >
        {rowData.State}
      </a>
    );
  };

  const homeValueTemplate = (rowData) => {
    const homeValue = Number(rowData.Average_Home_Value.replace(/[$,]/g, ""));
    const rate = Number(rowData.Property_Tax_Rate.replace("%", "")) / 100;
    const taxPer1000 = 1000 * rate;

    return (
      <div>
        <div className="font-medium">
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
          }).format(homeValue)}
        </div>
        <div className="text-sm text-gray-500">
          ${taxPer1000.toFixed(2)} per $1,000
        </div>
      </div>
    );
  };

  // Calculate in useEffect after data load
  useEffect(() => {
    if (propertyTaxes.length) {
      const avg =
        propertyTaxes.reduce(
          (acc, curr) => acc + Number(curr.Property_Tax_Rate.replace("%", "")),
          0
        ) / propertyTaxes.length;

      const highest = propertyTaxes.reduce((max, curr) =>
        Number(curr.Property_Tax_Rate.replace("%", "")) >
        Number(max.Property_Tax_Rate.replace("%", ""))
          ? curr
          : max
      );

      const lowest = propertyTaxes.reduce((min, curr) =>
        Number(curr.Property_Tax_Rate.replace("%", "")) <
        Number(min.Property_Tax_Rate.replace("%", ""))
          ? curr
          : min
      );

      setSummaryStats({
        averageRate: avg,
        highestRate: { state: highest.State, rate: highest.Property_Tax_Rate },
        lowestRate: { state: lowest.State, rate: lowest.Property_Tax_Rate },
      });
    }
  }, [propertyTaxes]);

  const exportSelectedStates = () => {
    const csvContent = propertyTaxes
      .map(
        (row) =>
          `${row.State},${row.Property_Tax_Rate},${row.Average_Home_Value}`
      )
      .join("\n");
    const blob = new Blob(
      [`State,Tax Rate,Average Home Value\n${csvContent}`],
      { type: "text/csv;charset=utf-8;" }
    );
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "property_taxes.csv";
    link.click();
  };

  const propertyTaxTemplate = (rowData) => {
    const homeValue = Number(rowData.Average_Home_Value.replace(/[$,]/g, ""));
    const rate = Number(rowData.Property_Tax_Rate.replace("%", "")) / 100;
    const annualTax = homeValue * rate;

    return (
      <div className="flex align-items-center">
        <span className="text-sm text-gray-500 mr-2">Avg. Annual Tax:</span>
        <span className="font-semibold">
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
          }).format(annualTax)}
        </span>
      </div>
    );
  };

  const annualTaxTemplate = (rowData) => {
    const homeValue = Number(rowData.Average_Home_Value.replace(/[$,]/g, ""));
    const rate = Number(rowData.Property_Tax_Rate.replace("%", "")) / 100;
    const annualTax = homeValue * rate;
    const monthlyTax = annualTax / 12;

    // Fun comparison calculations
    const coffeeEquivalent = Math.round(annualTax / 5); // $5 coffee
    const gasEquivalent = Math.round(annualTax / 4); // $4/gallon gas
    const netflixYears = Math.round(annualTax / (15 * 12)); // $15/month Netflix

    return (
      <div className="relative">
        <div className="flex align-items-center">
          <span className="text-gray-500">Avg. Annual Tax:</span>
          <span className="ml-2 font-medium">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0,
            }).format(annualTax)}
          </span>
          <i
            className="pi pi-info-circle ml-2 cursor-pointer text-blue-500"
            data-pr-tooltip={`This amount equals:
            • ${coffeeEquivalent} cups of coffee
            • ${gasEquivalent} gallons of gas
            • ${netflixYears} years of Netflix`}
          />
        </div>
        <div className="text-sm text-gray-500">
          Monthly:{" "}
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
          }).format(monthlyTax)}
        </div>
      </div>
    );
  };

  // Add Tooltip component to your return
  <Tooltip target=".pi-info-circle" />;
  <Tooltip target=".pi-calculator" />;

  const savingsComparisonTemplate = (rowData) => {
    // Get highest tax state (NJ) as baseline
    const baselineHomeValue = Number(
      rowData.Average_Home_Value.replace(/[$,]/g, "")
    );
    const baselineRate = 2.49; // NJ rate
    const currentRate = Number(rowData.Property_Tax_Rate.replace("%", ""));

    // Calculate savings
    const potentialSavings =
      (baselineHomeValue * (baselineRate - currentRate)) / 100;

    // Don't show anything for New Jersey (baseline state)
    if (rowData.State === "New Jersey") {
      return null;
    }

    // Show savings based on amount
    if (potentialSavings > 5000) {
      return (
        <i
          className="pi pi-dollar text-green-600 font-bold"
          title={`High savings potential: $${Math.round(
            potentialSavings
          ).toLocaleString()}`}
        />
      );
    } else if (potentialSavings > 2000) {
      return (
        <i
          className="pi pi-dollar text-blue-600"
          title={`Medium savings potential: $${Math.round(
            potentialSavings
          ).toLocaleString()}`}
        />
      );
    } else {
      return (
        <i
          className="pi pi-dollar text-gray-400"
          title={`Lower savings potential: $${Math.round(
            potentialSavings
          ).toLocaleString()}`}
        />
      );
    }
  };

  const investorTipsTemplate = (rowData) => {
    const INVESTOR_DETAILS = {
      "New Jersey": {
        programs: [
          "• Highest property tax but allows full tax deduction",
          "• BAIT Program for business tax savings",
          "• Strong rental market near NYC/Philadelphia",
        ],
      },
      Illinois: {
        programs: [
          "• Property Tax Credit on state income tax",
          "• Chicago opportunity zones tax benefits",
          "• High depreciation potential in urban areas",
        ],
      },
      Texas: {
        programs: [
          "• No state income tax on rental income",
          "• Homestead benefits for owner conversion",
          "• Fast depreciation in growing markets",
        ],
      },
      Florida: {
        programs: [
          "• No state income tax",
          "• High rental demand in coastal areas",
          "• Save Our Homes assessment benefits",
        ],
      },
      California: {
        programs: [
          "• Prop 13 protection limits tax increases",
          "• Solar tax incentives for rentals",
          "• High appreciation potential",
        ],
      },
      "New York": {
        programs: [
          "• STAR program benefits",
          "• Multiple dwelling tax incentives",
          "• Strong urban rental markets",
        ],
      },
      Massachusetts: {
        programs: [
          "• Circuit breaker tax credit",
          "• Historic rental property credits",
          "• Energy efficiency deductions",
        ],
      },
      Pennsylvania: {
        programs: [
          "• Clean and Green program",
          "• Improvement tax abatements",
          "• Local tax incentive zones",
        ],
      },
      // Default template for other states
      DEFAULT: {
        programs: [
          "• Standard federal rental deductions apply",
          "• Property tax fully deductible for rentals",
          "• Check local investor incentives",
        ],
      },
    };

    const disclaimer = `
      DISCLAIMER: Tax benefits vary by location and individual circumstances. 
      Please verify current programs and eligibility at your state's tax authority website. 
      Consult with a qualified tax professional for specific advice.
      This information is for general guidance only.
    `;

    const getStateTips = (state) => {
      const tips = INVESTOR_DETAILS[state] || INVESTOR_DETAILS["DEFAULT"];
      return {
        ...tips,
        programs: [
          ...tips.programs,
          "\nStandard Federal Deductions:",
          "• Property taxes",
          "• Mortgage interest",
          "• Depreciation",
          "• Repairs & maintenance",
          "• Insurance",
          "\n" + disclaimer,
        ],
      };
    };

    const tips = getStateTips(rowData.State);

    return (
      <div className="flex align-items-center">
        <i
          className="pi pi-home mr-2 cursor-pointer text-blue-500"
          data-pr-tooltip={tips.programs.join("\n")}
        />
        <Tooltip target=".pi-home" position="left" />
      </div>
    );
  };

  return (
    <div className="datatable-filter-demo">
      <div className="card p-3">
        <div className="flex align-items-center justify-content-between mb-3">
          <h3>Property Taxes by State</h3>
          <Button
            icon="pi pi-download"
            label="Export CSV"
            className="p-button-outlined"
            onClick={exportSelectedStates}
          />
        </div>

        <Toast ref={toast} />

        {/* Stats Cards - Using PrimeReact's flex utilities */}
        <div className="flex flex-row align-items-stretch justify-content-between mb-3">
          <Card className="flex-1 mr-2 text-center bg-blue-50">
            <h5>Average Tax Rate</h5>
            <div className="text-2xl font-bold text-primary">
              {summaryStats.averageRate.toFixed(2)}%
            </div>
          </Card>

          <Card className="flex-1 mx-2 text-center bg-red-50">
            <h5>Highest: {summaryStats.highestRate.state}</h5>
            <div className="text-2xl font-bold text-danger">
              {summaryStats.highestRate.rate}
            </div>
          </Card>

          <Card className="flex-1 ml-2 text-center bg-green-50">
            <h5>Lowest: {summaryStats.lowestRate.state}</h5>
            <div className="text-2xl font-bold text-success">
              {summaryStats.lowestRate.rate}
            </div>
          </Card>
        </div>

        <DataTable
          value={propertyTaxes}
          sortMode="multiple"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          footer={footer}
          showGridlines
          stripedRows
          size="small"
          className="shadow-sm"
        >
          <Column
            field="State"
            header="State"
            body={stateBodyTemplate}
          ></Column>
          <Column
            field="Average_Home_Value"
            header="Average Home Value"
            body={homeValueTemplate}
            sortable
          ></Column>

          <Column
            field="Property_Tax_Rate"
            header={() => (
              <div className="flex align-items-center">
                Tax Rate (%)
                <i
                  className="pi pi-info-circle ml-2 text-blue-500 cursor-pointer"
                  data-pr-tooltip="Property tax rates shown as percentage of assessed value. Colors indicate: Red (>2%), Yellow (1.8-2%), Blue (1.5-1.8%), Green (<1.5%)"
                  data-pr-position="top"
                />
              </div>
            )}
            body={taxRateTemplate}
            sortable
          />
          <Column
            field="annual_tax"
            header="Est. Annual Tax"
            body={annualTaxTemplate}
            sortable
          />
          <Column
            header="Investment Tools"
            body={(rowData) => (
              <div className="flex gap-2">
                {investorTipsTemplate(rowData)}
                {costCalculatorTemplate(rowData)}
              </div>
            )}
          />
        </DataTable>
      </div>
      <Tooltip target=".pi-info-circle" />
    </div>
  );
};

export default PropertyTaxesTable;
