import React, { useState } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const LenderPDFReport = ({ propertyDetails, calculations, financialParams, projections, demographicsData }) => {
    const [isGenerating, setIsGenerating] = useState(false);

  const generatePDF = () => {
    setIsGenerating(true);
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    
    // Helper function for centered text
    const centerText = (text, y) => {
      const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const x = (pageWidth - textWidth) / 2;
      doc.text(text, x, y);
    };

    // Title
    doc.setFontSize(20);
    centerText('Investment Property Analysis Report', 20);

    // Property Details
    doc.setFontSize(12);
    doc.text(`Property Address: ${propertyDetails.address}`, 20, 30);
    doc.text(`Report Date: ${new Date().toLocaleDateString()}`, 20, 35);

    // Executive Summary
    doc.setFontSize(16);
    doc.text('Executive Summary', 20, 45);
    doc.setFontSize(10);
    doc.text([
      `Purchase Price: $${propertyDetails.purchasePrice.toLocaleString()}`,
      `Monthly Rent: $${propertyDetails.monthlyRent.toLocaleString()}`,
      `Property Details: ${propertyDetails.bedsBaths} • ${propertyDetails.squareFeet} sqft`,
      `Year Built: ${propertyDetails.yearBuilt}`
    ], 25, 55);

    // Key Lending Metrics
    doc.setFontSize(16);
    doc.text('Key Lending Metrics', 20, 80);
    doc.setFontSize(10);

    // DSCR Section
    const dscrData = [
      ['DSCR', calculations.dscr.toFixed(2)],
      ['Annual NOI', `$${calculations.noi.toLocaleString()}`],
      ['Annual Debt Service', `$${(calculations.monthlyMortgage * 12).toLocaleString()}`]
    ];

    doc.autoTable({
      head: [['Metric', 'Value']],
      body: dscrData,
      startY: 85,
      margin: { left: 25 },
      theme: 'grid'
    });

    // Loan Details
    const loanData = [
      ['Loan Amount', `$${(propertyDetails.purchasePrice * (1 - financialParams.downPayment/100)).toLocaleString()}`],
      ['Down Payment', `$${(propertyDetails.purchasePrice * financialParams.downPayment/100).toLocaleString()} (${financialParams.downPayment}%)`],
      ['Interest Rate', `${financialParams.interestRate}%`],
      ['Loan Term', `${financialParams.loanTerm} years`],
      ['Monthly Payment', `$${calculations.monthlyMortgage.toLocaleString()}`]
    ];

    doc.autoTable({
      head: [['Loan Details', 'Value']],
      body: loanData,
      startY: doc.lastAutoTable.finalY + 10,
      margin: { left: 25 },
      theme: 'grid'
    });

    // Monthly Operating Expenses
    const expenseData = [
      ['Property Tax', `$${calculations.monthlyTaxes.toLocaleString()}`],
      ['Insurance', `$${calculations.monthlyInsurance.toLocaleString()}`],
      ['Maintenance', `$${calculations.monthlyMaintenance.toLocaleString()}`],
      ['Property Management', `$${calculations.monthlyManagement.toLocaleString()}`],
      ['HOA', `$${calculations.monthlyHOA.toLocaleString()}`],
      ['Total Operating Expenses', `$${calculations.monthlyOperatingExpenses.toLocaleString()}`]
    ];

    doc.autoTable({
      head: [['Monthly Operating Expenses', 'Amount']],
      body: expenseData,
      startY: doc.lastAutoTable.finalY + 10,
      margin: { left: 25 },
      theme: 'grid'
    });

    // Cash Flow Analysis
    const cashFlowData = [
      ['Effective Gross Income', `$${(propertyDetails.monthlyRent - calculations.monthlyVacancy).toLocaleString()}`],
      ['Less Operating Expenses', `$${calculations.monthlyOperatingExpenses.toLocaleString()}`],
      ['Less Debt Service', `$${calculations.monthlyMortgage.toLocaleString()}`],
      ['Net Cash Flow', `$${calculations.monthlyCashFlow.toLocaleString()}`]
    ];

    doc.autoTable({
      head: [['Cash Flow Analysis', 'Monthly Amount']],
      body: cashFlowData,
      startY: doc.lastAutoTable.finalY + 10,
      margin: { left: 25 },
      theme: 'grid'
    });

    // Property Metrics
    const metricsData = [
      ['Cap Rate', `${calculations.capRate.toFixed(2)}%`],
      ['Price per Square Foot', `$${(propertyDetails.purchasePrice / propertyDetails.squareFeet).toFixed(2)}`],
      ['Gross Rent Multiplier', calculations.grossRentMultiplier.toFixed(2)],
      ['Expense Ratio', `${calculations.expenseRatio.toFixed(2)}%`]
    ];

    // Add new page for projections
    doc.addPage();

    // 5-Year Projections
    doc.setFontSize(16);
    doc.text('5-Year Projections', 20, 20);

    const projectionData = projections.yearlyData.slice(0, 5).map(year => [
      year.year,
      `$${year.propertyValue.toLocaleString()}`,
      `$${year.yearlyRent.toLocaleString()}`,
      `$${year.yearlyCashFlow.toLocaleString()}`,
      year.yearlyDSCR.toFixed(2)
    ]);

    doc.autoTable({
      head: [['Year', 'Property Value', 'Annual Rent', 'Cash Flow', 'DSCR']],
      body: projectionData,
      startY: 25,
      margin: { left: 20 },
      theme: 'grid'
    });

    // Save the PDF
    doc.save('Property_Analysis_Report.pdf');
  };

  return (
    <button
      onClick={generatePDF}
      className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
    >
      Lendor Report
    </button>
  );
};

export default LenderPDFReport;