import React, { useRef, useState } from "react";
import http from "../http-common";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { jwtDecode } from "jwt-decode";
import { Loader2 } from "lucide-react";
import { Toast } from "primereact/toast"; // Add this import
import { Alert, AlertDescription } from "./ui/alert";
import { AlertCircle } from "lucide-react";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errored, setErrored] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const toast = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrored(false);
    setErrorMsg("");
    try {
      const response = await http.post(
        "/user/login",
        {
          username,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data;
      // Store token in localStorage
      localStorage.setItem("token", data.token);
      // Redirect to the dashboard or home page after successful login
      const decodedToken = jwtDecode(data.token);
      const fetchUserSettings = async () => {
        try {
          const response = await http.get(`/user/${decodedToken.id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          const settings = response.data?.settings;
          localStorage.setItem("settings", JSON.stringify(settings));
          window.location.href = "/search";
        } catch (error) {
          console.error("Error fetching user settings:", error);
        }
      };
      fetchUserSettings();
    } catch (error) {      
      setErrored(true);
      setErrorMsg(error?.response?.data);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      <div className="flex align-items-center justify-content-center">
        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">
              Welcome Back
            </div>
            <span className="text-600 font-medium line-height-3">
              Don't have an account?
            </span>
            <a
              className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
              href={"/register/"}
            >
              Create Today!
            </a>
          </div>

          <div>
            <label htmlFor="email" className="block text-900 font-medium mb-2">
              Email
            </label>
            <InputText
              id="email"
              type="text"
              placeholder="Email address"
              className="w-full mb-3"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />

            <label
              htmlFor="password"
              className="block text-900 font-medium mb-2"
            >
              Password
            </label>
            <InputText
              id="password"
              type="password"
              placeholder="Password"
              className="w-full mb-3"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              label="Sign In"
              icon="pi pi-user"
              className="w-full"
              onSubmit={handleSubmit}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading && <Loader2 className="w-5 h-5 animate-spin" />}
            </Button>
          </div>
          {errored && (<div className="pt-1">
            {" "}
            {/* Added padding top for spacing */}
            <Alert
              variant="destructive"
              className="border-red-500 bg-red-50 text-red-700"
            >
              <AlertDescription className="ml-1">{errorMsg}</AlertDescription>
            </Alert>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
