import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import TutorialDataService from "../../services/tutorial.service";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { ProgressSpinner } from "primereact/progressspinner";
import FilterForm from "./filterform";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { Divider } from "primereact/divider";
import InfoTooltipComponent from "./InfoTooltipComponent";
import CashOnCashReturnCalculator from "./CashOnCashReturnCalculator";
import defaultThumbnail from "../../PhotoNotAvailable_Large.gif"; // Import the default image
import ListView from "./inventorylistview";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useRef } from "react";
import { calculateReturns } from "../../common/helpers/functions";
import moment from "moment";
import FiltersHeader from "./leads-page/filter-header";
import { filtersList, propertyTypeOptions, statusList } from "./leads-page/leads-constants";
import { formatPropertyType, formatToFloat, formatToLocaleString, generatePropertyTags, getDaysDifference, getStatusTag, parseApiDateString } from "./leads-page/utils";


export default function ExtractDTable() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const zipparam = queryParams.get("zip");
  const cityparam = queryParams.get("city");
  const stateparam = queryParams.get("state");
  const isAdmin = queryParams.get("admin");
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [city, setCity] = useState(cityparam || "");
  const [state, setState] = useState(stateparam || "");
  const [zipcode, setZipCode] = useState(zipparam || "");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [minPpsqft, setMinPpsqft] = useState("");
  const [maxPpsqft, setMaxPpsqft] = useState("");
  const [status, setStatus] = useState(1);
  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState([]);
  const [filter, setFilters] = useState();
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [rightSidebarVisible, setRightSidebarVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [rightSidebarWidth, setRightSidebarWidth] = useState();
  const [finChips, setFinChips] = useState([]);
  const [cashOnCashReturnValue, setCashOnCashReturnValue] = useState(null);
  const [roiValue, setroiValue] = useState(null);
  const [annualPreTaxCashFlowValue, setAnnualPreTaxCashFlowValue] =
    useState(null);
  const [monthlyPreTaxCashFlowValue, setMonthlyPreTaxCashFlowValue] =
    useState(null);
  const [downPaymentPercent, setDownPaymentPercent] = useState(25);
  const [loanTermYears, setLoanTermYears] = useState();
  const handleCashOnCashReturnChange = (
    cashOnCashReturn,
    annualPreTaxCashFlow,
    roi
  ) => {
    const mcashflow = annualPreTaxCashFlow / 12;
    setCashOnCashReturnValue(cashOnCashReturn);
    setAnnualPreTaxCashFlowValue(annualPreTaxCashFlow);
    setMonthlyPreTaxCashFlowValue(mcashflow.toFixed(2));
    setroiValue(roi);
  };
  const containerRef = useRef(null);

  const [propertyManagers, setPropertyManagers] = useState([]);
  const [isLoadingManagers, setIsLoadingManagers] = useState(false);
  const [previousLocation, setPreviousLocation] = useState({
    city: "",
    state: "",
  });
  const [isPmExpanded, setIsPmExpanded] = useState(false);
  const [citySuggestions, setCitySuggestions] = useState([]);
  const [isFetchingCities, setIsFetchingCities] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestionRef = useRef(null);
  const minSuggestions = [100000, 200000, 300000, 400000];
  const maxSuggestions = [100000, 200000, 300000, 400000];

  const fetchPropertyManagers = useCallback(async (city, state) => {
    if (!city) return;

    setIsLoadingManagers(true);
    try {
      const response = await fetch(
        `https://ipmc-api.onrender.com/api/lookup/property-manager/search/?city=${encodeURIComponent(
          city
        )}&state=${encodeURIComponent(state || "")}`
      );
      const data = await response.json();
      setPropertyManagers(data);
    } catch (error) {
      console.error("Error fetching property managers:", error);
      setPropertyManagers([]);
    } finally {
      setIsLoadingManagers(false);
    }
  }, []);

  useEffect(() => {
    if (selectedRowData?.city && selectedRowData?.state) {
      // Only fetch if the city or state has changed
      if (
        previousLocation.city !== selectedRowData.city ||
        previousLocation.state !== selectedRowData.state
      ) {
        fetchPropertyManagers(selectedRowData.city, selectedRowData.state);
        setPreviousLocation({
          city: selectedRowData.city,
          state: selectedRowData.state,
        });
      }
    }
  }, [selectedRowData, fetchPropertyManagers]);

  const fetchCitySuggestions = async (query) => {
    if (!query || query.length < 2) return;

    setIsFetchingCities(true);
    try {
      const response = await fetch(
        `https://secure.geonames.org/searchJSON?` + 
        `q=${encodeURIComponent(query)}` +
        `&country=US` +
        `&maxRows=10` +
        `&cities=cities1000` +
        `&featureClass=P` +
        `&username=geolocwithgeonames`
      );
      const data = await response.json();

      const suggestions = data.geonames.map((place) => ({
        city: place.name,
        state: place.adminCode1,
        display: `${place.name}, ${place.adminCode1}`,
      }));

      setCitySuggestions(suggestions);
      setZipCode('');
      setShowSuggestions(true);
    } catch (error) {
      console.error("Error fetching city suggestions:", error);
    } finally {
      setIsFetchingCities(false);
    }
  };

  const debouncedFetch = useRef(_.debounce(fetchCitySuggestions, 300)).current;

  const handleCityChange = (e) => {
    setCity(e.target.value);
    debouncedFetch(e.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setCity(suggestion.city);
    setState(suggestion.state);
    setShowSuggestions(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionRef.current &&
        !suggestionRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      debouncedFetch.cancel();
    };
  }, [debouncedFetch]);

  const handleResize = () => {
    // Check viewport width and set sidebar width accordingly
    if (window.innerWidth <= 600) {
      setRightSidebarWidth("100vw");
    } else {
      setRightSidebarWidth("60vw");
    }
  };

  const handlePropertyTypeChange = (e) => {
    setSelectedPropertyTypes(e.value); // Update property types array directly
  };

 

  const fetchLocation = async () => {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      setZipCode(response.data.postal);
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  const fetchData = () => {
    setIsLoading(true);
    setProducts([]);

    const parsedAddress = {};
    parsedAddress.city = city;
    parsedAddress.state = state;
    parsedAddress.zipcode = zipcode;
    parsedAddress.listingStatus = status;
    parsedAddress.listingFilters = filter;
    parsedAddress.minPpsqft = minPpsqft;
    parsedAddress.maxPpsqft = maxPpsqft;
    parsedAddress.minPrice = minPrice;
    parsedAddress.maxPrice = maxPrice;
    parsedAddress.propertyTypes = selectedPropertyTypes;
    TutorialDataService.makeExtractsRequest(parsedAddress)
      .then((data) => {
        data = data.data;
        // Check if data is an array, if not, convert it to an array
        if (Array.isArray(data)) {
          // Combine streetNumber, street, city, state, and zipCode into one field
          const combinedData = data.map((item) => ({
            ...item,
            fullAddress: `${item.streetNumber} ${item.street}, ${item.city}, ${item.state} ${item.zipCode}`,
            property_type: formatPropertyType(item.property_type),
            daysonmarket: getDaysDifference(
              parseApiDateString(item.createddate),
              parseApiDateString(item.updateddate)
            ),
          }));
          setProducts(combinedData);
          setIsLoading(false); // Data fetched, loading done
        } else if (typeof data === "object") {
          // If data is an object, convert it to an array with a single element
          const combinedData = {
            ...data,
            fullAddress: `${data.streetNumber} ${data.street}, ${data.city}, ${data.state} ${data.zipCode}`,
          };
          setProducts([combinedData]);
          setIsLoading(false); // Data fetched, loading done
        } else {
          // Handle other data types or unexpected responses
          console.error("Unexpected data format:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const savedSettings = JSON.parse(localStorage.getItem("settings"));
    setDownPaymentPercent(savedSettings?.downpayment ?? 25);
    setLoanTermYears(savedSettings?.loanterm ?? 30);
    fetchLocation();
    fetchData();
  }, []);

  const customPriceTemplate = (selectedprice) => {
    // Safeguarding against null, undefined, or non-string values
    if (!selectedprice || typeof selectedprice !== "string") {
      return ""; // Return a placeholder or empty string in case of bad data
    }

    try {
      // Remove commas from the price string
      const priceString = selectedprice.replace(/,/g, "");

      // Convert the cleaned-up string to a number
      const price = parseFloat(priceString);

      if (!isNaN(price)) {
        // Format price with commas for thousands separator, no decimal places for whole numbers
        return price.toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      } else {
        console.error("Parsed NaN from price data:", selectedprice);
        return ""; // Return empty string or placeholder if price is not valid
      }
    } catch (error) {
      // Log the error silently
      console.error("Error formatting price:", error);
      return ""; // Return empty string or a predefined error placeholder
    }
  };

  const toggleRightSidebar = () => {
    setRightSidebarVisible(!rightSidebarVisible);
  };

  const showDetailsInSidebar = (rowData) => {
    setSelectedRowData(rowData);
    calculateFin(rowData);
    setCurrentIndex(products.indexOf(rowData));
    toggleRightSidebar();
  };

  

  

  const navigateRows = (direction) => {
    const newIndex = currentIndex + direction;
    if (newIndex >= 0 && newIndex < products.length) {
      const newproduct = products[newIndex];
      setSelectedRowData(newproduct);
      calculateFin(newproduct);
      setCurrentIndex(newIndex);

      if (containerRef.current) {
        containerRef.current.scrollTo({
          top: 0, // Scroll to the top of the container
          behavior: "smooth", // Smooth scrolling effect
        });
      }
    }
  };

  const calculateFin = (selectedRowData) => {
    const monthlyRent =
      selectedRowData.rentalestimate !== null &&
      selectedRowData.rentalestimate !== ""
        ? selectedRowData.rentalestimate
        : selectedRowData.fmr !== null && selectedRowData.fmr !== ""
        ? selectedRowData.fmr
        : 0;

    const opexraw =
      Math.round(monthlyRent * 0.1 * 12) - Math.round(monthlyRent * 0.06 * 12);
    const griraw = Math.round(monthlyRent * 12);
    const gri = formatToLocaleString(Math.round(monthlyRent * 12));
    const noiraw = griraw - opexraw;
    const chips = [
      {
        label: "Annual Revenue",
        value: "$" + formatToLocaleString(Math.round(monthlyRent) * 12),
        hint: "Expected yearly rental income based on market rates or Fair Market Rent (FMR)",
      },
      {
        label: "Cash-on-Cash",
        value: cashOnCashReturnValue + "%",
        hint: "COC",
      },
      {
        label: "NOI",
        value: formatToLocaleString(noiraw) + " $",
        hint: "Annual income minus operating expenses before mortgage payments and taxes. Key metric for property value.",
      },
      {
        label: "Annual Vacancy",
        value: formatToLocaleString(Math.round(monthlyRent * 0.06 * 12) + " $"),
        hint: "Estimated yearly income loss from vacant periods based on local vacancy rate",
      },
      {
        label: "Annual Maintanance",
        value: formatToLocaleString(Math.round(monthlyRent * 0.1 * 12) + " $"),
        hint: "",
      },
      { label: "GRI", value: gri + " $", hint: "Gross rental Income" },
    ];
    setFinChips(chips);
  };

  const firstChip = finChips.slice(0, 1);

  // Take the first two elements

  // Skip the first two elements
  const remainingChips = finChips.slice(2);

    

  const calcCaprate = (product) => {
    const monthlyRent =
      product.rentalestimate !== null && product.rentalestimate !== ""
        ? product.rentalestimate
        : product.fmr !== null && product.fmr !== ""
        ? product.fmr
        : 0;

    const savedSettings = JSON.parse(localStorage.getItem("settings"));
    const results = calculateReturns({
      purchasePrice: product.price,
      downPaymentPercent: savedSettings?.downpayment ?? 25,
      interestRate: product.latestMortgageRates.pmms30,
      loanTermYears: savedSettings?.loanterm ?? 30,
      monthlyRent: monthlyRent,
      vacancyRatePercent: product.rental_vacancy_rate ?? 6,
      maintenancePercent: 10,
      operatingExpensePercent: 10,
      appreciationRate: 3,
      holdingPeriodYears: 5,
      sellingCostsPercent: 6,
      hoafee: product.hoa,
      propertyTaxRate: product.propertyTaxes?.Property_Tax_Rate || "0%",
      insuranceRatePercent: 0.005,
    });
    return {
      annualPreTaxCashFlow: results.annualPreTaxCashFlow,
      cashOnCashReturn: results.cashOnCashReturn,
      ROI: results.ROI,
      capRate: results.capRate,
      DSCR: results.DSCR,
    };
  };

  const cleanAddress = (address) => {
    if (!address) return "";
    return address.replace("US Get Directions", "").trim();
  };

  const AddressGoogleLink = ({ address, children }) => {
    const createGoogleSearchUrl = (address) => {
      const searchQuery = encodeURIComponent(address);
      return `https://www.google.com/search?q=${searchQuery}`;
    };

    return (
      <a
        href={createGoogleSearchUrl(address)}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 hover:text-blue-600 pl-2"
      >
        {children}
      </a>
    );
  };

  // Function to render details in a formatted list
  const renderDetails = () => (
    <div>
      <div className="">
        <div className="flex-wrap items-center text-base font-bold bg-white md:flex md:py-1 md:text-lg mb-1 sticky-image">
          <div className="pl-1 m-1 ">
            <span className="status-label">
              {getStatusTag(selectedRowData.status)}
            </span>

            <img
              src={selectedRowData.thumbnail || defaultThumbnail}
              alt="Thumbnail"
              className="thumbnail-img"
              onError={(e) => {
                e.target.onerror = null; // Prevent infinite loop in case the default image also fails
                e.target.src = defaultThumbnail;
              }}
            />
            <Button
              icon="pi pi-times"
              className="close-button mr-3"
              onClick={toggleRightSidebar}
            />
          </div>
        </div>

        <ul className="list-none p-0 m-0 flex-wrap">
          <li className="md:flex-row md:align-items-center md:justify-content-between mb-2 ml-2">
            <div>
              <div className="mt-1 flex-col text-base">
                <div className="text-2xl font-bold mb-2">
                  ${customPriceTemplate(selectedRowData.price)}
                </div>
                <div className="text-lg font-medium">
                  {selectedRowData.fullAddress}
                  <AddressGoogleLink
                    address={`${selectedRowData.streetNumber} ${selectedRowData.street} ${selectedRowData.city} ${selectedRowData.state} ${selectedRowData.zipCode}`}
                  >
                    <i className="pi pi-external-link"></i>
                  </AddressGoogleLink>
                </div>
                <div className="text-gray-600 mt-1">
                  {selectedRowData.bedrooms} beds • {selectedRowData.bathrooms}{" "}
                  baths • {selectedRowData.squareFeet} sqft •{" "}
                  {selectedRowData.year_built} built
                </div>
              </div>
            </div>
          </li>
          <li className="md:flex-row md:align-items-center md:justify-content-between mb-2">
            <div className="mt-1 text-lg md:mt-1">
              <div className="flex-container mt-1">
                {selectedRowData.price && (
                  <div className="flex-col p-3rem chip items-center bg-gray-100 border border-gray-400 rounded-md md:text-sm">
                    <p className="mb-0">
                      $
                      {formatToLocaleString(
                        formatToFloat(selectedRowData.price) *
                          (downPaymentPercent / 100)
                      )}{" "}
                      Initial Capital
                    </p>
                  </div>
                )}
                {selectedRowData.ppsqft && (
                  <div className="flex-col p-3rem chip items-center bg-gray-100 border border-gray-400 rounded-md md:text-sm">
                    <p className="mb-0">${selectedRowData.ppsqft}/sqft</p>
                  </div>
                )}
                {selectedRowData.property_type && (
                  <div className="flex-col p-3rem chip items-center bg-gray-100 border border-gray-400 rounded-md md:text-sm">
                    <p className="mb-0">
                      {formatPropertyType(selectedRowData.property_type)}
                    </p>
                  </div>
                )}
                {selectedRowData.year_built && (
                  <div className="flex-col p-3rem chip items-center bg-gray-100 border border-gray-400 rounded-md md:text-sm">
                    <p className="mb-0">
                      {" "}
                      Built in {selectedRowData.year_built}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-2 space-x-1.5">
              {generatePropertyTags(selectedRowData, calcCaprate,  monthlyPreTaxCashFlowValue).map((tag, i) => (
                <span
                  key={i}
                  className={`inline-block px-2 py-0.5 font-medium mr-2 rounded m-1 ${tag.color}`}
                >
                  {tag.text}
                </span>
              ))}
            </div>
          </li>
          {(selectedRowData.rentalestimate || selectedRowData.fmr) && (
            <li className="md:align-items-center md:justify-content-between mb-2 ml-2">
              <Divider />
              <div>
                <div>
                  <span className="mr-2 mb-1 md:mb-0 text-xl font-bold md:text-2xl">
                    Projected returns
                  </span>
                </div>
                <div className="flex-row sticky top-0 bg-white z-10 pt-4">
                  <div className="flex-container mt-1">
                    {firstChip.map((item) => (
                      <div className="flex-col chip px-4 py-3rem bg-white border border-gray-400 rounded-md md:px-6 md:py-4 pl-1 ml-0">
                        <p className="text-base font-bold md:text-lg mb-1">
                          <InfoTooltipComponent description={item.hint} />{" "}
                          {item.label}
                        </p>
                        <p
                          className={`text-xl font-bold truncate md:text-2xl mb-0 ${
                            parseFloat(item.value) < 0
                              ? "text-red-500"
                              : "text-green-600"
                          }`}
                        >
                          {item.value}
                        </p>
                      </div>
                    ))}
                    <div className="flex-col chip px-4 py-3rem bg-white border border-gray-400 rounded-md md:px-6 md:py-4 pl-1 ml-0">
                      <p className="text-base font-bold md:text-lg mb-1">
                        <InfoTooltipComponent
                          description={
                            "Pre-tax cash flow divided by total cash invested. Shows first-year return on your down payment."
                          }
                        />{" "}
                        Cash-on-Cash
                      </p>
                      <p
                        className={`text-xl font-bold truncate md:text-2xl mb-0 ${
                          parseFloat(cashOnCashReturnValue) < 0
                            ? "text-red-500"
                            : "text-green-600"
                        }`}
                      >
                        {cashOnCashReturnValue} %
                      </p>
                    </div>
                    <div className="flex-col chip px-4 py-3rem bg-white border border-gray-400 rounded-md md:px-6 md:py-4 pl-1 ml-0">
                      <p className="text-base font-bold md:text-lg mb-1">
                        <InfoTooltipComponent description="Debt Service Coverage Ratio - Net rent vs mortgage payment (>1.25 is good)" />{" "}
                        DSCR
                      </p>
                      <p
                        className="text-xl font-bold truncate md:text-2xl mb-0"
                        style={{
                          color:
                            calcCaprate(selectedRowData).DSCR > 1.25
                              ? "green"
                              : calcCaprate(selectedRowData).DSCR < 1
                              ? "red"
                              : undefined,
                        }}
                      >
                        {calcCaprate(selectedRowData).DSCR}
                      </p>
                    </div>
                    <div className="flex-col chip px-4 py-3rem bg-white border border-gray-400 rounded-md md:px-6 md:py-4 pl-1 ml-0">
                      <p className="text-base font-bold md:text-lg mb-1">
                        <InfoTooltipComponent description="Net Operating Income / Property Price (higher is better)" />{" "}
                        Cap Rate
                      </p>
                      <p className="text-xl font-bold truncate md:text-2xl mb-0">
                        {calcCaprate(selectedRowData).capRate}%
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex-row">
                  <div className="flex-container mt-1">
                    <div className="chip mt-1 flex-col bg-gray-100 border border-gray-400 rounded-md pl-1 ml-0">
                      <InfoTooltipComponent description="Monthly income after all expenses including mortgage, taxes, insurance, and maintenance" />{" "}
                      Cash Flow:{" "}
                      <span
                        className={
                          parseFloat(monthlyPreTaxCashFlowValue) < 0
                            ? "text-red-500"
                            : "text-green-600"
                        }
                      >
                        {monthlyPreTaxCashFlowValue} $
                      </span>
                    </div>
                    <div className="chip mt-1 flex-col bg-gray-100 border border-gray-400 rounded-md pl-1 ml-0">
                      <InfoTooltipComponent description="Total return including appreciation, equity buildup, tax benefits, and cash flow" />{" "}
                      ROI:{" "}
                      <span
                        className={
                          parseFloat(roiValue) < 0
                            ? "text-red-500"
                            : "text-green-600"
                        }
                      >
                        {roiValue} %
                      </span>
                    </div>
                    {remainingChips.map((item) => (
                      <div className="chip mt-1 flex-col bg-gray-100 border border-gray-400 rounded-md pl-1 ml-0">
                        <InfoTooltipComponent description={item.hint} />{" "}
                        {item.label}:{" "}
                        <span
                          className={
                            parseFloat(item.value) < 0
                              ? "text-red-500"
                              : "text-green-600"
                          }
                        >
                          {item.value}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </li>
          )}
          <Divider />

          <li className="mb-2  ml-2">
            {(selectedRowData.rentalestimate || selectedRowData.fmr) && (
              <CashOnCashReturnCalculator
                purchaseprice={formatToFloat(selectedRowData.price)}
                rentalestimate={
                  selectedRowData.rentalestimate || selectedRowData.fmr
                }
                selectedRowData={selectedRowData}
                onCashOnCashReturnChange={handleCashOnCashReturnChange}
              />
            )}
          </li>

          <Divider />
          {/* Property Managers Section */}
          {propertyManagers?.length > 0 && (
            <li className="mb-2 ml-2">
              <div
                className="flex items-center cursor-pointer py-2 hover:bg-gray-50 rounded"
                onClick={() => setIsPmExpanded(!isPmExpanded)}
              >
                <i
                  className={`pi ${
                    isPmExpanded ? "pi-chevron-down" : "pi-chevron-right"
                  } mr-2`}
                ></i>
                <span className="text-lg font-medium">Property Managers</span>
              </div>

              {isPmExpanded && (
                <div className="space-y-2 mt-2">
                  {propertyManagers
                    .sort(() => Math.random() - 0.5)
                    .slice(0, 10)
                    .map((manager, index) => (
                      <div
                        key={index}
                        className="flex justify-between items-start p-3 border border-gray-200 rounded hover:bg-gray-50 transition-colors"
                      >
                        <div className="flex-1">
                          <div className="font-medium text-gray-900">
                            {manager.name}
                          </div>
                          <div className="text-sm text-gray-600">
                            {manager.company}
                          </div>
                          <div className="text-xs text-gray-500 mt-1">
                            {cleanAddress(manager.address)}
                          </div>
                        </div>
                        {manager.phone && (
                          <a
                            href={`tel:${manager.phone}`}
                            className="flex items-center text-blue-600 hover:text-blue-800 ml-4 text-sm"
                          >
                            <i className="pi pi-phone mr-1"></i>
                            {manager.phone}
                          </a>
                        )}
                      </div>
                    ))}
                </div>
              )}
              <Divider />
            </li>
          )}

          <li className="flex flex-row items-center justify-between mb-4 ml-2">
            <div>
              <span className="text-gray-900 font-medium mr-2">
                Days On Market:
              </span>
              <div className="inline-flex items-center text-gray-600">
                {selectedRowData.active_days_on_site || "today"}
              </div>
              <div className="flex items-center">
                <div className="text-gray-600 mr-4">
                  <span
                    className={`${
                      selectedRowData.active_days_on_site < 40
                        ? "text-green-500"
                        : selectedRowData.active_days_on_site < 90
                        ? "text-gray-600"
                        : "text-red-500"
                    }`}
                  >
                    Last Updated:{" "}
                    {moment(selectedRowData.updateddate).format("LL")}
                  </span>
                </div>
                <div className="text-gray-600 mr-4">
                  <span
                    className={`${
                      selectedRowData.active_days_on_site < 40
                        ? "text-green-500"
                        : selectedRowData.active_days_on_site < 90
                        ? "text-gray-600"
                        : "text-red-500"
                    }`}
                  >
                    First Seen:{" "}
                    {moment(selectedRowData.createddate).format("LL")}
                  </span>
                </div>
              </div>
            </div>
          </li>
          <Divider />
          {selectedRowData.linkedid && isAdmin && (
            <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-2 ml-2">
              <div>
                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">
                  Linked Id
                </span>
                <div className="mt-1 text-600">
                  <a
                    href={"/links/" + selectedRowData.linkedid}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {selectedRowData.linkedid}
                  </a>
                </div>
              </div>
              {/* <div
                className="flex align-items-center justify-content-center bg-green-100 border-round"
                style={{ width: "2.5rem", height: "2.5rem" }}
              >
                <i className="pi pi-link text-green-500 text-xl"></i>
              </div> */}
            </li>
          )}
          {selectedRowData.extractid && isAdmin && (
            <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-2 ml-2">
              <div>
                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">
                  cashflow-influencer
                </span>
                <div className="mt-1 text-600">
                  <a
                    href={"/cashflow-influencer/" + selectedRowData.extractid}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {selectedRowData.extractid}
                  </a>
                </div>
              </div>
              <div>
                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">
                  Elevator Pitch
                </span>
                <div className="mt-1 text-600">
                  <a
                    href={"/elevatorpitch/" + selectedRowData.extractid}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {selectedRowData.extractid}
                  </a>
                </div>
              </div>
            </li>
          )}
        </ul>
      </div>
    </div>
  );

  const handleNodeSelect = (nodeData) => {
    showDetailsInSidebar(nodeData);
    handleResize();
  };

  return (
    <div>
      <FiltersHeader
        city={city}
        handleCityChange={handleCityChange}
        isFetchingCities={isFetchingCities}
        showSuggestions={showSuggestions}
        citySuggestions={citySuggestions}
        handleSuggestionClick={handleSuggestionClick}
        state={state}
        setState={setState}
        zipcode={zipcode}
        setZipCode={setZipCode}
        status={status}
        setStatus={setStatus}
        statusList={statusList}
        selectedPropertyTypes={selectedPropertyTypes}
        handlePropertyTypeChange={handlePropertyTypeChange}
        propertyTypeOptions={propertyTypeOptions}
        filter={filter}
        setFilters={setFilters}
        filtersList={filtersList}
        minPrice={minPrice}
        setMinPrice={setMinPrice}
        maxPrice={maxPrice}
        setMaxPrice={setMaxPrice}
        minPpsqft={minPpsqft}
        setMinPpsqft={setMinPpsqft}
        maxPpsqft={maxPpsqft}
        setMaxPpsqft={setMaxPpsqft}
        fetchData={fetchData}
      />

      {FilterForm}
      {isLoading ? (
        <div
          className="flex align-items-center justify-content-center"
          style={{ height: "200px" }}
        >
          <ProgressSpinner />
        </div>
      ) : (
        <div>
          <div>
            <ListView products={products} onNodeSelect={handleNodeSelect} />
          </div>

          <Sidebar
            visible={rightSidebarVisible}
            onHide={toggleRightSidebar}
            position="right"
            style={{ width: rightSidebarWidth }}
          >
            {selectedRowData && (
              <div>
                <div className="sidebar-content" ref={containerRef}>
                  <div className="sticky-header">
                    <div className="address-nav-container">
                      <span className="address">
                        {selectedRowData.fullAddress}
                      </span>
                      <div className="navigation">
                        <Button
                          label="Previous"
                          icon="pi pi-chevron-left"
                          className="p-button-link pl-0 ml-2"
                          onClick={() => navigateRows(-1)}
                          disabled={currentIndex === 0}
                        />
                        <Button
                          label="Next"
                          icon="pi pi-chevron-right"
                          className="p-button-link"
                          iconPos="right"
                          onClick={() => navigateRows(1)}
                          disabled={currentIndex === products.length - 1}
                        />
                      </div>
                    </div>
                  </div>
                  {renderDetails()}
                </div>
              </div>
            )}
          </Sidebar>
        </div>
      )}
    </div>
  );
}
