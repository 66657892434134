import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Badge } from "primereact/badge";
import { useNavigate } from "react-router-dom";

const StatsCard = ({ value, label, color, icon }) => (
  <Card className="shadow-sm">
    <div className="text-center p-4">
      <div className={`text-4xl font-bold mb-2 ${color}`}>
        {icon && <i className={`pi ${icon} mr-2`}></i>}
        {value}
      </div>
      <div className="text-gray-600 text-sm">{label}</div>
    </div>
  </Card>
);

const PropertyManagersTable = () => {
  const [propertyManagers, setPropertyManagers] = useState([]);
  const [searchCity, setSearchCity] = useState("");
  const [searchState, setSearchState] = useState("");
  const [searchName, setSearchName] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(20);
  const [first, setFirst] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [analytics, setAnalytics] = useState({
    totalManagers: 0,
    activeStates: 0,
    recentlyUpdated: 0,
    avgResponseTime: "24h",
  });
  const toast = useRef(null);
  const navigate = useNavigate();
  const [stats, setStats] = useState({
    totalManagers: 0,
    activeStates: 0,
    citiesCovered: 0,
    avgManagersPerState: 0,
  });

  const onPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const onSort = (event) => {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);
  };

  useEffect(() => {
    fetchPropertyManagers();
    updateAnalytics();
  }, [first, rows, sortField, sortOrder]);

  const StatsSection = ({ analytics }) => {
    const {
      totalManagers,
      activeStates,
      uniqueCities,
      managersPerState,
      managersPerCity,
    } = analytics;

    return (
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
        <StatsCard
          value={totalManagers}
          label="Total Managers"
          color="text-blue-600"
        />
        <StatsCard
          value={activeStates}
          label="States Covered"
          color="text-green-600"
        />
        <StatsCard
          value={uniqueCities}
          label="Cities with Coverage"
          color="text-purple-600"
        />
        <StatsCard
          value={Math.round(totalManagers / activeStates)}
          label="Avg Managers per State"
          color="text-orange-600"
        />
      </div>
    );
  };

  const MarketInsights = ({ data, stats }) => {
    const highDensityMarkets = data.filter(
      (pm) => pm.managers_in_city > 5
    ).length;
    const singleManagerMarkets = data.filter(
      (pm) => pm.managers_in_city === 1
    ).length;

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <Card className="shadow-sm">
          <div className="text-lg font-bold mb-4">Market Coverage</div>
          <div className="space-y-3">
            <div className="flex justify-between">
              <span>High-density Markets</span>
              <span className="font-semibold">{highDensityMarkets}</span>
            </div>
            <div className="flex justify-between">
              <span>Single Manager Areas</span>
              <span className="font-semibold">{singleManagerMarkets}</span>
            </div>
            <div className="flex justify-between">
              <span>States Without Coverage</span>
              <span className="font-semibold">{50 - stats.activeStates}</span>
            </div>
          </div>
        </Card>
      </div>
    );
  };

  const updateAnalytics = () => {
    // Calculate analytics from current data
    const states = new Set(propertyManagers.map((pm) => pm.state));
    const recentDate = new Date();
    recentDate.setDate(recentDate.getDate() - 7);

    setAnalytics({
      totalManagers: totalRecords,
      activeStates: states.size,
      recentlyUpdated: propertyManagers.filter(
        (pm) => new Date(pm.updatedate) > recentDate
      ).length,
      avgResponseTime: "24h",
    });
  };

  // Fetch functions remain the same as your original code
  const fetchPropertyManagers = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://ipmc-api.onrender.com/api/lookup/property-manager?limit=${rows}&offset=${first}${
          sortOrder ? `&sort=${sortOrder === 1 ? "desc" : "asc"}` : ""
        }`
      );
      const data = await response.json();
      setPropertyManagers(data.data);
      setTotalRecords(data.total);
      setLoading(false);

      if (data.data && data.data.length > 0) {
        const firstRecord = data.data[0];
        setStats({
          totalManagers: firstRecord.total_count,
          activeStates: firstRecord.unique_states,
          citiesCovered: firstRecord.unique_cities,
          avgManagersPerState: Math.round(
            firstRecord.total_count / firstRecord.unique_states
          ),
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch data",
        life: 3000,
      });
      setLoading(false);
    }
  };

  const fetchPropertyManagersByCityAndState = async (city, state, name) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://ipmc-api.onrender.com/api/lookup/property-manager/search/?city=${city}&state=${state}&name=${searchName}`
      );
      const data = await response.json();
      setPropertyManagers(data);
      setTotalRecords(data.length);
      setLoading(false);

      if (data.data && data.data.length > 0) {
        const firstRecord = data.data[0];
        setStats({
          totalManagers: firstRecord.total_count,
          activeStates: firstRecord.unique_states,
          citiesCovered: firstRecord.unique_cities,
          avgManagersPerState: Math.round(
            firstRecord.total_count / firstRecord.unique_states
          ),
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch data",
        life: 3000,
      });
      setLoading(false);
    }
  };

  const onSearch = () => {
    if (
      searchCity.trim() !== "" ||
      searchState.trim() !== "" ||
      searchName.trim() !== ""
    ) {
      fetchPropertyManagersByCityAndState(
        searchCity.trim(),
        searchState.trim(),
        searchName.trim()
      );
    } else {
      fetchPropertyManagers();
    }
  };

  // Custom templates for DataTable
  const companyTemplate = (rowData) => (
    <div className="flex flex-column">
      <span className="text-xl font-bold mb-1">{rowData.company}</span>
      <span className="text-gray-600">{rowData.name}</span>
    </div>
  );
  const locationTemplate = (rowData) => {
    // Create a search URL with company info
    const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(
      `${rowData.company} property management ${rowData.city} ${rowData.state}`
    )}`;

    return (
      <div className="space-y-1">
        <span className="mb-1">{rowData.address}</span>
        <div className="text-gray-600 mt-1">{`${rowData.city}, ${rowData.state}`}</div>
        <a
          href={searchUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:text-blue-700 text-sm inline-flex items-center"
        >
          search
        </a>
      </div>
    );
  };

  // Add this utility function at the top of your file
  const checkAreaCode = (phone, state) => {
    // Remove all non-numeric characters
    const cleanPhone = phone?.replace(/\D/g, "");
    if (!cleanPhone || cleanPhone.length < 10) return false;

    const areaCode = cleanPhone.substring(0, 3);

    const areaCodeMap = {
      'AL': ['205', '251', '256', '334', '938'],
      'AK': ['907'],
      'AZ': ['480', '520', '602', '623', '928'],
      'AR': ['479', '501', '870'],
      'CA': ['209', '213', '310', '323', '408', '415', '424', '442', '510', '530', '559', '562', '619', '626', '650', '657', '661', '669', '707', '714', '747', '760', '805', '818', '831', '858', '909', '916', '925', '949', '951'],
      'CO': ['303', '719', '720', '970'],
      'CT': ['203', '475', '860', '959'],
      'DE': ['302'],
      'FL': ['239', '305', '321', '352', '386', '407', '561', '727', '754', '772', '786', '813', '850', '863', '904', '941', '954'],
      'GA': ['229', '404', '470', '478', '678', '706', '762', '770', '912'],
      'HI': ['808'],
      'ID': ['208', '986'],
      'IL': ['217', '224', '309', '312', '331', '618', '630', '708', '773', '779', '815', '847', '872'],
      'IN': ['219', '260', '317', '463', '574', '765', '812', '930'],
      'IA': ['319', '515', '563', '641', '712'],
      'KS': ['316', '620', '785', '913'],
      'KY': ['270', '364', '502', '606', '859'],
      'LA': ['225', '318', '337', '504', '985'],
      'ME': ['207'],
      'MD': ['240', '301', '410', '443', '667'],
      'MA': ['339', '351', '413', '508', '617', '774', '781', '857', '978'],
      'MI': ['231', '248', '269', '313', '517', '586', '616', '734', '810', '906', '947', '989'],
      'MN': ['218', '320', '507', '612', '651', '763', '952'],
      'MS': ['228', '601', '662', '769'],
      'MO': ['314', '417', '573', '636', '660', '816'],
      'MT': ['406'],
      'NE': ['308', '402', '531'],
      'NV': ['702', '725', '775'],
      'NH': ['603'],
      'NJ': ['201', '551', '609', '732', '848', '856', '862', '908', '973'],
      'NM': ['505', '575'],
      'NY': ['212', '315', '332', '347', '516', '518', '585', '607', '631', '646', '680', '716', '718', '845', '914', '917', '929', '934'],
      'NC': ['252', '336', '704', '828', '910', '919', '980', '984'],
      'ND': ['701'],
      'OH': ['216', '220', '234', '330', '380', '419', '440', '513', '567', '614', '740', '937'],
      'OK': ['405', '539', '580', '918'],
      'OR': ['458', '503', '541', '971'],
      'PA': ['215', '267', '272', '412', '484', '570', '610', '717', '724', '814', '878'],
      'RI': ['401'],
      'SC': ['803', '843', '854', '864'],
      'SD': ['605'],
      'TN': ['423', '615', '629', '731', '865', '901', '931'],
      'TX': ['210', '214', '254', '281', '325', '346', '361', '409', '430', '432', '469', '512', '682', '713', '737', '806', '817', '830', '832', '903', '915', '936', '940', '956', '972', '979'],
      'UT': ['385', '435', '801'],
      'VT': ['802'],
      'VA': ['276', '434', '540', '571', '703', '757', '804'],
      'WA': ['206', '253', '360', '425', '509', '564'],
      'WV': ['304', '681'],
      'WI': ['262', '414', '534', '608', '715', '920'],
      'WY': ['307'],
      'DC': ['202'],
      'PR': ['787', '939'],
      'VI': ['340']
    };

    // Check if area code matches state
    return areaCodeMap[state]?.includes(areaCode) || false;
  };

  const getDataQuality = (rowData) => {
    const indicators = [];

    // Check for complete data
    if (rowData.phone && rowData.phone.length >= 10)
      indicators.push("Valid Phone");
    if (rowData.company && rowData.company.length > 3)
      indicators.push("Company Name");
    if (rowData.state && rowData.city) indicators.push("Location");

    // Check data consistency
    const isPhoneAreaCodeMatch = checkAreaCode(rowData.phone, rowData.state);

    return (
      <div className="data-quality flex gap-1">
        {indicators.map((indicator) => (
          <span className="px-2 py-1 text-xs rounded bg-green-100 text-green-800">
            {indicator}
          </span>
        ))}
        {!isPhoneAreaCodeMatch && (
          <span className="px-2 py-1 text-xs rounded bg-yellow-100 text-yellow-800">
            Phone Area Code Mismatch
          </span>
        )}
      </div>
    );
  };

  const socialValidation = (rowData) => (
    <div className="validation-links flex gap-2">
      <a
        href={`https://www.yelp.com/search?find_desc=${encodeURIComponent(
          rowData.company
        )}&find_loc=${encodeURIComponent(`${rowData.city}, ${rowData.state}`)}`}
        target="_blank"
        rel="noopener"
      >
        <i className="pi pi-star" /> Reviews
      </a>
      {/* <a
        href={`https://www.bbb.org/search?find_country=USA&find_text=${encodeURIComponent(
          `${rowData.company} ${rowData.city} ${rowData.state}`
        )}`}
        target="_blank"
        rel="noopener"
      >
        <i className="pi pi-check-circle" /> BBB
      </a> */}
    </div>
  );

  const dataFreshness = (updateDate) => {
    const lastUpdate = new Date(updateDate);
    const monthsOld = (new Date() - lastUpdate) / (1000 * 60 * 60 * 24 * 30);

    return (
      <span
        className={`data-freshness ${
          monthsOld > 6 ? "text-red-500" : "text-green-500"
        }`}
      >
        Last verified: {lastUpdate.toLocaleDateString()}
      </span>
    );
  };

  const contactTemplate = (rowData) => (
    <div className="flex flex-column">
      <a
        href={`tel:${rowData.phone}`}
        className="text-blue-500 hover:text-blue-700 mb-1"
      >
        {rowData.phone}
      </a>
      {rowData.fax && <span className="text-gray-600">Fax: {rowData.fax}</span>}
    </div>
  );

  const statusTemplate = (rowData) => (
    <span className="inline-flex align-items-center px-2 py-1 bg-blue-100 text-blue-800 rounded-full">
      Active
    </span>
  );

  return (
    <div className="p-4 md:p-4">
      {/* Stats Section */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 mb-4 md:mb-6 p-2">
        <StatsCard
          value={stats.totalManagers}
          label="Total Managers"
          color="text-blue-600"
          icon="pi-users"
        />
        <StatsCard
          value={stats.activeStates}
          label="Active States"
          color="text-green-600"
          icon="pi-globe"
        />
        <StatsCard
          value={stats.citiesCovered}
          label="Cities Covered"
          color="text-purple-600"
          icon="pi-building"
        />
        <StatsCard
          value={stats.avgManagersPerState}
          label="Avg Per State"
          color="text-orange-600"
          icon="pi-chart-bar"
        />
      </div>

      {/* Market insights */}
      {/* <MarketInsights data={propertyManagers} stats={stats} /> */}

      {/* Search Section - Stack on mobile */}
      <div className="flex flex-col gap-2 mb-4 md:flex-row md:gap-4">
        <span className="p-input-icon-left w-full">
          <InputText
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            placeholder="Manager or Company"
            className="w-full p-2"
          />
        </span>
        <span className="p-input-icon-left w-full">
          <InputText
            value={searchCity}
            onChange={(e) => setSearchCity(e.target.value)}
            placeholder="City"
            className="w-full p-2"
          />
        </span>
        <span className="p-input-icon-left w-full">
          <InputText
            value={searchState}
            onChange={(e) => setSearchState(e.target.value)}
            placeholder="State"
            className="w-full p-2"
          />
        </span>
        <Button
          label="Search"
          icon="pi pi-search"
          className="p-button-primary w-full md:w-auto"
          onClick={onSearch}
        />
      </div>

      <Toast ref={toast} />

      {/* DataTable */}
      <DataTable
        value={propertyManagers}
        paginator
        rows={rows}
        first={first}
        totalRecords={totalRecords}
        lazy
        loading={loading}
        responsiveLayout="scroll"
        onPage={onPage}
        onSort={onSort}
        sortField={sortField}
        sortOrder={sortOrder}
        className="p-datatable-sm"
        showGridlines
        stripedRows
      >
        <Column
          field="company"
          header="Company & Manager"
          body={companyTemplate}
          sortable
        />
        <Column field="address" header="Location" body={locationTemplate} />
        <Column field="phone" header="Contact" body={contactTemplate} />
        <Column
          header="Validation"
          body={(rowData) => (
            <div className="space-y-2">
              {getDataQuality(rowData)}
              {socialValidation(rowData)}
              {dataFreshness(rowData.updateddate)}
            </div>
          )}
        />
      </DataTable>
    </div>
  );
};

export default PropertyManagersTable;
