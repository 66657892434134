import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import LandingEmailCapture from './LandingEmailCapture';
import RealEstateCalculatorPDFGenerator from './static-roi-calculator -pdf';

const formatValue = (value, type = 'number', decimals = 2) => {
  if (value === null || value === undefined) return '-';
  
  try {
    switch (type) {
      case 'currency':
        return `$${Number(value).toLocaleString('en-US', { 
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals 
        })}`;
      case 'percentage':
        return `${Number(value).toFixed(decimals)}%`;
      default:
        return Number(value).toFixed(decimals);
    }
  } catch (error) {
    return '-';
  }
};

const RealEstateCalculator = () => {
  const [inputs, setInputs] = useState({
    propertyType: 'Single Family',
    purchasePrice: 300000,
    structureValue: 240000,
    downPayment: 20,
    interestRate: 7,
    loanTerm: 30,
    propertyTax: 1.2,
    insurance: 0.5,
    vacancyRate: 5,
    maintenance: 1,
    managementFee: 10,
    appreciationRate: 3,
    holdingPeriod: 10,
    discountRate: 5,
    marginalTaxRate: 25,
    monthlyRent: 2000
  });

  const [results, setResults] = useState({
    monthlyMortgage: 1596.73,
    initialMonthlyCashFlow: -561.73,
    cashOnCashReturn: -11.23,
    capRate: 4.14,
    oer: 45.53,
    irr: 3.68,
    dscr: 0.77,
    priceToRent: 12.50,
    breakEvenRatio: 1.30,
    totalReturn: -27.67,
    npv: -11280.55,
    roi: -27.67,
    annualizedROI: -3.19,
    totalDepreciation: 87272.73,
    totalTaxSavings: 21818.18,
    cashFlowData: [],
    appreciationData: []
  });

  const propertyTypes = [
    { label: 'Single Family', value: 'Single Family' }
  ];

  const inputFields = [
    { name: 'purchasePrice', label: 'Purchase Price ($)', mode: 'currency', currency: 'USD' },
    { name: 'structureValue', label: 'Structure Value ($)', mode: 'currency', currency: 'USD' },
    { name: 'downPayment', label: 'Down Payment (%)', mode: 'decimal', minFractionDigits: 1 },
    { name: 'interestRate', label: 'Interest Rate (%)', mode: 'decimal', minFractionDigits: 1 },
    { name: 'loanTerm', label: 'Loan Term (years)', mode: 'decimal', minFractionDigits: 0 },
    { name: 'propertyTax', label: 'Property Tax (%)', mode: 'decimal', minFractionDigits: 1 },
    { name: 'insurance', label: 'Insurance (%)', mode: 'decimal', minFractionDigits: 1 },
    { name: 'vacancyRate', label: 'Vacancy Rate (%)', mode: 'decimal', minFractionDigits: 1 },
    { name: 'maintenance', label: 'Maintenance (%)', mode: 'decimal', minFractionDigits: 1 },
    { name: 'managementFee', label: 'Management Fee (%)', mode: 'decimal', minFractionDigits: 1 },
    { name: 'monthlyRent', label: 'Monthly Rent ($)', mode: 'currency', currency: 'USD' },
    { name: 'appreciationRate', label: 'Appreciation Rate (%)', mode: 'decimal', minFractionDigits: 1 },
    { name: 'holdingPeriod', label: 'Holding Period (years)', mode: 'decimal', minFractionDigits: 0 },
    { name: 'discountRate', label: 'Discount Rate (%)', mode: 'decimal', minFractionDigits: 1 },
    { name: 'marginalTaxRate', label: 'Marginal Tax Rate (%)', mode: 'decimal', minFractionDigits: 1 }
  ];

  const metrics = [
    {
      label: "Monthly Mortgage Payment",
      value: formatValue(results.monthlyMortgage, 'currency'),
      description: "The amount you'll pay each month towards your mortgage, including principal and interest."
    },
    {
      label: "Initial Monthly Cash Flow",
      value: formatValue(results.initialMonthlyCashFlow, 'currency'),
      description: "The difference between your monthly rental income and expenses, including mortgage payments, in the first year."
    },
    {
      label: "Cash-on-Cash Return",
      value: formatValue(results.cashOnCashReturn, 'percentage'),
      description: "The ratio of annual cash flow to the total cash invested, expressed as a percentage."
    },
    {
      label: "Cap Rate",
      value: formatValue(results.capRate, 'percentage'),
      description: "The ratio of net operating income to property value, indicating the potential return on investment."
    },
    {
      label: "Gross Rent Multiplier (GRM)",
      value: formatValue(results.grmRatio, 'number'),
      description: "The ratio of property price to annual rental income, used to compare investment properties."
    },
    {
      label: "Debt Service Coverage Ratio (DSCR)",
      value: formatValue(results.dscr, 'number'),
      description: "The ratio of net operating income to debt service, indicating the property's ability to cover its debt payments."
    },
    {
      label: "Price to Rent Ratio",
      value: formatValue(results.priceToRent, 'number'),
      description: "The ratio of property price to annual rent, indicating whether it's better to buy or rent in a given market."
    },
    {
      label: "1% Rule",
      value: "Does not meet",
      description: "A property meets the 1% rule if the monthly rent is at least 1% of the purchase price."
    },
    {
      label: "Break-even Ratio",
      value: formatValue(results.breakEvenRatio, 'number'),
      description: "The ratio of operating expenses to gross operating income, indicating the point at which the property becomes profitable."
    },
    {
      label: "Total Return (over 10 years)",
      value: formatValue(results.totalReturn, 'percentage'),
      description: "The total return on investment, including appreciation and cash flow, expressed as a percentage of the initial investment."
    },
    {
      label: "Net Present Value (NPV)",
      value: formatValue(results.npv, 'currency'),
      description: "The difference between the present value of cash inflows and the present value of cash outflows over the investment period."
    },
    {
      label: "Return on Investment (ROI)",
      value: formatValue(results.roi, 'percentage'),
      description: "The total return on investment over the holding period, expressed as a percentage."
    },
    {
      label: "Annualized ROI",
      value: formatValue(results.annualizedROI, 'percentage'),
      description: "The average annual return on investment over the holding period."
    },
    {
      label: "Operating Expense Ratio (OER)",
      value: formatValue(results.oer, 'percentage'),
      description: "The ratio of total operating expenses to effective gross income."
    },
    {
      label: "Internal Rate of Return (IRR)",
      value: formatValue(results.irr, 'percentage'),
      description: "The annualized rate of return that sets the net present value of all cash flows to zero."
    },
    {
      label: "Total Depreciation",
      value: formatValue(results.totalDepreciation, 'currency'),
      description: "The total amount of depreciation deduction over the holding period."
    },
    {
      label: "Total Tax Savings from Depreciation",
      value: formatValue(results.totalTaxSavings, 'currency'),
      description: "The estimated tax savings from depreciation deductions over the holding period, based on your tax status."
    }
  ];

  const handleInputChange = (name, value) => {
    setInputs(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const calculateResults = () => {
    try {
      // Calculate loan amount and monthly mortgage
      const loanAmount = inputs.purchasePrice * (1 - inputs.downPayment / 100);
      const monthlyRate = inputs.interestRate / 1200;
      const months = inputs.loanTerm * 12;
      const monthlyMortgage = loanAmount * (monthlyRate * Math.pow(1 + monthlyRate, months)) / 
                            (Math.pow(1 + monthlyRate, months) - 1);
  
      // Calculate monthly expenses
      const propertyTaxMonthly = (inputs.purchasePrice * inputs.propertyTax / 100) / 12;
      const insuranceMonthly = (inputs.purchasePrice * inputs.insurance / 100) / 12;
      const vacancyMonthly = inputs.monthlyRent * (inputs.vacancyRate / 100);
      const maintenanceMonthly = (inputs.purchasePrice * inputs.maintenance / 100) / 12;
      const managementMonthly = inputs.monthlyRent * (inputs.managementFee / 100);
  
      // Calculate monthly and annual cash flows
      const monthlyExpenses = monthlyMortgage + propertyTaxMonthly + insuranceMonthly + 
                            vacancyMonthly + maintenanceMonthly + managementMonthly;
      const initialMonthlyCashFlow = inputs.monthlyRent - monthlyExpenses;
      const annualCashFlow = initialMonthlyCashFlow * 12;
  
      // Calculate investment metrics
      const totalInvestment = inputs.purchasePrice * (inputs.downPayment / 100);
      const cashOnCashReturn = (annualCashFlow / totalInvestment) * 100;
      
      const grossOperatingIncome = inputs.monthlyRent * 12;
      const operatingExpenses = (propertyTaxMonthly + insuranceMonthly + vacancyMonthly + 
                               maintenanceMonthly + managementMonthly) * 12;
      const netOperatingIncome = grossOperatingIncome - operatingExpenses;
      
      // Calculate ratios
      const capRate = (netOperatingIncome / inputs.purchasePrice) * 100;
      const grmRatio = inputs.purchasePrice / grossOperatingIncome;
      const oer = (operatingExpenses / grossOperatingIncome) * 100;
      const dscr = netOperatingIncome / (monthlyMortgage * 12);
      const priceToRent = inputs.purchasePrice / (inputs.monthlyRent * 12);
      const breakEvenRatio = operatingExpenses / grossOperatingIncome;
  
      // Generate cash flow and appreciation data
      const appreciationData = Array.from({ length: inputs.holdingPeriod + 1 }, (_, i) => {
        const value = inputs.purchasePrice * Math.pow(1 + inputs.appreciationRate / 100, i);
        return {
          year: `Year ${i}`,
          value: value
        };
      });
  
      const cashFlowData = Array.from({ length: inputs.holdingPeriod }, (_, i) => {
        const yearlyRent = inputs.monthlyRent * 12 * Math.pow(1 + inputs.appreciationRate / 100, i);
        const yearlyExpenses = operatingExpenses * Math.pow(1 + inputs.appreciationRate / 100, i);
        const yearlyCashFlow = yearlyRent - yearlyExpenses - (monthlyMortgage * 12);
        return {
          year: `Year ${i + 1}`,
          cashFlow: yearlyCashFlow
        };
      });
  
      // Calculate returns
      const totalCashFlow = cashFlowData.reduce((sum, year) => sum + year.cashFlow, 0);
      const finalPropertyValue = appreciationData[inputs.holdingPeriod].value;
      const totalAppreciation = finalPropertyValue - inputs.purchasePrice;
  
      // Calculate depreciation and tax savings
      const annualDepreciation = inputs.structureValue / 27.5;
      const totalDepreciation = annualDepreciation * inputs.holdingPeriod;
      const totalTaxSavings = totalDepreciation * (inputs.marginalTaxRate / 100);
  
      // Calculate NPV and returns
      const npv = cashFlowData.reduce((acc, curr, i) => {
        return acc + (curr.cashFlow / Math.pow(1 + inputs.discountRate / 100, i + 1));
      }, -totalInvestment);
  
      const totalReturn = ((totalAppreciation + totalCashFlow) / totalInvestment) * 100;
      const annualizedROI = (Math.pow(1 + totalReturn / 100, 1 / inputs.holdingPeriod) - 1) * 100;
      
      // Update all results
      setResults({
        monthlyMortgage,
        initialMonthlyCashFlow,
        cashOnCashReturn,
        capRate,
        grmRatio,
        oer,
        irr: annualizedROI, // Simplified IRR calculation
        dscr,
        priceToRent,
        breakEvenRatio,
        totalReturn,
        npv,
        roi: totalReturn,
        annualizedROI,
        totalDepreciation,
        totalTaxSavings,
        cashFlowData,
        appreciationData
      });
  
    } catch (error) {
      console.error('Error calculating results:', error);
    }
  };

  useEffect(() => {
    calculateResults();
  }, [inputs]);

  const renderHeader = (title) => (
    <div className="text-xl font-bold p-4 border-b">{title}</div>
  );

  return (
    <div className="w-full max-w-6xl mx-auto p-4 space-y-6">
      <Card header={renderHeader("Real Estate Investment Analysis")}>
        <div className="p-4">
          <div className="grid grid-cols-4 gap-4">
            <div className="col">
              <span className="p-float-label">
                <Dropdown
                  id="propertyType"
                  value={inputs.propertyType}
                  options={propertyTypes}
                  onChange={(e) => handleInputChange('propertyType', e.value)}
                  className="w-full"
                />
                <label htmlFor="propertyType">Property Type</label>
              </span>
            </div>

            {inputFields.map(field => (
              <div key={field.name} className="col">
                <span className="p-float-label">
                  <InputNumber
                    id={field.name}
                    value={inputs[field.name]}
                    onValueChange={(e) => handleInputChange(field.name, e.value)}
                    mode={field.mode}
                    currency={field.currency}
                    locale="en-US"
                    minFractionDigits={field.minFractionDigits}
                    className="w-full"
                  />
                  <label htmlFor={field.name}>{field.label}</label>
                </span>
              </div>
            ))}
          </div>
        </div>
      </Card>

      <Card header={renderHeader("Investment Analysis Results")}>
        <div className="p-4">
          {metrics.map((metric, index) => (
            <div key={index} className="mb-4">
              <div className="flex gap-2">
                <span className="font-bold text-900">{metric.label}:</span>
                <span className={`font-bold ${
                  String(metric.value).includes('-') ? 'text-red-500' : 
                  metric.value === 'Does not meet' ? 'text-red-500' :
                  String(metric.value).includes('$') ? 'text-green-500' : 
                  'text-blue-500'
                }`}>
                  {metric.value}
                </span>
              </div>
              <p className="text-gray-500 text-sm mt-1">{metric.description}</p>
            </div>
          ))}

            <div className="p-4">
            <LandingEmailCapture />
            </div>
            <div className="p-4">
            <RealEstateCalculatorPDFGenerator
  inputs={inputs}
  results={results}
  metrics={metrics}
/>
            </div>
           

          <div className="space-y-4 mt-6">
            <h3 className="font-semibold">Annual Cash Flow Projection</h3>
            <BarChart width={800} height={300} data={results.cashFlowData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="cashFlow" fill="#82ca9d" name="Annual Cash Flow" />
            </BarChart>

            <h3 className="font-semibold">Property Value Appreciation</h3>
            <LineChart width={800} height={300} data={results.appreciationData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="value" stroke="#8884d8" name="Property Value" />
            </LineChart>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default RealEstateCalculator;