export const calculateReturns = ({
    purchasePrice,
    downPaymentPercent,
    interestRate,
    loanTermYears,
    monthlyRent,
    vacancyRatePercent,
    maintenancePercent,
    operatingExpensePercent,
    appreciationRate,
    holdingPeriodYears,
    sellingCostsPercent,
    hoafee,
    propertyTaxRate,
    insuranceRatePercent = 0.005
}) => {
    const downPayment = purchasePrice * (downPaymentPercent / 100);
    const loanAmount = purchasePrice - downPayment;
    const monthlyInterestRate = interestRate / 12 / 100;
    const numberOfPayments = loanTermYears * 12;

    

    const monthlyMortgagePayment =
        (loanAmount * monthlyInterestRate) /
        (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));

    const grossAnnualRent = monthlyRent * 12;
    const annualVacancyLoss = grossAnnualRent * (vacancyRatePercent / 100);
    const netAnnualRent = grossAnnualRent - annualVacancyLoss;

    const annualMortgagePayment = monthlyMortgagePayment * 12;
    const annualMaintenanceCosts = monthlyRent * (maintenancePercent / 100) * 12;
    const annualOperatingExpenses = monthlyRent * (operatingExpensePercent / 100) * 12;
    const annualHOACosts = hoafee * 12;
    const annualPropertyTax =
      (purchasePrice * parseFloat(propertyTaxRate?.replace("%", "") || 0)) /
      100;
    // Insurance is 0.5% of purchase price annually
    const annualInsurance = purchasePrice * insuranceRatePercent;

    const annualNetOperatingIncome = netAnnualRent - 
                                annualOperatingExpenses - 
                                annualHOACosts -
                                annualMaintenanceCosts -
                                annualPropertyTax -
                                annualInsurance;
                                
    const annualPreTaxCashFlow = netAnnualRent - 
                                annualMortgagePayment - 
                                annualMaintenanceCosts - 
                                annualOperatingExpenses - 
                                annualHOACosts - 
                                annualPropertyTax - 
                                annualInsurance;

    // Calculate DSCR
    const dscr = annualNetOperatingIncome / annualMortgagePayment;

    const capRate = (annualNetOperatingIncome / purchasePrice) * 100;
    const coC = (annualPreTaxCashFlow / downPayment) * 100;

    // ROI calculation
    const sellingPrice = purchasePrice * Math.pow((1 + appreciationRate / 100), holdingPeriodYears);
    const sellingCosts = sellingPrice * (sellingCostsPercent / 100);

    const totalIncome = (annualPreTaxCashFlow * holdingPeriodYears) + (sellingPrice - sellingCosts);
    const totalInvestmentCost = downPayment + (annualMortgagePayment * holdingPeriodYears) + (annualMaintenanceCosts * holdingPeriodYears) + (annualOperatingExpenses * holdingPeriodYears);

    const roi = ((totalIncome - totalInvestmentCost) / totalInvestmentCost) * 100;

    return {
        annualPreTaxCashFlow: annualPreTaxCashFlow.toFixed(2),
        cashOnCashReturn: coC.toFixed(2),
        ROI: roi.toFixed(2),
        capRate: capRate.toFixed(2),
        DSCR: dscr.toFixed(2)
    };
};

// utils/statusClassName.js
export const getStatusClassName = (status) => {
    const normalizedStatus = (status || "").toLowerCase().trim();
  
    if (
      normalizedStatus === "active" ||
      normalizedStatus === "available" ||
      normalizedStatus === "new listing" ||
      normalizedStatus === "new" ||
      normalizedStatus === "1"
    ) {
      return "text-success";
    } else {
      return "text-danger";
    }
  };

