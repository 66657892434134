import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "primereact/button";
import { Alert, AlertDescription } from "./ui/alert";
import {
  Building2,
  Copy,
  UserCheck,
  Users,
  Building,
  Home,
  Loader2,
  AlertCircle,
  DollarSign,
  TrendingUp,
  PieChart,
  Wand2,
} from "lucide-react";
import http from "../http-common";
import { useParams } from "react-router-dom";


const RealEstateElevatorPitch = () => {
    const [propertyData, setPropertyData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [generating, setGenerating] = useState(false);
    const [fetchError, setFetchError] = useState("");
    const [generatedPitch, setGeneratedPitch] = useState("");
    const [generatedPitchParsed, setGeneratedPitchParsed] = useState("");
    const [activeRole, setActiveRole] = useState("investor");
    const [pitchError, setPitchError] = useState("");
    const { id } = useParams();

  useEffect(() => {
    fetchPropertyData(id);
  }, [id]);

  const fetchPropertyData = async (id) => {
    setLoading(true);
    setFetchError("");
    try {
      const response = await http.get(`/extracts/${id}`);
      setPropertyData(response.data);
    } catch (error) {
      setFetchError(error.message || "Error fetching property data");
      console.error("Error fetching property data:", error);
    } finally {
      setLoading(false);
    }
  };

  const DisclaimerText = () => {
    return (
      <div className="mt-8 p-4 bg-gray-50 rounded-lg text-gray-600 text-sm">
        <h3 className="font-semibold mb-2">Calculation Assumptions:</h3>
        <p className="mb-3">
          These calculations are estimates based on the following assumptions:
        </p>
        <ul className="space-y-1 list-disc pl-5">
          <li>Down payment: 25% of purchase price</li>
          <li>Loan term: 30-year fixed rate mortgage</li>
          <li>Property maintenance: 10% of property rent annually</li>
          <li>Operating expenses: 10% of property rent annually</li>
          <li>Vacancy rate: 6% (National average) if not specified</li>
          <li>Property appreciation: 3% annually</li>
          <li>Holding period: 5 years</li>
          <li>Selling costs: 6% of sale price</li>
        </ul>
        <p className="mt-3 italic">
          Note: Actual returns may vary based on market conditions, property management, and other factors. These calculations are for estimation purposes only and should not be considered financial advice.
        </p>
      </div>
    );
  };

  const handleGeneratePitch = async () => {
    setGenerating(true);
    setPitchError("");
    try {
      await generatePitch(activeRole);
    } catch (error) {
      setPitchError("Failed to generate pitch. Please try again.");
    } finally {
      setGenerating(false);
    }
  };

  const roleConfigs = {
    investor: {
      gradient: "linear-gradient(135deg, #3b82f6 0%, #0ea5e9 100%)",
      lightGradient: "linear-gradient(135deg, #dbeafe 0%, #e0f2fe 100%)",
      color: "#2563eb",
    },
    wholesaler: {
      gradient: "linear-gradient(135deg, #8b5cf6 0%, #ec4899 100%)",
      lightGradient: "linear-gradient(135deg, #ede9fe 0%, #fce7f3 100%)",
      color: "#7c3aed",
    },
    propertyManager: {
      gradient: "linear-gradient(135deg, #10b981 0%, #14b8a6 100%)",
      lightGradient: "linear-gradient(135deg, #d1fae5 0%, #ccfbf1 100%)",
      color: "#059669",
    },
    buyer: {
      gradient: "linear-gradient(135deg, #f59e0b 0%, #f97316 100%)",
      lightGradient: "linear-gradient(135deg, #fef3c7 0%, #fff7ed 100%)",
      color: "#d97706",
    },
  };

  const formatCurrency = (value, showNegative = false) => {
    if (value === undefined || value === null) return '$0';
    
    const absValue = Math.abs(value);
    const formattedAbs = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(absValue);
    
    return value < 0 && showNegative ? `-${formattedAbs}` : formattedAbs;
  };

  const calculateMonthlyMortgage = (purchasePrice, downPaymentPercent, interestRate, loanTermYears) => {
    const downPayment = purchasePrice * (downPaymentPercent / 100);
    const loanAmount = purchasePrice - downPayment;
    const monthlyRate = interestRate / 100 / 12;
    const numberOfPayments = loanTermYears * 12;
    
    const monthlyPayment = loanAmount * 
      (monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) / 
      (Math.pow(1 + monthlyRate, numberOfPayments) - 1);
      
    return Math.round(monthlyPayment);
  };
  
  const calculateReturns = ({
    purchasePrice,
    downPaymentPercent,
    interestRate,
    loanTermYears,
    monthlyRent,
    vacancyRatePercent,
    propertyTaxRate,
    maintenancePercent,
    operatingExpensePercent,
    appreciationRate,
    holdingPeriodYears,
    sellingCostsPercent,
    hoafee
  }) => {
    // Calculate loan and down payment
    const downPayment = purchasePrice * (downPaymentPercent / 100);
    const monthlyMortgage = calculateMonthlyMortgage(purchasePrice, downPaymentPercent, interestRate, loanTermYears);
  
    // Calculate monthly income
    const vacancyLoss = monthlyRent * ( vacancyRatePercent / 100);
    const effectiveGrossRent = monthlyRent - vacancyLoss;

    const monthlyPropertyTax =
      (purchasePrice * parseFloat(propertyTaxRate?.replace("%", "") || 0)) /
      100 /
      12;

    // Insurance is 0.5% of purchase price annually
    const monthlyInsurance = (purchasePrice * 0.005) / 12;

    // Property management is 10% of monthly rent
    const monthlyPropertyManagement = monthlyRent * 0.1;
  
    // Calculate monthly expenses
    const monthlyMaintenance = (monthlyRent  * ( maintenancePercent / 100));
    const monthlyOperatingExpenses = (monthlyRent  * (operatingExpensePercent / 100));
    const totalMonthlyExpenses = monthlyMortgage +
                                 monthlyPropertyTax + 
                                 monthlyInsurance + 
                                 monthlyMaintenance + 
                                 vacancyLoss+
                                 hoafee +
                                 monthlyPropertyManagement;
  
    // Calculate cash flow
    const monthlyPreTaxCashFlow =  monthlyRent - totalMonthlyExpenses;
    const annualPreTaxCashFlow = monthlyPreTaxCashFlow * 12;
  
    // Calculate ROI metrics
    const cashOnCashReturn = (annualPreTaxCashFlow / downPayment) * 100;
    const capRate = ((effectiveGrossRent - monthlyMaintenance - monthlyOperatingExpenses) * 12 / purchasePrice) * 100;
  
    // Calculate future value
    const futureValue = purchasePrice * Math.pow(1 + (appreciationRate / 100), holdingPeriodYears);
    const sellingCosts = futureValue * (sellingCostsPercent / 100);
    const netFutureValue = futureValue - sellingCosts;
  
    return {
      monthlyMortgage,
      downPayment,
      monthlyPreTaxCashFlow,
      annualPreTaxCashFlow,
      cashOnCashReturn,
      capRate,
      futureValue,
      netFutureValue,
      effectiveGrossRent,
      totalMonthlyExpenses
    };
  };

  const processDataForRole = (role, data) => {
  if (!data) return null;
  const hoaValue = data.hoa ? Number(data.hoa.replace(/[^0-9.-]+/g, "")) : 0;
  const monthlyRent = (data.rentalestimate !== null && data.rentalestimate !== "") ? 
  data.rentalestimate :
  (data.fmr !== null && data.fmr !== "" ? data.fmr : 0);

  const returns = calculateReturns({
    purchasePrice: data.price,
    downPaymentPercent: 25,
    interestRate: data.latestMortgageRates?.pmms30 || 7.5,
    loanTermYears: 30,
    monthlyRent: monthlyRent,
    vacancyRatePercent: data.rental_vacancy_rate ?? 6,
    propertyTaxRate: data.propertyTaxes?.Property_Tax_Rate || "0%",
    maintenancePercent: 10,
    operatingExpensePercent: 10,
    appreciationRate: 3,
    holdingPeriodYears: 5,
    sellingCostsPercent: 6,
    hoafee: hoaValue
  });

  const baseData = {
    location: `${data.city}, ${data.state}`,
    propertyType: data.property_type || "Single Family Home",
    specs: `${data.bedrooms || 3} bed, ${data.bathrooms || 2} bath, ${(data.squareFeet || 0).toLocaleString()} sqft`,
    price: formatCurrency(data.price),
    ppsqft: data.squareFeet ? Math.round(data.price / data.squareFeet) : 'N/A'
  };

  const roleSpecificData = {
    investor: {
      ...baseData,
      monthlyMortgage: formatCurrency(returns.monthlyMortgage),
      monthlyExpenses: formatCurrency(returns.totalMonthlyExpenses),
      marketRent: formatCurrency(data.fmr),
      monthlyCashFlow: formatCurrency(returns.monthlyPreTaxCashFlow, true), // Changed this line
      capRate: ((returns.monthlyPreTaxCashFlow * 12) / data.price * 100).toFixed(2) + "%",
      potentialROI: ((returns.monthlyPreTaxCashFlow * 12) / data.price * 100).toFixed(2) + "%",
      cashOnCashReturn: ((returns.monthlyPreTaxCashFlow * 12) / (data.price * 0.25) * 100).toFixed(2) + "%",
      propertyTax: (data.propertyTaxes?.Property_Tax_Rate || 0) + "%",
      mortgageRate: (data.latestMortgageRates?.pmms30 || 0) + "%",
      vacancyRate: (data.rental_vacancy_rate || 6) + "%"
    },
    wholesaler: {
      ...baseData,
      arv: formatCurrency(data.price * 1.2),
      wholesaleFee: formatCurrency(data.price * 0.05),
      repairEstimate: formatCurrency(data.price * 0.1),
      equityPotential: formatCurrency(data.price * 0.2)
    },
    propertyManager: {
      ...baseData,
      monthlyRent: formatCurrency(data.fmr),
      vacancyRate: (data.rental_vacancy_rate || 6).toFixed(2) + "%",
      annualGrossRent: formatCurrency(data.fmr * 12),
      managementFee: formatCurrency(data.fmr * 0.1),
      occupancyRate: (100 - (data.rental_vacancy_rate || 6)).toFixed(2) + "%",
      hoa: formatCurrency(data.hoa || 0)
    },
    buyer: {
      ...baseData,
      monthlyPayment: formatCurrency(returns.monthlyMortgage),
      downPayment: formatCurrency(returns.downPayment),
      mortgageRate: (data.latestMortgageRates?.pmms30 || 0) + "%",
      propertyTax: (data.propertyTaxes?.Property_Tax_Rate || 0) + "%",
      hoa: formatCurrency(data.hoa || 0),
      futurePotentialValue: formatCurrency(data.price * (1 + 0.03) ** 5)
    }
  };

  return roleSpecificData[role];
};

  const promptTemplates = {
    investor: (data) => `Create viral social media posts for the following platforms about this real estate investment opportunity. Each post must be attention-grabbing and data-driven.
    
    PROPERTY DETAILS:
    ${data.propertyType} in ${data.location}
    ${data.specs}
    List Price: ${data.price} (${data.ppsqft}/sqft)
  
    KEY METRICS:
    • Monthly Mortgage: ${data.monthlyMortgage}
    • Monthly Cash Flow: ${data.cashFlow}
    • Market Rent: ${data.marketRent}
    • Vacancy Rate: ${data.vacancyRate}
    • Property Tax: ${data.propertyTax}
    • Current Rate: ${data.mortgageRate}
    • Potential ROI: ${data.potentialROI}
    
    1. FACEBOOK (max 250 chars):
    - Start with "🔥 INVESTMENT ALERT!"
    - Lead with most compelling numbers (ROI/cash flow)
    - Create FOMO with market stats
    - Add clear call-to-action
    - Use strategic emojis 💰🏠📈
    - End with #RealEstateInvesting #PassiveIncome
    
    2. TWITTER (max 280 chars):
    - Start with #DealAlert
    - Include key investment metrics
    - Use bullet points for readability
    - Create urgency
    - Add relevant hashtags #REI #Investing
    
    3. LINKEDIN:
    - Start with compelling investment thesis
    - Break down all numbers
    - Include market analysis
    - Discuss value-add opportunities
    - End with clear next steps
    - Professional hashtags
    
    4. REDDIT (r/realestateinvesting):
    - Detailed investment analysis
    - Show all calculations
    - Discuss risks and opportunities
    - Ask for community feedback
    - Include local market insights
    
    Style: Data-driven analysis with urgency
    Tone: Experienced investor sharing a hot deal
    Goal: Generate serious investment inquiries`,
  
    wholesaler: (data) => `Create viral social media posts for wholesale real estate opportunity. Each post must create urgency and target cash buyers.
  
    PROPERTY DETAILS:
    ${data.propertyType} in ${data.location}
    ${data.specs}
    
    DEAL METRICS:
    • Purchase Price: ${data.price}
    • ARV: ${data.arv}
    • Repair Estimate: ${data.repairEstimate}
    • Wholesale Fee: ${data.wholesaleFee}
    • Equity Potential: ${data.equityPotential}
    • Recent Comps: ${data.comps}
    
    1. FACEBOOK (max 250 chars):
    - Start with "🚨 OFF-MARKET DEAL ALERT"
    - Highlight the spread/profit potential
    - Emphasize below market value
    - Create extreme urgency
    - Clear call for cash buyers
    - Use emojis strategically 💰⚡️
    
    2. TWITTER (max 280 chars):
    - Lead with profit numbers
    - Show ARV vs Purchase
    - Create FOMO
    - Target cash buyers
    - #WholesaleRE #REDeals
    
    3. LINKEDIN:
    - Professional deal breakdown
    - Include market comps
    - Show renovation potential
    - Target serious investors
    - Include deal timeline
    - Professional analysis
    
    4. REDDIT (r/wholesaling):
    - Full deal analysis
    - Comps breakdown
    - Renovation scope
    - Exit strategy options
    - Seeking quick close
    
    Style: Direct and numbers-focused
    Tone: Deal finder with urgent opportunity
    Goal: Find cash buyer within 24-48 hours`,
  
    propertyManager: (data) => `Create viral social media posts targeting property owners and investors. Focus on professional management and ROI.
  
    PROPERTY DETAILS:
    ${data.propertyType} in ${data.location}
    ${data.specs}
    Monthly Rent: ${data.monthlyRent}
    
    MANAGEMENT METRICS:
    • Annual Gross Rent: ${data.annualGrossRent}
    • Occupancy Rate: ${data.occupancyRate}
    • Property Tax: ${data.propertyTax}
    • HOA Fee: ${formatCurrency(data.hoa || 0)}
    • Year Built: ${data.year_built || 'N/A'}
    
    1. FACEBOOK (max 250 chars):
    - Start with "🏢 MAXIMIZE YOUR RENTAL ROI"
    - Focus on income potential
    - Highlight management expertise
    - Show market performance
    - Clear value proposition
    - #PropertyManagement #PassiveIncome
    
    2. TWITTER (max 280 chars):
    - Lead with performance metrics
    - Share market insights
    - Focus on professional service
    - Target property owners
    - #RealEstate #PropertyManagement
    
    3. LINKEDIN:
    - Professional market analysis
    - Management track record
    - Property performance metrics
    - Service differentiators
    - Client testimonials
    - Target investors
    
    4. REDDIT (r/propertymanagement):
    - Detailed management strategy
    - Market analysis
    - Tenant screening process
    - Maintenance protocols
    - Professional discussion
    
    Style: Professional and data-driven
    Tone: Expert sharing valuable insights
    Goal: Attract property owners seeking professional management`,
  
    buyer: (data) => `Create viral social media posts for this home buying opportunity. Balance lifestyle appeal with investment potential.
  
    PROPERTY DETAILS:
    ${data.propertyType} in ${data.location}
    ${data.specs}
    List Price: ${data.price}
    
    BUYER INFO:
    • Monthly Payment: ${data.monthlyPayment}
    • Down Payment: ${data.downPayment}
    • Current Rate: ${data.mortgageRate}
    • Property Tax: ${data.propertyTax}
    • HOA: ${formatCurrency(data.hoa || 0)}
    • Year Built: ${data.year_built || 'N/A'}
    
    1. FACEBOOK (max 250 chars):
    - Start with "😍 YOUR DREAM HOME AWAITS"
    - Focus on lifestyle benefits
    - Highlight key features
    - Include virtual tour offer
    - Create emotional connection
    - End with clear CTA
    
    2. TWITTER (max 280 chars):
    - Showcase best features
    - Include price and specs
    - Mention location benefits
    - Create excitement
    - #DreamHome #RealEstate
    
    3. LINKEDIN:
    - Professional property overview
    - Investment potential
    - Market appreciation data
    - Location analysis
    - Compare to rentals
    - Target young professionals
    
    4. REDDIT (r/RealEstate):
    - Complete property breakdown
    - Market analysis
    - Financial details
    - Location highlights
    - Seek buyer feedback
    
    Style: Mix of emotional and practical
    Tone: Excited but informative
    Goal: Generate showing requests`
  }; 

  const generatePitch = async (role, data = propertyData) => {
    if (!data) return;

    setPitchError("");
    setLoading(true);

    try {
      const processedData = processDataForRole(role, data);
      const promptTemplate = promptTemplates[role](processedData);
      //console.log(promptTemplate);
      const response = await fetch(
        "https://ipmc-api.onrender.com/api/proxy/claude-completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "CLAUDE_API_KEY",
            "anthropic-version": "2023-06-01",
          },
          body: JSON.stringify({
            model: "claude-3-opus-20240229",
            max_tokens: 1000,
            temperature: 0,
            messages: [
              {
                role: "user",
                content: promptTemplate,
              },
            ],
          }),
        }
      );

    //const response = null;
      if (!response.ok) {
        throw new Error("Failed to generate pitch");
      }

      const result = await response.json();
      setGeneratedPitch(result.text);
      setGeneratedPitchParsed(parsePosts(result.text));
    } catch (err) {
      setPitchError(err.message);
      console.error("Pitch generation error:", err);
    } finally {
      setLoading(false);
    }
  };

  const MetricsDisplay = () => {
    if (!propertyData) return null;

    const data = processDataForRole(activeRole, propertyData);
    if (!data) return null;

    const metrics = {
      investor: [
        { label: "Monthly Cash Flow", value: data.monthlyCashFlow, icon: DollarSign },
        { label: "Potential ROI", value: data.potentialROI, icon: TrendingUp },
        { label: "Market Rent", value: data.marketRent, icon: PieChart },
        { label: "Vacancy Rate", value: data.vacancyRate, icon: Building },
      ],
      wholesaler: [
        { label: "ARV", value: data.arv, icon: DollarSign },
        {
          label: "Repair Estimate",
          value: data.repairEstimate,
          icon: TrendingUp,
        },
        { label: "Wholesale Fee", value: data.wholesaleFee, icon: PieChart },
        {
          label: "Equity Potential",
          value: data.equityPotential,
          icon: Building,
        },
      ],
      propertyManager: [
        { label: "Monthly Rent", value: data.monthlyRent, icon: DollarSign },
        {
          label: "Annual Gross",
          value: data.annualGrossRent,
          icon: TrendingUp,
        },
        { label: "Occupancy", value: data.occupancyRate, icon: PieChart },
        { label: "Management Fee", value: data.managementFee, icon: Building },
      ],
      buyer: [
        {
          label: "Monthly Payment",
          value: data.monthlyPayment,
          icon: DollarSign,
        },
        { label: "Down Payment", value: data.downPayment, icon: TrendingUp },
        { label: "Price/SqFt", value: `$${data.ppsqft}`, icon: PieChart },
        { label: "Property Tax", value: data.propertyTax, icon: Building },
      ],
    };

    return (
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mb-4">
        {metrics[activeRole].map(({ label, value, icon: Icon }) => (
          <Card
            key={label}
            className="overflow-hidden border border-gray-100 shadow-lg hover:shadow-xl transition-all duration-300"
          >
            <div
              style={{
                height: "4px",
                background: roleConfigs[activeRole].gradient,
              }}
            />
            <CardContent className="p-2">
              <div className="flex flex-col items-center justify-center space-y-3">
                <div
                  className="p-2 rounded-xl transform hover:scale-110 transition-all duration-300 m-1"
                  style={{ background: roleConfigs[activeRole].lightGradient }}
                >
                  <Icon
                    className="h-6 w-6"
                    style={{ color: roleConfigs[activeRole].color }}
                  />
                </div>
                <div className="text-sm font-medium text-gray-600 text-center">
                  {label}
                </div>
                <div
                  className="text-xl font-bold m-1"
                  style={{ color: roleConfigs[activeRole].color }}
                >
                  {value}
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    );
  };

  const handleRoleChange = async (role) => {
    setActiveRole(role);
  };

  if (loading && !propertyData) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (fetchError) {
    return (
      <Alert variant="destructive" className="m-6">
        <AlertCircle className="h-4 w-4" />
        <AlertDescription>{fetchError}</AlertDescription>
      </Alert>
    );
  }

  const parsePosts = (text) => {
    // Clean up the platform headers in the response
    const platformMatchers = {
      'FACEBOOK:': 'FACEBOOK',
      'TWITTER:': 'TWITTER', 
      'LINKEDIN:': 'LINKEDIN',
      'REDDIT:': 'REDDIT'
    };
  
    const websiteLink = `\n\nLearn more at cashflowrover.com ${id}`;
    
    const posts = {};
    let currentPlatform = null;
    let currentContent = [];
    
    text.split('\n').forEach(line => {
      // Check if line matches any platform header
      const platformMatch = Object.keys(platformMatchers).find(p => line.includes(p));
      
      if (platformMatch) {
        if (currentPlatform) {
          // Add website link before saving the content
          currentContent.push(websiteLink);
          posts[currentPlatform] = currentContent.join('\n');
          currentContent = [];
        }
        currentPlatform = platformMatchers[platformMatch];
      } else if (currentPlatform && line.trim()) {
        currentContent.push(line);
      }
    });
    
    if (currentPlatform) {
      // Add website link to the last platform's content
      currentContent.push(websiteLink);
      posts[currentPlatform] = currentContent.join('\n');
    }
    
    return posts;
  };

  const platformConfigs = {
    FACEBOOK: {
      icon: Copy,
      gradient: "linear-gradient(135deg, #1877f2 0%, #0ea5e9 100%)",
      lightGradient: "linear-gradient(135deg, #dbeafe 0%, #e0f2fe 100%)"
    },
    TWITTER: {
      icon: Copy,
      gradient: "linear-gradient(135deg, #1da1f2 0%, #0ea5e9 100%)",
      lightGradient: "linear-gradient(135deg, #dbeafe 0%, #e0f2fe 100%)"
    },
    LINKEDIN: {
      icon: Copy,
      gradient: "linear-gradient(135deg, #0077b5 0%, #0ea5e9 100%)",
      lightGradient: "linear-gradient(135deg, #dbeafe 0%, #e0f2fe 100%)"
    },
    REDDIT: {
      icon: Copy,
      gradient: "linear-gradient(135deg, #ff4500 0%, #ff6b81 100%)",
      lightGradient: "linear-gradient(135deg, #fff5f5 0%, #ffe0e3 100%)"
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-6 space-y-8">
      <Card className="overflow-hidden shadow-xl">
        <div
          style={{
            height: "4px",
            background: "linear-gradient(to right, #3b82f6, #8b5cf6, #ec4899)",
          }}
        />
        <CardHeader
          style={{
            background: "linear-gradient(135deg, #f8fafc 0%, #ffffff 100%)",
            borderBottom: "1px solid #e2e8f0",
          }}
        >
          <CardTitle className="flex items-center gap-3 text-xl font-bold text-gray-800">
            <Building2 className="h-6 w-6" style={{ color: "#2563eb" }} />
            Property Pitch Generator
          </CardTitle>
        </CardHeader>
        <CardContent className="p-4">
          {/* Property Details Section */}
          <div className="mb-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {/* Location Column */}
              <div>
                <h3 className="text-lg font-semibold mb-4 text-gray-800">Property Overview</h3>
                <div className="space-y-3">
                  <div className="flex items-center justify-between">
                    <span className="text-gray-600">Location:</span>
                    <span className="font-medium m-1">{propertyData?.city}, {propertyData?.state}</span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-gray-600">Property Type:</span>
                    <span className="font-medium  m-1">{propertyData?.property_type || 'N/A'}</span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-gray-600">Year Built:</span>
                    <span className="font-medium  m-1">{propertyData?.year_built || 'N/A'}</span>
                  </div>
                </div>
              </div>

              {/* Property Specs Column */}
              <div>
                <h3 className="text-lg font-semibold mb-4 text-gray-800">Property Specs</h3>
                <div className="space-y-3">
                  <div className="flex items-center justify-between">
                    <span className="text-gray-600">Bedrooms:</span>
                    <span className="font-medium  m-1">{propertyData?.bedrooms || '0'}</span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-gray-600">Bathrooms:</span>
                    <span className="font-medium  m-1">{propertyData?.bathrooms || '0'}</span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-gray-600">Square Feet:</span>
                    <span className="font-medium  m-1">{propertyData?.squareFeet?.toLocaleString() || 'N/A'}</span>
                  </div>
                </div>
              </div>

              {/* Financial Details Column */}
              <div>
                <h3 className="text-lg font-semibold mb-4 text-gray-800">Financial Details</h3>
                <div className="space-y-3">
                  <div className="flex items-center justify-between">
                    <span className="text-gray-600">List Price:</span>
                    <span className="font-medium m-1">{formatCurrency(propertyData?.price)}</span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-gray-600">Price/SqFt:</span>
                    <span className="font-medium m-1">${propertyData?.ppsqft || 'N/A'}</span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-gray-600">HOA:</span>
                    <span className="font-medium m-1">{formatCurrency(propertyData?.hoa || 0)}/month</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-4">
            {Object.entries({
              investor: { icon: UserCheck, label: "Investor" },
              wholesaler: { icon: Users, label: "Wholesaler" },
              propertyManager: { icon: Building, label: "Property Manager" },
              buyer: { icon: Home, label: "Buyer" },
            }).map(([role, { icon: Icon, label }]) => (
              <Card
                key={role}
                className={`cursor-pointer transition-all duration-300 transform hover:-translate-y-1 m-1
                  ${
                    activeRole === role
                      ? "ring-2 ring-offset-2 shadow-lg"
                      : "hover:shadow-md"
                  }`}
                style={
                  activeRole === role
                    ? {
                        ringColor: roleConfigs[role].color,
                        background: roleConfigs[role].lightGradient,
                      }
                    : {}
                }
                onClick={() => handleRoleChange(role)}
              >
                <div
                  style={{
                    height: "4px",
                    background: roleConfigs[role].gradient,
                  }}
                />
                <CardContent className="p-2">
                  <div className="flex flex-col items-center justify-center h-24 space-y-3">
                    <div
                      className="p-2 rounded-xl transition-all duration-300"
                      style={{
                        background:
                          activeRole === role
                            ? roleConfigs[role].lightGradient
                            : "#f8fafc",
                      }}
                    >
                      <Icon
                        className="h-4 w-4 m-1"
                        style={{
                          color:
                            activeRole === role
                              ? roleConfigs[role].color
                              : "#64748b",
                        }}
                      />
                    </div>
                    <span
                      className="font-semibold"
                      style={{
                        color:
                          activeRole === role
                            ? roleConfigs[role].color
                            : "#334155",
                      }}
                    >
                      {label}
                    </span>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
          

          <h4 className="mb-3">Deal Highlights</h4>
          <MetricsDisplay />
          <div className="flex justify-center mt-4">
            <Button
              onClick={handleGeneratePitch}
              disabled={generating || !propertyData}
              className="px-8 py-4 text-lg font-semibold text-white rounded-xl transition-all duration-300 transform hover:scale-105 hover:shadow-lg flex items-center gap-3"
              style={{
                background: roleConfigs[activeRole].gradient
              }}
            >
              {generating ? (
                <>
                  <Loader2 className="h-5 w-5 animate-spin" />
                  Generating...
                </>
              ) : (
                <>
                  <Wand2 className="h-5 w-5" />
                  Generate {activeRole.charAt(0).toUpperCase() + activeRole.slice(1)} Pitch
                </>
              )}
            </Button>
          </div>
        </CardContent>
      </Card>

      {generatedPitch && (
        <Card className="shadow-xl overflow-hidden">
          <div
            style={{
              height: "4px",
              background:
                "linear-gradient(to right, #3b82f6, #8b5cf6, #ec4899)",
            }}
          />
          <CardHeader
            style={{
              background: "linear-gradient(135deg, #f8fafc 0%, #ffffff 100%)",
              borderBottom: "1px solid #e2e8f0",
            }}
          >
            <CardTitle className="flex items-center justify-between">
              <span className="text-xl font-bold text-gray-800 m-4">
                Generated Pitch
              </span>
             
            </CardTitle>
          </CardHeader>          
        </Card>
      )}
       <div className="space-y-6">
      {Object.entries(generatedPitchParsed).map(([platform, content]) => {
        const PlatformIcon = platformConfigs[platform].icon;
        return (
          <Card key={platform} className="shadow-lg overflow-hidden">
            <div className="mt-4" style={{ height: "4px", background: platformConfigs[platform].gradient }} />
            <CardHeader 
              style={{
                background: platformConfigs[platform].lightGradient,
                borderBottom: "1px solid #e2e8f0"
              }}
              className="flex flex-row items-center justify-between"
            >
              <div className="flex items-center gap-2">
                <PlatformIcon className="h-5 w-5" 
                  style={{ color: platform === 'FACEBOOK' ? '#1877f2' : 
                          platform === 'TWITTER' ? '#1da1f2' : 
                          platform === 'LINKEDIN' ? '#0077b5' : '#ff4500' }} />
                <CardTitle className="text-lg font-bold text-gray-800 m-1">
                  {platform.charAt(0) + platform.slice(1).toLowerCase()}
                </CardTitle>
              </div>
              <button
                onClick={() => navigator.clipboard.writeText(content)}
                className="inline-flex items-center gap-2 px-3 py-1 rounded-lg text-white text-sm transition-all duration-300 hover:shadow-md"
                style={{ background: platformConfigs[platform].gradient }}
              >
                <Copy className="h-4 w-4 m-1" />
                Copy
              </button>
            </CardHeader>
            <CardContent className="p-6">
            <div style={{ whiteSpace: 'pre-line' }} className="text-gray-700 leading-relaxed">
                {content}
              </div>
            </CardContent>
          </Card>
        );
      })}
      <DisclaimerText />
    </div>
    </div>
  );
};

export default RealEstateElevatorPitch;
