import React, { useState, useEffect } from "react";
import http from "../http-common";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "./helpers/authcontext";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";
import { Slider } from 'primereact/slider';
import { Loader2 } from "lucide-react";


const Account = () => {
  const { user, setUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  // Existing state variables
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);

  // Original settings state variables
  const [incometaxratePreference, setincometaxratePreference] = useState("");
  const [downpaymentPreference, setdownpaymentPreference] = useState("");
  const [loanTermPreference, setloanTermPreference] = useState("");
  const [holdperiodPreference, setholdperiodPreference] = useState("");
  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState([]);

  // New settings state variables
  const [monthlyInvestmentBudget, setMonthlyInvestmentBudget] = useState("");
  const [maxPurchasePrice, setMaxPurchasePrice] = useState("");
  const [investmentStrategy, setInvestmentStrategy] = useState("");
  const [targetRoi, setTargetRoi] = useState("");
  const [preferredLocations, setPreferredLocations] = useState("");
  const [riskTolerance, setRiskTolerance] = useState("");
  const [maxLeverageRatio, setMaxLeverageRatio] = useState("");
  const [capRateThreshold, setCapRateThreshold] = useState("");
  const [cashOnCashThreshold, setCashOnCashThreshold] = useState("");
  const [priceDropAlertPercentage, setPriceDropAlertPercentage] = useState("");
  const [minBedrooms, setMinBedrooms] = useState("");
  const [minBathrooms, setMinBathrooms] = useState("");
  const [minSquareFeet, setMinSquareFeet] = useState("");
  const [propertyAge, setPropertyAge] = useState("");
  const [emailAlerts, setEmailAlerts] = useState(false);
  const [pushNotifications, setPushNotifications] = useState(false);
  const [alertFrequency, setAlertFrequency] = useState("");
  const [renovationPreference, setRenovationPreference] = useState("");
  const [propertyManagementPreference, setPropertyManagementPreference] = useState("");
  const [notes, setNotes] = useState("");
  const [primaryMarkets, setPrimaryMarkets] = useState([]);
  const [secondaryMarkets, setSecondaryMarkets] = useState([]);
  const [excludedMarkets, setExcludedMarkets] = useState([]);
  const [msaOptions, setMSAOptions] = useState([]);
  const [matchThreshold, setMatchThreshold] = useState(60); // Default to 60%


  
  // Existing useEffect modified to include new fields
  useEffect(() => {
    if (user) {
      setUsername(user.username || "");
      setName(user.name || "");
      setEmail(user.email || "");
      setPhone(user.phone || "");
      setAddress(user.address || "");

      const fetchUserSettings = async () => {
        try {
          const response = await http.get(`/user/${user.id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          const settings = response.data?.settings;
          // Existing setters
          setincometaxratePreference(settings.taxrate || "");
          setdownpaymentPreference(settings.downpayment || "");
          setloanTermPreference(settings.loanterm || "");
          setholdperiodPreference(settings.holdperiod || "");
          setSelectedPropertyTypes(settings.propertytypepreferences?.split(',') || []);

          // New setters
          setMonthlyInvestmentBudget(settings.monthlyinvestmentbudget || "");
          setMaxPurchasePrice(settings.maxpurchaseprice || "");
          setInvestmentStrategy(settings.investmentstrategy || "");
          setTargetRoi(settings.targetroi || "");
          setPreferredLocations(settings.preferredlocations || "");
          setRiskTolerance(settings.risktolerance || "");
          setMaxLeverageRatio(settings.maxleverageratio || "");
          setCapRateThreshold(settings.capratethreshold || "");
          setCashOnCashThreshold(settings.cashoncashthreshold || "");
          setPriceDropAlertPercentage(settings.pricedropalertpercentage || "");
          setMinBedrooms(settings.minbedrooms || "");
          setMinBathrooms(settings.minbathrooms || "");
          setMinSquareFeet(settings.minsquarefeet || "");
          setPropertyAge(settings.propertyage || "");
          setEmailAlerts(settings.emailalerts === "true");
          setPushNotifications(settings.pushnotifications === "true");
          setAlertFrequency(settings.alertfrequency || "");
          setRenovationPreference(settings.renovationpreference || "");
          setPropertyManagementPreference(settings.propertymanagementpreference || "");
          setNotes(settings.notes || "");
          setPrimaryMarkets(settings.primarymarkets ? JSON.parse(settings.primarymarkets) : []);
          setSecondaryMarkets(settings.secondarymarkets ? JSON.parse(settings.secondarymarkets) : []);
          setExcludedMarkets(settings.excludedmarkets ? JSON.parse(settings.excludedmarkets) : []);
          setMatchThreshold((settings.match_threshold * 100) || 60)
        } catch (error) {
          console.error("Error fetching user settings:", error);
        }
      };

      fetchUserSettings();
    }else {
      const savedSettings = JSON.parse(localStorage.getItem("userSettings"));
      if (savedSettings) {
        setincometaxratePreference(savedSettings.taxrate || "");
        setdownpaymentPreference(savedSettings.downpayment || "");
        setloanTermPreference(savedSettings.loanterm || "");
        setholdperiodPreference(savedSettings.holdperiod || "");
      }}
  }, [user]);

  useEffect(() => {
    const fetchMSAOptions = async () => {
      try {
        const response = await http.get('/lookup/msa-markets', {
          params: {
            limit: 1000,  // Adjust as needed
            sort: 'asc'
          }
        });
        setMSAOptions(response.data);
      } catch (error) {
        console.error('Error fetching MSA options:', error);
      }
    };

    fetchMSAOptions();
  }, []);

  const propertyTypeOptions = [
    { label: 'Single Family Home', value: '1' },
    { label: 'Condo', value: '2' },
    { label: 'Townhouse', value: '3' },
    { label: 'Multi-Family', value: '4' }
  ];

  const handleSubmit = async () => {
    try {
      const response = await http.put(
        "/user/profile",
        {
          username,
          password,
          name,
          email,
          phone,
          address,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const { token } = response.data;
      localStorage.setItem("token", token);
      const decodedToken = jwtDecode(token);
      setUser(decodedToken);
      console.log("Profile updated successfully");
    } catch (error) {
      console.error("Profile update failed:", error);
    }
  };

  const handlePropertyTypeChange = (e) => {
    setSelectedPropertyTypes(e.value); // Update property types array directly
  };

  const handleChangePassword = async () => {
    try {
      const response = await http.put(
        "/user/change-password",
        {
          password: newPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const { token } = response.data;
      localStorage.setItem("token", token);
      const decodedToken = jwtDecode(token);
      setUser(decodedToken);
      console.log("Password updated successfully");
      setShowPasswordDialog(false);
      setNewPassword("");
    } catch (error) {
      console.error("Password update failed:", error);
    }
  };

  // Updated handleSettingsSubmit
  const handleSettingsSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await http.put(
        "/user/settings",
        {
          userId: user.id,
          // Existing fields
          incometaxrate: incometaxratePreference,
          downpayment: downpaymentPreference,
          loanterm: loanTermPreference,
          holdperiod: holdperiodPreference,
          propertytypepreferences: selectedPropertyTypes.join(','),
          
          // New fields
          monthlyinvestmentbudget: monthlyInvestmentBudget,
          maxpurchaseprice: maxPurchasePrice,
          investmentstrategy: investmentStrategy,
          targetroi: targetRoi,
          preferredlocations: preferredLocations,
          risktolerance: riskTolerance,
          maxleverageratio: maxLeverageRatio,
          capratethreshold: capRateThreshold,
          cashoncashthreshold: cashOnCashThreshold,
          pricedropalertpercentage: priceDropAlertPercentage,
          minbedrooms: minBedrooms,
          minbathrooms: minBathrooms,
          minsquarefeet: minSquareFeet,
          propertyage: propertyAge,
          emailalerts: emailAlerts.toString(),
          pushnotifications: pushNotifications.toString(),
          alertfrequency: alertFrequency,
          renovationpreference: renovationPreference,
          propertymanagementpreference: propertyManagementPreference,
          notes: notes,
          primarymarkets: JSON.stringify(primaryMarkets),
          secondarymarkets: JSON.stringify(secondaryMarkets),
          excludedmarkets: JSON.stringify(excludedMarkets),
          match_threshold: (matchThreshold / 100)
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const settings = response.data?.settings;
      localStorage.setItem("settings", JSON.stringify(settings));             
      console.log("Settings updated successfully");
    } catch (error) {
      console.error("Settings update failed:", error);
    }
    setIsLoading(false);
  };

  // Options for dropdowns
  const investmentStrategyOptions = [
    { label: 'Buy and Hold', value: 'buy_and_hold' },
    { label: 'Fix and Flip', value: 'fix_and_flip' },
    { label: 'BRRRR', value: 'brrrr' },
    { label: 'Wholesale', value: 'wholesale' },
    { label: 'Value Add', value: 'value_add' }
  ];

  const riskToleranceOptions = [
    { label: 'Conservative', value: 'conservative' },
    { label: 'Moderate', value: 'moderate' },
    { label: 'Aggressive', value: 'aggressive' }
  ];

  const alertFrequencyOptions = [
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' }
  ];

  // Return statement with updated TabView
  return (
    <div className="flex align-items-center justify-content-center">
      <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6 mt-10">
        <TabView>
        <TabPanel header="Profile">
            <div className="text-center mb-5">
              <div className="text-900 text-3xl font-medium mb-3">
                Manage Your Profile
              </div>
            </div>

            <div>
              <label htmlFor="email" className="block text-900 font-medium mb-2">
                Email
              </label>
              <InputText
                id="email"
                type="text"
                placeholder="Email address"
                className="w-full mb-2"
                value={username}
                disabled
              />

              <div className="flex align-items-center mb-3">
                <div className="flex-grow-1 mr-2">
                  <label htmlFor="password" className="block text-900 font-medium mb-2">
                    Password
                  </label>
                  <InputText
                    id="password"
                    type="password"
                    placeholder="Password"
                    className="w-full mb-3"
                    value={password}
                    disabled
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <Button
                  label="Edit"
                  icon="pi pi-key"
                  className="p-button-secondary btn-chgpwd"
                  onClick={() => setShowPasswordDialog(true)}
                />
              </div>

              {/* <label htmlFor="phone" className="block text-900 font-medium mb-2">
                Phone Number
              </label>
              <InputText
                id="phone"
                type="text"
                placeholder="Phone Number"
                className="w-full mb-3"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />

              <label htmlFor="address" className="block text-900 font-medium mb-2">
                Address
              </label>
              <InputText
                id="address"
                type="text"
                placeholder="Address"
                className="w-full mb-3"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              /> */}

              <Button
                label="Save Profile"
                icon="pi pi-save"
                className="w-full"
                onClick={handleSubmit}
              />
            </div>
          </TabPanel>

          <TabPanel header="Settings">
            <div className="grid">
              {/* Existing settings fields */}
              <div className="col-12 md:col-6">
                <label htmlFor="incometaxratePreference" className="block text-900 font-medium mb-1">
                  Income Tax Rate
                </label>
                <InputNumber
                  id="incometaxratePreference"
                  value={incometaxratePreference}
                  onValueChange={(e) => setincometaxratePreference(e.value)}
                  suffix="%"
                  className="w-full"
                />
              </div>

              {/* Continue with existing fields... */}
              <div className="col-12 md:col-6">
                <label htmlFor="targetRoi" className="block text-900 font-medium mb-1">
                Target ROI (%)
                </label>
                <InputText
                  id="targetRoi"
                  type="text"
                  placeholder="Target ROI (%)"
                  className="w-full"
                  value={targetRoi}
                  onChange={(e) => setTargetRoi(e.target.value)}
                />
              </div>

              <div className="col-12 md:col-6">
                <label htmlFor="cashOnCashThreshold" className="block text-900 font-medium mb-1">
                Cash on Cash Threshold (%)
                </label>
                <InputText
                  id="cashOnCashThreshold"
                  type="text"
                  className="w-full"
                  value={cashOnCashThreshold}
                  onChange={(e) => setCashOnCashThreshold(e.target.value)}
                />
              </div>

              <div className="col-12 md:col-6">
                <label htmlFor="capRateThreshold" className="block text-900 font-medium mb-1">
                Cap Rate Threshold (%)
                </label>
                <InputText
                  id="capRateThreshold"
                  type="text"
                  className="w-full"
                  value={capRateThreshold}
                  onChange={(e) => setCapRateThreshold(e.target.value)}
                />
              </div>

              <div className="col-12 md:col-6">
                <label htmlFor="downpaymentPreference" className="block text-900 font-medium mb-1">
                  Down Payment %
                </label>
                <InputText
                  id="downpaymentPreference"
                  type="text"
                  placeholder="Down payment"
                  className="w-full"
                  value={downpaymentPreference}
                  onChange={(e) => setdownpaymentPreference(e.target.value)}
                />
              </div>

              <div className="col-12 md:col-6">
                <label htmlFor="loantermPreference" className="block text-900 font-medium mb-1">
                  Loan Term
                </label>
                <InputText
                  id="loantermPreference"
                  type="text"
                  placeholder="30"
                  className="w-full"
                  value={loanTermPreference}
                  onChange={(e) => setloanTermPreference(e.target.value)}
                />
              </div>

              <div className="col-12 md:col-6">
                <label htmlFor="holdperiodPreference" className="block text-900 font-medium mb-1">
                Holding Period
                </label>
                <InputText
                  id="holdperiodPreference"
                  type="text"
                  placeholder="30"
                  className="w-full"
                  value={holdperiodPreference}
                  onChange={(e) => setholdperiodPreference(e.target.value)}
                />
              </div>

              <div className="field col-12 md:col-6">
                <label htmlFor="holdperiodPreference" className="block text-900 font-medium mb-1">
                  Property Type
                  </label>
              <MultiSelect
                value={selectedPropertyTypes}
                options={propertyTypeOptions}
                onChange={handlePropertyTypeChange}
                placeholder="Select Property Types"
                className="w-full"
                display="chip" // Optional: displays selected items as chips
              />
              </div>

              {/* New Financial Settings */}
              <div className="col-12 md:col-6">
                <label htmlFor="monthlyInvestmentBudget" className="block text-900 font-medium mb-1">
                  Monthly Investment Budget
                </label>
                <InputNumber
                  id="monthlyInvestmentBudget"
                  value={monthlyInvestmentBudget}
                  onValueChange={(e) => setMonthlyInvestmentBudget(e.value)}
                  mode="currency"
                  currency="USD"
                  className="w-full"
                />
              </div>

              <div className="col-12 md:col-6">
                <label htmlFor="maxPurchasePrice" className="block text-900 font-medium mb-1">
                  Maximum Purchase Price
                </label>
                <InputNumber
                  id="maxPurchasePrice"
                  value={maxPurchasePrice}
                  onValueChange={(e) => setMaxPurchasePrice(e.value)}
                  mode="currency"
                  currency="USD"
                  className="w-full"
                />
              </div>

              {/* Investment Strategy */}
              <div className="col-12 md:col-6">
                <label htmlFor="investmentStrategy" className="block text-900 font-medium mb-1">
                  Investment Strategy
                </label>
                <Dropdown
                  id="investmentStrategy"
                  value={investmentStrategy}
                  options={investmentStrategyOptions}
                  onChange={(e) => setInvestmentStrategy(e.value)}
                  className="w-full"
                />
              </div>

              {/* Risk Profile */}
              <div className="col-12 md:col-6">
                <label htmlFor="riskTolerance" className="block text-900 font-medium mb-1">
                  Risk Tolerance
                </label>
                <Dropdown
                  id="riskTolerance"
                  value={riskTolerance}
                  options={riskToleranceOptions}
                  onChange={(e) => setRiskTolerance(e.value)}
                  className="w-full"
                />
              </div>

              {/* Alert Settings */}
              <div className="col-12 md:col-6">
                <label htmlFor="alertFrequency" className="block text-900 font-medium mb-1">
                  Alert Frequency
                </label>
                <Dropdown
                  id="alertFrequency"
                  value={alertFrequency}
                  options={alertFrequencyOptions}
                  onChange={(e) => setAlertFrequency(e.value)}
                  className="w-full"
                />
              </div>

              {/* Notification Preferences */}
              <div className="col-12 md:col-6">
                <div className="flex align-items-center justify-content-between">
                  <label className="text-900 font-medium">Email Alerts</label>
                  <InputSwitch
                    checked={emailAlerts}
                    onChange={(e) => setEmailAlerts(e.value)}
                  />
                  
                </div>
                {/* <div className="flex align-items-center justify-content-between">
                  <label className="text-900 font-medium">Push Notifications</label>
                  <InputSwitch
                    checked={pushNotifications}
                    onChange={(e) => setPushNotifications(e.value)}
                  />
                </div> */}
              </div>

              <div className="field col-12">
                <label htmlFor="matchThreshold" className="block text-900 font-medium mb-2">
                    Match Threshold ({matchThreshold}%)
                </label>
                <div className="flex align-items-center gap-2">
                    <Slider 
                        id="matchThreshold"
                        value={matchThreshold} 
                        onChange={(e) => setMatchThreshold(e.value)} 
                        className="w-full" 
                        min={0} 
                        max={100}
                        step={5}
                    />
                </div>
                <small className="text-600">Only show properties that match {matchThreshold}% or more of your criteria</small>
            </div>


              {/* Market Preferences */}
              <div className="col-12">
                <label htmlFor="primaryMarkets" className="block text-900 font-medium mb-1">
                  Primary Markets
                </label>
                <MultiSelect
                  value={primaryMarkets}
                  options={msaOptions}
                  onChange={(e) => setPrimaryMarkets(e.value)}
                  placeholder="Select Markets"
                  className="w-full"
                  display="chip"
                  filter
                  filterPlaceholder="Search markets..."
                  emptyFilterMessage="No markets found"
                  maxSelectedLabels={3}
                  virtualScrollerOptions={{ itemSize: 43 }}
                  panelClassName="custom-multiselect-panel" // For custom styling
                  filterMatchMode="contains" // 'contains', 'startsWith', 'endsWith'
                  scrollHeight="400px" // Control dropdown height
                />

              </div>

              {/* Additional Notes */}
              <div className="col-12">
                <label htmlFor="notes" className="block text-900 font-medium mb-1">
                  Additional Notes
                </label>
                <InputTextarea
                  id="notes"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  rows={3}
                  className="w-full"
                />
              </div>

              {/* Save Button */}
              <div className="col-12">
                <Button
                  label="Save Settings"
                  icon="pi pi-save"
                  className="w-full"
                  onClick={handleSettingsSubmit}
                  disabled={isLoading}
                >
                   {isLoading && (
        <Loader2 className="w-5 h-5 animate-spin" />
      )}
                </Button>
              </div>
            </div>
          </TabPanel>
        </TabView>

        <Dialog
        header="Change Password"
        visible={showPasswordDialog}
        style={{ width: "50vw" }}
        onHide={() => setShowPasswordDialog(false)}
      >
        <div>
          <label htmlFor="newPassword" className="block text-900 font-medium mb-2">
            New Password
          </label>
          <InputText
            id="newPassword"
            type="password"
            placeholder="New Password"
            className="w-full mb-3"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <Button
            label="Update Password"
            icon="pi pi-save"
            className="w-full"
            onClick={handleChangePassword}
          />
        </div>
      </Dialog>
      </div>

      {/* Password Dialog remains the same */}
    </div>
  );
};

export default Account;