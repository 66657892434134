import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';

const FiltersHeader = ({
  city,
  handleCityChange,
  isFetchingCities,
  showSuggestions,
  citySuggestions,
  handleSuggestionClick,
  state,
  setState,
  zipcode,
  setZipCode,
  status,
  setStatus,
  statusList,
  selectedPropertyTypes,
  handlePropertyTypeChange,
  propertyTypeOptions,
  filter,
  setFilters,
  filtersList,
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
  minPpsqft,
  setMinPpsqft,
  maxPpsqft,
  setMaxPpsqft,
  fetchData,
}) => {
  return (
    <Accordion>
      <AccordionTab header="filters">
        <div className="formgrid grid">
          {/* Location Group */}
          <div className="col-12 md:col-6 lg:col-3 space-y-3">
            <div className="text-sm font-medium mb-2 text-gray-600">
              Location
            </div>

            {/* City with Autosuggest */}
            <div className="relative">
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">
                  <i className={PrimeIcons.MAP_MARKER}></i>
                </span>
                <span className="p-input-icon-right w-full">
                  {isFetchingCities && <i className="pi pi-spin pi-spinner" />}
                  <InputText
                    placeholder="City"
                    value={city}
                    onChange={handleCityChange}
                    className="w-full"
                  />
                </span>
              </div>
              {showSuggestions && citySuggestions.length > 0 && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    right: 0,
                    zIndex: 9999,
                    backgroundColor: "white",
                    borderRadius: "4px",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                    border: "1px solid #ddd",
                  }}
                >
                  {citySuggestions.map((suggestion, index) => (
                    <div
                      key={index}
                      style={{
                        padding: "8px 12px",
                        cursor: "pointer",
                        borderBottom:
                          index !== citySuggestions.length - 1
                            ? "1px solid #eee"
                            : "none",
                      }}
                      onMouseDown={(e) => {
                        e.preventDefault();
                        handleSuggestionClick(suggestion);
                      }}
                      className="hover:bg-gray-100"
                    >
                      {suggestion.display}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* State */}
            <div className="p-inputgroup mt-2">
              <span className="p-inputgroup-addon">
                <i className={PrimeIcons.GLOBE}></i>
              </span>
              <InputText
                placeholder="State"
                value={state}
                onChange={(e) => setState(e.target.value)}
                className="w-full"
              />
            </div>

            {/* Zipcode */}
            <div className="p-inputgroup mt-2">
              <span className="p-inputgroup-addon">
                <i className={PrimeIcons.ENVELOPE}></i>
              </span>
              <InputText
                placeholder="ZipCode"
                value={zipcode}
                onChange={(e) => setZipCode(e.target.value)}
                className="w-full"
                type="number"
              />
            </div>
          </div>

          {/* Status and Type Group */}
          <div className="col-12 md:col-6 lg:col-3 space-y-3">
            <div className="text-sm font-medium mb-2 text-gray-600">
              Property Details
            </div>

            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className={PrimeIcons.INFO_CIRCLE}></i>
              </span>
              <Dropdown
                value={status}
                options={statusList}
                onChange={(e) => setStatus(e.value)}
                placeholder="Select a Status"
                className="w-full"
              />
            </div>

            <div className="p-inputgroup mt-2">
              <span className="p-inputgroup-addon">
                <i className={PrimeIcons.BUILDING}></i>
              </span>
              <MultiSelect
                value={selectedPropertyTypes}
                options={propertyTypeOptions}
                onChange={handlePropertyTypeChange}
                placeholder="Select Property Types"
                className="w-full"
                display="chip"
              />
            </div>

            <div className="p-inputgroup mt-2">
              <span className="p-inputgroup-addon">
                <i className={PrimeIcons.INFO_CIRCLE}></i>
              </span>
              <Dropdown
                value={filter}
                options={filtersList}
                onChange={(e) => setFilters(e.value)}
                placeholder="Select a Filter"
                className="w-full"
              />
            </div>

          </div>

          {/* Price Group */}
          <div className="col-12 md:col-6 lg:col-3 space-y-3">
            <div className="text-sm font-medium mb-2 text-gray-600">
              Price Range
            </div>

            <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
            <i className={PrimeIcons.MONEY_BILL}></i>
          </span>
              
              <InputText
                placeholder="Min Price"
                value={minPrice}
                onChange={(e) => setMinPrice(e.target.value)}
                className="w-full"
                type="number"
              />
            </div>

            <div className="p-inputgroup mt-2">
            <span className="p-inputgroup-addon">
            <i className={PrimeIcons.MONEY_BILL}></i>
          </span>
            <InputText
                placeholder="Max Price"
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
                className="w-full"
                type="number"
              />
            </div>
          </div>

          {/* Price per SqFt Group */}
          <div className="col-12 md:col-6 lg:col-3 space-y-3">
            <div className="text-sm font-medium mb-2 text-gray-600">
              Price per SqFt
            </div>

            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className={PrimeIcons.CALCULATOR}></i>
              </span>
              <InputText
                placeholder="Min $/SqFt"
                value={minPpsqft}
                onChange={(e) => setMinPpsqft(e.target.value)}
                className="w-full"
                type="number"
              />
            </div>

            <div className="p-inputgroup mt-2">
              <span className="p-inputgroup-addon">
                <i className={PrimeIcons.CALCULATOR}></i>
              </span>
              <InputText
                placeholder="Max $/SqFt"
                value={maxPpsqft}
                onChange={(e) => setMaxPpsqft(e.target.value)}
                className="w-full"
                type="number"
              />
            </div>
          </div>

          {/* Apply Button */}
          <div className="col-12 flex justify-end mt-4">
            <Button
              label="Apply"
              onClick={fetchData}
              className="p-button-primary"
            />
          </div>
        </div>
      </AccordionTab>
    </Accordion>
  );
};

export default FiltersHeader;