import React from "react";

const KeyMetrics = ({ calculations = {} }) => {
  return (
    <div className="surface-card p-4 border-round shadow-1">
      <div className="flex justify-content-between align-items-center mb-4">
        <h2 className="text-xl m-0 font-medium">Key Performance Indicators</h2>
      </div>

      <div className="grid">
        <div className="col-12 md:col-4 p-2">
          <div className="surface-200 p-3 border-round bg-purple-50">
            <span className="text-600 text-sm block mb-2">Cap Rate</span>
            <span className="text-900 text-xl">
              {(calculations.capRate || 0).toFixed(2)}%
            </span>
          </div>
        </div>

        <div className="col-12 md:col-4 p-2">
          <div className="surface-200 p-3 border-round bg-blue-50">
            <span className="text-600 text-sm block mb-2">
              Cash on Cash Return
            </span>
            <span className="text-900 text-xl">
              {(calculations.cashOnCashReturn || 0).toFixed(2)}%
            </span>
          </div>
        </div>

        <div className="col-12 md:col-4 p-2">
          <div className="surface-200 p-3 border-round bg-gray-50">
            <span className="text-600 text-sm block mb-2">
              Monthly Mortgage Payment
            </span>
            <span className="text-900 text-xl">
              ${(calculations.monthlyMortgage || 0).toFixed(2)}
            </span>
          </div>
        </div>
      </div>

      {/* Optional Disclaimer */}
      <div className="text-center mt-2">
        <span className="text-500 text-xs">
          * Based on current market rates and property valuation
        </span>
      </div>
    </div>
  );
};

export default KeyMetrics;
