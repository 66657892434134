import React, { useState } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const InvestorPDFReport = ({ propertyDetails, calculations, financialParams, projections, demographicsData }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);

  // Safe formatting helpers with null checks
  const formatCurrency = (value) => {
    if (value == null) return 'N/A';
    return `$${value.toLocaleString()}`;
  };

  const formatPercent = (value) => {
    if (value == null) return 'N/A';
    return `${value.toFixed(2)}%`;
  };

  const formatNumber = (value) => {
    if (value == null) return 'N/A';
    return value.toFixed(2);
  };

  const addTableOfContents = (doc, outlineItems) => {
    // Title
    doc.setFontSize(16);
    doc.setTextColor(0, 67, 124);
    doc.setFont(undefined, 'bold');
    doc.text('Table of Contents', 20, 30);
    
    // Reset styles for content
    doc.setFont(undefined, 'normal');
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(10);
  
    let startY = 45;
    const lineHeight = 12;
    const pageWidth = doc.internal.pageSize.width;
    const rightMargin = 20;
    const pageNumberX = pageWidth - rightMargin;
    
    outlineItems.forEach((item) => {
      // Draw the title
      doc.setTextColor(0, 67, 124);
      doc.text(item.title, 20 + (item.level * 10), startY);
      
      // Get the width of the title
      const titleWidth = doc.getTextWidth(item.title);
      
      // Get the width of the page number
      const pageNumberWidth = doc.getTextWidth(item.page.toString());
      
      // Calculate space available for dots
      const startDots = 20 + titleWidth + 5;
      const endDots = pageNumberX - pageNumberWidth - 5;
      const dotsWidth = endDots - startDots;
      
      // Calculate number of dots needed
      const dotWidth = doc.getTextWidth('.');
      const numberOfDots = Math.floor(dotsWidth / dotWidth);
      const dots = '.'.repeat(numberOfDots);
      
      // Draw dots
      doc.setTextColor(150, 150, 150);
      doc.text(dots, startDots, startY);
      
      // Draw page number
      doc.setTextColor(0, 67, 124);
      doc.text(item.page.toString(), pageNumberX, startY, { align: 'right' });
      
      // Create clickable area
      doc.link(20, startY - 5, pageWidth - 40, 8, { pageNumber: item.page });
      
      startY += lineHeight;
    });
  };

  const getFinancialAnalysisData = (calculations) => {
    if (!calculations) return [];
    
    // Income Calculations
    const annualGrossIncome = propertyDetails.monthlyRent * 12;              // $2,361 × 12 = $28,332
    const annualVacancyLoss = calculations.monthlyVacancy * 12;              // $111.07 × 12 = $1,332.84
    const effectiveGrossIncome = annualGrossIncome - annualVacancyLoss;      // $28,332 - $1,332.84 = $26,999.16
    
    // Operating Expense Calculations (excluding vacancy loss)
    const annualOperatingExpenses = (
        calculations.monthlyTaxes +
        calculations.monthlyInsurance +
        calculations.monthlyMaintenance +
        calculations.monthlyManagement +
        calculations.monthlyHOA +
        calculations.monthlyUtilities +
        calculations.monthlyOther
    ) * 12;                                                                  // $512.90 × 12 = $6,154.80
    
    const netOperatingIncome = effectiveGrossIncome - annualOperatingExpenses; // $26,999.16 - $6,154.80 = $20,844.36
    
    // Cash Flow Calculations
    const annualDebtService = calculations.monthlyMortgage * 12;             // $801.59 × 12 = $9,619.07
    const annualCashFlow = netOperatingIncome - annualDebtService;          // $20,844.36 - $9,619.07 = $11,225.29

    return [
        // Income Section
        ['1. Income Analysis', ''],
        ['    Annual Gross Income', formatCurrency(annualGrossIncome)],
        ['    Less: Vacancy Loss', `(${formatCurrency(annualVacancyLoss)})`],
        ['    Effective Gross Income', formatCurrency(effectiveGrossIncome)],
        ['', ''],
        
        // Operating Section
        ['2. Operating Analysis', ''],
        ['    Effective Gross Income', formatCurrency(effectiveGrossIncome)],
        ['    Less: Operating Expenses', `(${formatCurrency(annualOperatingExpenses)})`],
        ['    Net Operating Income (NOI)', formatCurrency(netOperatingIncome)],
        ['', ''],
        
        // Cash Flow Section
        ['3. Cash Flow Analysis', ''],
        ['    Net Operating Income', formatCurrency(netOperatingIncome)],
        ['    Less: Annual Debt Service', `(${formatCurrency(annualDebtService)})`],
        ['    Annual Cash Flow', formatCurrency(annualCashFlow)]
    ];
};

const getOperatingExpensesBreakdown = (calculations) => {
    if (!calculations) return [];

    const annualPropertyTaxes = calculations.monthlyTaxes * 12;           // $96.25 × 12 = $1,155
    const annualInsurance = calculations.monthlyInsurance * 12;           // $62.50 × 12 = $750
    const annualMaintenance = calculations.monthlyMaintenance * 12;       // $118.05 × 12 = $1,416.60
    const annualManagement = calculations.monthlyManagement * 12;         // $236.10 × 12 = $2,833.20
    const annualHOA = calculations.monthlyHOA * 12;                      // $0
    const annualUtilities = calculations.monthlyUtilities * 12;          // $0
    const annualOther = calculations.monthlyOther * 12;                  // $0

    const totalOperatingExpenses = (
        annualPropertyTaxes +
        annualInsurance +
        annualMaintenance +
        annualManagement +
        annualHOA +
        annualUtilities +
        annualOther
    );   // Total = $6,154.80

    return [
        ['Annual Operating Expenses Breakdown', ''],
        ['    Property Taxes', formatCurrency(annualPropertyTaxes)],
        ['    Insurance', formatCurrency(annualInsurance)],
        ['    Maintenance', formatCurrency(annualMaintenance)],
        ['    Property Management', formatCurrency(annualManagement)],
        ['    HOA Fees', formatCurrency(annualHOA)],
        ['    Utilities', formatCurrency(annualUtilities)],
        ['    Other Expenses', formatCurrency(annualOther)],
        ['', ''],
        ['Total Operating Expenses', formatCurrency(totalOperatingExpenses)]
    ];
};

const getInvestmentReturnsData = (calculations) => {
  if (!calculations) return [];

  return [
    ['Down Payment', formatCurrency(calculations.downPayment)],
    ['Closing Costs', formatCurrency(calculations.closingCosts)],
    ['Initial Repairs', formatCurrency(calculations.initialRepairs)],
    ['Total Investment', formatCurrency(calculations.totalInitialInvestment)],
    ['Cash on Cash Return', formatPercent(calculations.cashOnCashReturn)],
    ['Cap Rate', formatPercent(calculations.capRate)],
    ['DSCR', calculations.dscr.toFixed(2)]
  ];
};

  const formatProjectionsData = (projections) => {
    if (!projections?.yearlyData) return [];
    
    // Get first 5 years of data
    const fiveYearData = projections.yearlyData.slice(0, 5);
    
    return fiveYearData.map(year => [
      `Year ${year.year}`,
      formatCurrency(year.yearlyRent),
      formatCurrency(year.yearlyBaseExpenses + year.yearlyFixedExpenses + year.capExReserve),
      formatCurrency(year.yearlyNOI),
      formatCurrency(year.yearlyCashFlow),
      formatPercent(year.yearlyCoCReturn)
    ]);
  };
  
  const getProjectionsSummary = (projections) => {
    if (!projections?.summaryMetrics) return [];
    
    const {
      initialInvestment,
      finalEquity,
      averageCapRate,
      averageCoCReturn,
      averageDSCR
    } = projections.summaryMetrics;
  
    return [
      ['Initial Investment', formatCurrency(initialInvestment)],
      ['Total Appreciation', formatCurrency(projections.totalAppreciation)],
      ['Total Cash Flow', formatCurrency(projections.totalCashFlow)],
      ['IRR', formatPercent(projections.irr)],
      ['Average Annual Return', formatCurrency(projections.averageAnnualReturn)],
      ['Total ROI', formatPercent(projections.totalROI)],
      ['Average Cap Rate', formatPercent(averageCapRate)],
      ['Average Cash on Cash', formatPercent(averageCoCReturn)],
      ['Average DSCR', averageDSCR.toFixed(2)]
    ];
  };

  const generatePDF = async () => {

    const outlines = [];

    setIsGenerating(true);
    setError(null);
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    
    // Helper functions
    const centerText = (text, y) => {
      const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const x = (pageWidth - textWidth) / 2;
      doc.text(text, x, y);
    };

    const addSection = (title, y, includeBar = true) => {
      // Add colored bar
      if (includeBar) {
        doc.setFillColor(0, 67, 124);
        doc.rect(20, y - 5, pageWidth - 40, 0.5, 'F');
      }

      // Add section title
      doc.setFontSize(16);
      doc.setTextColor(0, 67, 124); // Dark blue for headers
      doc.setFont(undefined, 'bold');
      doc.text(title, 20, y + 5);
      doc.setFont(undefined, 'normal');
      doc.setTextColor(0, 0, 0); // Reset to black
      doc.setFontSize(10);
      return y + 15;
    };

    const addPageHeader = () => {
      doc.setFillColor(0, 67, 124);
      doc.rect(0, 0, pageWidth, 15, 'F');
      doc.setTextColor(255, 255, 255);
      doc.setFontSize(10);
      doc.text('Investment Property Analysis', 20, 10);
      doc.text(`Generated: ${new Date().toLocaleDateString()}`, pageWidth - 60, 10);
      doc.setTextColor(0, 0, 0);
    };

    try {
      // Cover Page
      addPageHeader();
      let startY = 40;

      // Title
      doc.setFontSize(26);
      doc.setTextColor(0, 67, 124);
      centerText('Investment Property', startY);
      doc.setFontSize(24);
      centerText('Analysis Report', startY + 12);
      startY += 35;

      // Property Details Box
      doc.setDrawColor(0, 67, 124);
      doc.setLineWidth(0.5);
      doc.rect(30, startY, pageWidth - 60, 40);
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      const address = propertyDetails?.address || 'Address not provided';
      doc.text(address, pageWidth / 2, startY + 15, { align: 'center' });
      doc.setFontSize(11);
      doc.text([
        `${propertyDetails?.bedsBaths || 'N/A'} • ${propertyDetails?.squareFeet || 'N/A'} sqft`,
        `Year Built: ${propertyDetails?.yearBuilt || 'N/A'}`
      ], pageWidth / 2, startY + 30, { align: 'center' });
      startY += 60;

      // Key Metrics Summary
      const metricsData = [
        {
          title: 'Purchase Price',
          value: formatCurrency(propertyDetails?.purchasePrice),
          color: '#004B8D'
        },
        {
          title: 'Monthly Rent',
          value: formatCurrency(propertyDetails?.monthlyRent),
          color: '#006BB6'
        },
        {
          title: 'Cap Rate',
          value: formatPercent(calculations?.capRate),
          color: '#0087E0'
        },
        {
          title: 'Cash on Cash',
          value: formatPercent(calculations?.cashOnCashReturn),
          color: '#00A3FF'
        }
      ];

      doc.setFontSize(14);
      metricsData.forEach((metric, index) => {
        const x = 20 + (index * ((pageWidth - 40) / 4));
        const width = (pageWidth - 40) / 4 - 10;
        
        // Draw box
        doc.setDrawColor(metric.color);
        doc.setFillColor(metric.color);
        doc.rect(x, startY, width, 40, 'F');
        
        // Add text
        doc.setTextColor(255, 255, 255);
        doc.setFontSize(12);
        doc.text(metric.title, x + width/2, startY + 15, { align: 'center' });
        doc.setFontSize(14);
        doc.text(metric.value, x + width/2, startY + 30, { align: 'center' });
      });
      
      startY += 60;

      doc.addPage();
      addPageHeader();
      const tocPage = doc.internal.getCurrentPageInfo().pageNumber;

      // Financial Analysis Page
      doc.addPage();
      addPageHeader();
      startY = 30;

      outlines.push({ 
        title: 'Financial Analysis', 
        page: doc.internal.getCurrentPageInfo().pageNumber,
        level: 0 
      });

      // Financial Analysis
      startY = addSection('Financial Analysis', startY);
      const financialAnalysisData = getFinancialAnalysisData(calculations);

      doc.autoTable({
        body: financialAnalysisData,
        startY: startY,
        theme: 'plain',
        styles: {
          cellPadding: 5,
          fontSize: 10,
          lineWidth: 0.1
        },
        columnStyles: {
          0: { 
            fontStyle: 'bold',
            textColor: [0, 67, 124]
          },
          1: { 
            halign: 'right'
          }
        },
        alternateRowStyles: {
          fillColor: [245, 247, 250]
        }
      });

      outlines.push({ 
        title: 'Operating Expenses Breakdown', 
        page: doc.internal.getCurrentPageInfo().pageNumber,
        level: 0 
      });
      startY = doc.autoTable.previous.finalY + 60;
      startY = addSection('Operating Expenses Breakdown', startY);
      const expensesData = getOperatingExpensesBreakdown(calculations);
      
      doc.autoTable({
        body: expensesData,
        startY: startY,
        theme: 'plain',
        styles: {
          cellPadding: 5,
          fontSize: 10,
          lineWidth: 0.1
        },
        columnStyles: {
          0: { 
            fontStyle: 'bold',
            textColor: [0, 67, 124]
          },
          1: { 
            halign: 'right'
          }
        },
        alternateRowStyles: {
          fillColor: [245, 247, 250]
        }
      });

      outlines.push({ 
        title: 'Investment Returns', 
        page: doc.internal.getCurrentPageInfo().pageNumber,
        level: 0 
      });

      // Investment Returns
      startY = doc.autoTable.previous.finalY + 25;
      startY = addSection('Investment Returns', startY);
      const returnsData = getInvestmentReturnsData(calculations);

      doc.autoTable({
        body: returnsData,
        startY: startY,
        theme: 'plain',
        styles: {
          cellPadding: 5,
          fontSize: 10,
          textColor: [40, 40, 40]
        },
        columnStyles: {
          0: { 
            fontStyle: 'bold',
            textColor: [0, 67, 124]
          },
          1: { 
            halign: 'right'
          }
        },
        alternateRowStyles: {
          fillColor: [245, 247, 250]
        }
      });

      // Market Analysis
      if (demographicsData) {
        outlines.push({ 
          title: 'Market Analysis', 
          page: doc.internal.getCurrentPageInfo().pageNumber,
          level: 0 
        });
        startY = doc.autoTable.previous.finalY + 25;
        startY = addSection('Market Analysis', startY);
        const marketData = [
          ['Median Home Value', formatCurrency(demographicsData?.demographics?.medianHomeValue)],
          ['Median rent', formatCurrency(demographicsData?.demographics?.medianRent)],
          ['Median Income', formatCurrency(demographicsData?.demographics?.medianIncome)],
          ['Population', formatNumber(demographicsData?.demographics?.population)]
        ];

        doc.autoTable({
          body: marketData,
          startY: startY,
          theme: 'plain',
          styles: {
            cellPadding: 5,
            fontSize: 10,
            textColor: [40, 40, 40]
          },
          columnStyles: {
            0: { fontStyle: 'bold', textColor: [0, 67, 124] },
            1: { halign: 'right' }
          },
          alternateRowStyles: {
            fillColor: [245, 247, 250]
          }
        });
      }

      // Projections Section
    if (projections?.yearlyData) {
      doc.addPage();
      addPageHeader();
      startY = 30;

      outlines.push({ 
        title: '5-Year Financial Projections', 
        page: doc.internal.getCurrentPageInfo().pageNumber,
        level: 0 
      });

      // 5-Year Projections
      startY = addSection('5-Year Financial Projections', startY);
      
      const projectionHeaders = [
        ['Year', 'Gross Income', 'Operating Expenses', 'NOI', 'Cash Flow', 'ROI']
      ];
      const projectionRows = formatProjectionsData(projections);

      doc.autoTable({
        head: projectionHeaders,
        body: projectionRows,
        startY: startY,
        theme: 'plain',
        styles: {
          cellPadding: 5,
          fontSize: 10,
          textColor: [40, 40, 40]
        },
        headStyles: {
          fillColor: [0, 67, 124],
          textColor: [255, 255, 255],
          fontStyle: 'bold'
        },
        alternateRowStyles: {
          fillColor: [245, 247, 250]
        }
      });

      

      // Add notes about projections
      startY = doc.autoTable.previous.finalY + 15;
      doc.setFontSize(9);
      doc.setTextColor(100, 100, 100);
      doc.text([
        'Notes:',
        '• Property value appreciation rate: ' + formatPercent(financialParams.appreciationRate),
        '• Rent growth rate: ' + formatPercent(financialParams.rentGrowthRate),
        '• Expense growth rate: ' + formatPercent(financialParams.expenseGrowthRate),
        '• Capital expenditure reserve: 0.5% of property value per year',
        '• Projections assume consistent market conditions and occupancy rates',
        '• Actual performance may vary based on market conditions and property management'
      ], 20, startY);
    }

    doc.setPage(tocPage);
      addTableOfContents(doc, outlines);

      doc.save('Investment_Property_Analysis.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
      setError('Failed to generate PDF. Please try again.');
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <button
        onClick={generatePDF}
        disabled={isGenerating}
        className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center gap-2"
      >
        {isGenerating ? (
          <>
            <span>Generating PDF...</span>
            <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-white"></div>
          </>
        ) : (
          'Investment Report'
        )}
      </button>
      {error && (
        <div className="text-red-600 text-sm">{error}</div>
      )}
    </div>
  );
};

export default InvestorPDFReport;