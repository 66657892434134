const DemographicsData = ({ demographicsData = {} }) => {
  const { demographics = {} } = demographicsData || {};

  return (
    <>
      {demographics && (
        <div className="surface-card p-4 border-round shadow-1">
          <div className="flex justify-content-between align-items-center mb-4">
            <h2 className="text-xl m-0 font-medium">
              Neighborhood Demographics
            </h2>
            <div className="text-gray-700 font-medium">
              <span className="text-sm">Census Data</span>
            </div>
          </div>

          <div className="grid">
            {/* Left Column */}
            <div className="col-md-6">
              <div className="surface-100 p-3 border-round mb-3">
                <div className="flex justify-content-between align-items-center">
                  <span className="text-gray-700">Population</span>
                  <span className="text-900 font-medium">
                    {demographics.population?.toLocaleString() || "N/A"}
                  </span>
                </div>
              </div>

              <div className="surface-100 p-3 border-round mb-3">
                <div className="flex justify-content-between align-items-center">
                  <span className="text-gray-700">Median Income</span>
                  <span className="text-900 font-medium">
                    ${demographics.medianIncome?.toLocaleString() || "N/A"}
                  </span>
                </div>
              </div>

              <div className="surface-100 p-3 border-round">
                <div className="flex justify-content-between align-items-center">
                  <span className="text-gray-700">Median Home Value</span>
                  <span className="text-900 font-medium">
                    ${demographics.medianHomeValue?.toLocaleString() || "N/A"}
                  </span>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-md-6 pl-3 pt-3">
              <div className="surface-100 p-3 border-round mb-3">
                <div className="flex justify-content-between align-items-center">
                  <span className="text-gray-700">Median Rent</span>
                  <span className="text-900 font-medium">
                    ${demographics.medianRent?.toLocaleString() || "N/A"}/mo
                  </span>
                </div>
              </div>

              <div className="surface-100 p-3 border-round mb-3">
                <div className="flex justify-content-between align-items-center">
                  <span className="text-gray-700">Bachelor's Degree</span>
                  <span className="text-900 font-medium">
                    {demographics.bachelorsDegree?.toLocaleString() || "N/A"}
                  </span>
                </div>
              </div>

              <div className="surface-100 p-3 border-round">
                <div className="flex justify-content-between align-items-center">
                  <span className="text-gray-700">Median Age</span>
                  <span className="text-900 font-medium">
                    {demographics.medianAge || "N/A"} years
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Simple Disclaimer */}
          <div className="text-center mt-3">
            <span className="text-500 text-sm">
              * Data from US Census Bureau
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default DemographicsData;
