import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const RealEstateCalculatorPDFGenerator = ({ inputs, results, metrics }) => {
  const generatePDF = () => {
    try {
      const doc = new jsPDF();
      const pageWidth = doc.internal.pageSize.getWidth();
      
      // Title and Date
      doc.setFontSize(20);
      doc.text('Real Estate Investment Analysis Report', pageWidth / 2, 15, { align: 'center' });
      
      const date = new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
      doc.setFontSize(12);
      doc.text(date, pageWidth / 2, 25, { align: 'center' });

      // Property Details Section
      doc.setFontSize(16);
      doc.text('Property Details', 14, 35);

      const propertyDetails = [
        ['Property Type', inputs.propertyType],
        ['Purchase Price', new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(inputs.purchasePrice)],
        ['Monthly Rent', new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(inputs.monthlyRent)],
        ['Down Payment', `${inputs.downPayment}%`],
        ['Interest Rate', `${inputs.interestRate}%`]
      ];

      doc.autoTable({
        startY: 40,
        head: [['Parameter', 'Value']],
        body: propertyDetails,
        theme: 'plain',
        headStyles: {
          fillColor: [41, 128, 185],
          textColor: 255,
          fontSize: 10,
          fontStyle: 'bold',
          cellPadding: 6,
        },
        columnStyles: {
          0: { cellWidth: 70 },
          1: { cellWidth: 70 }
        },
        styles: {
          cellPadding: 4,
          fontSize: 10,
          lineColor: [200, 200, 200],
          lineWidth: 0.1,
        },
        margin: { left: 14 }
      });

      // Investment Analysis Results
      doc.text('Investment Analysis Results', 14, doc.lastAutoTable.finalY + 15);

      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 20,
        head: [['Metric', 'Value', 'Description']],
        body: metrics.map(metric => [
          metric.label,
          metric.value,
          metric.description
        ]),
        theme: 'plain',
        headStyles: {
          fillColor: [26, 188, 156],
          textColor: 255,
          fontSize: 10,
          fontStyle: 'bold',
          cellPadding: 6,
        },
        columnStyles: {
          0: { cellWidth: 70 },
          1: { cellWidth: 40 },
          2: { cellWidth: 80 }
        },
        styles: {
          cellPadding: 4,
          fontSize: 10,
          lineColor: [200, 200, 200],
          lineWidth: 0.1,
        },
        margin: { left: 14 }
      });

      // Cash Flow Projection
      doc.addPage();
      doc.setFontSize(16);
      doc.text('Cash Flow Projection', 14, 15);

      const cashFlowData = results.cashFlowData.map(data => [
        data.year,
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(data.cashFlow)
      ]);

      doc.autoTable({
        startY: 20,
        head: [['Year', 'Cash Flow']],
        body: cashFlowData,
        headStyles: {
          fillColor: [41, 128, 185],
          textColor: 255,
          fontSize: 10,
          fontStyle: 'bold'
        },
        columnStyles: {
          0: { cellWidth: 40 },
          1: { cellWidth: 60 }
        },
        styles: {
          cellPadding: 4,
          fontSize: 10
        },
        margin: { left: 14 }
      });

      // Property Value Appreciation
      doc.text('Property Value Appreciation', 14, doc.lastAutoTable.finalY + 15);

      const appreciationData = results.appreciationData.map(data => [
        data.year,
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(data.value)
      ]);

      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 20,
        head: [['Year', 'Property Value']],
        body: appreciationData,
        headStyles: {
          fillColor: [41, 128, 185],
          textColor: 255,
          fontSize: 10,
          fontStyle: 'bold'
        },
        columnStyles: {
          0: { cellWidth: 40 },
          1: { cellWidth: 60 }
        },
        styles: {
          cellPadding: 4,
          fontSize: 10
        },
        margin: { left: 14 }
      });

      // Simple Disclaimer
      doc.setFontSize(10);
      doc.setTextColor(100, 100, 100);
      const disclaimer = "Disclaimer: This analysis is based on assumptions and projections. Please conduct thorough due diligence before making any investment decisions.";
      doc.text(disclaimer, pageWidth / 2, doc.internal.pageSize.getHeight() - 20, { 
        align: 'center',
        maxWidth: pageWidth - 28
      });

      // Add page numbers
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text(`Page ${i} of ${pageCount}`, doc.internal.pageSize.getWidth() - 30, doc.internal.pageSize.getHeight() - 10);
      }

      // Save the PDF
      doc.save(`real-estate-analysis-${new Date().toISOString().split('T')[0]}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <div className="mt-6">
      <button 
        onClick={generatePDF}
        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 flex items-center gap-2"
      >
        <svg 
          className="w-4 h-4" 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24" 
          xmlns="http://www.w3.org/2000/svg"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" 
          />
        </svg>
        Download Investment Report (PDF)
      </button>
    </div>
  );
};

export default RealEstateCalculatorPDFGenerator;