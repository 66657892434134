import React, { useState, useEffect } from "react";
import { ChevronDown, ChevronUp, Calendar } from "lucide-react";
import http from "../http-common";
import { getStatusClassName } from "../common/helpers/functions";
import { formatPrice } from "./helpers/utils";

const LoadingSpinner = () => (
  <div className="flex justify-center p-4">
    <div className="animate-spin rounded-full h-8 w-8 border-4 border-blue-500 border-t-transparent"></div>
  </div>
);

const ProgressBar = ({ loading }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval;
    if (loading) {
      setProgress(0);
      interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 90) {
            clearInterval(interval);
            return 90;
          }
          return prev + 10;
        });
      }, 200);
    } else {
      setProgress(100);
      const timeout = setTimeout(() => setProgress(0), 500);
      return () => clearTimeout(timeout);
    }
    return () => clearInterval(interval);
  }, [loading]);

  if (!loading && progress === 0) return null;

  return (
    <div className="fixed top-0 left-0 right-0 h-1 bg-gray-200 z-50">
      <div
        className="h-full bg-blue-500 transition-all duration-300 ease-out"
        style={{
          width: `${progress}%`,
          transition: loading
            ? "width 300ms ease-in-out"
            : "width 100ms ease-out",
        }}
      />
    </div>
  );
};

const LoadingOverlay = ({ show }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-40">
      <div className="bg-white rounded-lg p-4 flex items-center gap-3">
        <div className="animate-spin rounded-full h-5 w-5 border-2 border-blue-500 border-t-transparent"></div>
        <span className="text-sm text-gray-600">Loading...</span>
      </div>
    </div>
  );
};

const LoadingIndicator = ({ loading }) => {
  if (!loading) return null;

  return (
    <div className="inline-flex items-center gap-2 px-3 py-2 text-sm text-blue-700 font-medium">
      <div className="animate-spin w-4 h-4 border-2 border-blue-600 border-t-transparent rounded-full"></div>
      <span>Loading...</span>
    </div>
  );
};

const AddressGoogleLink = ({ address, children }) => {
  const createGoogleSearchUrl = (address) => {
    const searchQuery = encodeURIComponent(address);
    return `https://www.google.com/search?q=${searchQuery}`;
  };

  return (
    <a
      href={createGoogleSearchUrl(address)}
      target="_blank"
      rel="noopener noreferrer"
      className="text-blue-500 hover:text-blue-600 pl-2"
    >
      {children}
    </a>
  );
};

const AddressGroup = ({ address, expandedGroup, setExpandedGroup }) => {
  const isExpanded = expandedGroup === address.key;
  const occurrences = address.count || address.addresses?.length || 0;

  return (
    <div className="border rounded mb-1 bg-white">
      <div
        className="flex items-center px-3 py-2 cursor-pointer hover:bg-gray-50"
        onClick={() => setExpandedGroup(isExpanded ? null : address.key)}
      >
        <div className="flex-1 min-w-0">
          <div className="text-sm truncate">{address.key}</div>
        </div>
        <div className="flex items-center gap-2">
          <span className="text-sm text-gray-500">
            ({occurrences} occurrences)
          </span>
          {isExpanded ? (
            <ChevronUp className="w-4 h-4 text-gray-400" />
          ) : (
            <ChevronDown className="w-4 h-4 text-gray-400" />
          )}
        </div>
      </div>

      {isExpanded && (
        <div className="px-3 pb-2">
          <table className="w-full text-sm">
            <thead>
              <tr className="text-left border-b">
                <th className="py-1 pr-4 font-medium">Record #</th>
                <th className="py-1 pr-4 font-medium">Address</th>
                <th className="py-1 pr-4 font-medium">Price</th>
                <th className="py-1 pr-4 font-medium w-48">Source</th>
                <th className="py-1 pr-4 font-medium">Created Date</th>
              </tr>
            </thead>
            <tbody>
              {address.addresses?.map((addr, index) => (
                <tr
                  key={index}
                  className={getStatusClassName(addr.status)}
                >
                  <td className="py-1 pr-4">{addr.extractid}</td>
                  <td className="py-1 pr-4">
                    {`${addr.streetNumber} ${addr.street}, ${addr.city}, ${addr.state} ${addr.zip}`}
                    <AddressGoogleLink
                      address={`${addr.streetNumber} ${addr.street} ${addr.city} ${addr.state} ${addr.zipCode}`}
                    >
                      <i className="pi pi-external-link"></i>
                    </AddressGoogleLink>
                  </td>
                  <td className="py-1 pr-4">
                  {formatPrice(addr.price)}
                  </td>
                  <td className="py-1 pr-4 w-48">
                    <div className="truncate">
                      <a href={addr.url} target="_blank">
                        {addr.source}
                      </a>
                      {` - ${addr.listingid?.substring(0, 13)}`}
                    </div>
                  </td>
                  <td className="py-1 pr-4">
                    {new Date(addr.createddate).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const AddressList = () => {
  const [addresses, setAddresses] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalGroups, setTotalGroups] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState("");

  const itemsPerPage = 20;

  const fetchAddresses = async (page) => {
    setLoading(true);
    try {
      let url = `/links/pagedlinks?page=${page}&limit=${itemsPerPage}`;
      if (fromDate) {
        url += `&fromDate=${fromDate}`;
      }

      const response = await http.get(url);
      const { data, total } = response.data;
      setAddresses(data);
      setTotalPages(Math.ceil(total / itemsPerPage));
      setTotalGroups(total);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    } finally {
      setTimeout(() => setLoading(false), 500); // Add small delay for smoother transition
    }
  };

  useEffect(() => {
    fetchAddresses(currentPage);
  }, [currentPage, fromDate]);

  return (
    <>
      <LoadingIndicator loading={loading} />
      <div className="min-h-screen bg-white">
        <div className="px-4 py-3">
          <h1 className="text-xl font-bold mb-4">Duplicate Address Manager</h1>

          <div className="flex flex-col sm:flex-row items-center gap-3 mb-4">
            <div className="w-full sm:w-auto flex items-center gap-2">
              <div className="relative flex-1 sm:flex-none">
                <input
                  type="date"
                  value={fromDate}
                  onChange={(e) => {
                    setFromDate(e.target.value);
                    setCurrentPage(1);
                  }}
                  className="w-full sm:w-48 px-3 py-1.5 border rounded text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Select date..."
                  disabled={loading}
                />
              </div>
              {fromDate && (
                <button
                  onClick={() => {
                    setFromDate("");
                    setCurrentPage(1);
                  }}
                  className="px-3 py-1.5 text-sm text-gray-600 hover:text-gray-800 border rounded hover:bg-gray-50 disabled:opacity-50"
                  disabled={loading}
                >
                  Clear
                </button>
              )}
            </div>
          </div>

          {/* Filter status message */}
          <div className="text-xs text-gray-600 mb-3 flex items-center gap-2">
            {fromDate && (
              <span className="bg-blue-50 text-blue-700 px-2 py-1 rounded-full text-xs font-medium">
                Filtering from: {new Date(fromDate).toLocaleDateString()}
              </span>
            )}
            <span>
              Showing {addresses.length} of {totalGroups} duplicate groups
            </span>
          </div>

          {/* Loading state for the main content */}
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div>
              {addresses.map((address, index) => (
                <AddressGroup
                  key={index}
                  address={address}
                  expandedGroup={expandedGroup}
                  setExpandedGroup={setExpandedGroup}
                />
              ))}
            </div>
          )}

          <div className="flex items-center justify-center gap-2 mt-3 text-sm">
            <button
              className="px-3 py-1 border rounded text-gray-600 disabled:opacity-50 disabled:bg-gray-50"
              disabled={currentPage === 1 || loading}
              onClick={() => setCurrentPage((prev) => prev - 1)}
            >
              Previous
            </button>

            {/* First page */}
            {currentPage > 3 && (
              <>
                <button
                  className="px-3 py-1 border rounded hover:bg-gray-50 disabled:opacity-50"
                  onClick={() => setCurrentPage(1)}
                  disabled={loading}
                >
                  1
                </button>
                {currentPage > 4 && <span className="text-gray-400">...</span>}
              </>
            )}

            {/* Page numbers */}
            {Array.from({ length: totalPages }, (_, i) => i + 1)
              .filter((page) => Math.abs(currentPage - page) <= 2)
              .map((page) => (
                <button
                  key={page}
                  className={`px-3 py-1 border rounded ${
                    currentPage === page
                      ? "bg-blue-50 text-blue-600 border-blue-200"
                      : "hover:bg-gray-50"
                  } disabled:opacity-50`}
                  onClick={() => setCurrentPage(page)}
                  disabled={loading}
                >
                  {page}
                </button>
              ))}

            {/* Last page */}
            {currentPage < totalPages - 2 && (
              <>
                {currentPage < totalPages - 3 && (
                  <span className="text-gray-400">...</span>
                )}
                <button
                  className="px-3 py-1 border rounded hover:bg-gray-50 disabled:opacity-50"
                  onClick={() => setCurrentPage(totalPages)}
                  disabled={loading}
                >
                  {totalPages}
                </button>
              </>
            )}

            <button
              className="px-3 py-1 border rounded text-gray-600 disabled:opacity-50 disabled:bg-gray-50"
              disabled={currentPage === totalPages || loading}
              onClick={() => setCurrentPage((prev) => prev + 1)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressList;
