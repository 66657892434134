import React, { useEffect, useState } from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const ReturnAnalysis = ({ returnMetrics, propertyDetails, calculations, financialParams }) => {
  const [metrics, setMetrics] = useState(returnMetrics);

    useEffect(() => {
        setMetrics(returnMetrics);
    }, [returnMetrics]);

    const chartData = [{
        name: 'Return Composition',
        cashflow: metrics?.cashflowReturn || 0,
        appreciation: metrics?.appreciationReturn || 0,
        equity: metrics?.equityReturn || 0
    }];

    const formatCurrency = (value) => {
      if (!value) return '$0';
      return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
      }).format(value);
  };

  const formatPercent = (value) => {
      if (!value) return '0.00%';
      return `${value.toFixed(2)}%`;
  };

  const formatNumber = (value) => {
      if (!value) return '0.00';
      return value.toFixed(2);
  };

    // Calculate additional financial ratios
    const calculateFinancialRatios = () => {
      const monthlyRent = propertyDetails?.monthlyRent || 0;
      const purchasePrice = propertyDetails?.purchasePrice || 0;
      const annualRent = monthlyRent * 12;

      return {
          rentToValue: (monthlyRent / purchasePrice) * 100,
          grossRentMultiplier: purchasePrice / annualRent,
          debtCoverageRatio: calculations?.dscr || 0,
          debtYield: financialParams?.interestRate || 0,
          breakEvenRatio: calculations?.breakEvenRatio || 0,
          operatingExpenseRatio: calculations?.expenseRatio || 0,
          rentPerSqFt: monthlyRent / (propertyDetails?.squareFeet || 1),
          pricePerSqFt: purchasePrice / (propertyDetails?.squareFeet || 1)
      };
  };

  const financialRatios = calculateFinancialRatios();


    return (
        <div className="surface-card p-4 border-round shadow-1">
            <div className="flex justify-content-between align-items-center mb-4">
                <h2 className="text-xl m-0 font-medium">Return Analysis</h2>
            </div>
            <div className="surface-card p-3 border-round">
                {/* Key Return Metrics */}
                <div className="grid">
                    <div className="col-12 md:col-3 p-2">
                        <div className="bg-gray-50 p-3 border-round">
                            <span className="block text-600 text-sm mb-2">IRR (30-Year)</span>
                            <span className="text-900 text-xl">
                                {formatPercent(metrics?.irr)}
                            </span>
                        </div>
                    </div>
                    <div className="col-12 md:col-3 p-2">
                        <div className="bg-gray-50 p-3 border-round">
                            <span className="block text-600 text-sm mb-2">Equity Multiple</span>
                            <span className="text-900 text-xl">
                                {(metrics?.equityMultiple || 0).toFixed(2)}x
                            </span>
                        </div>
                    </div>
                    <div className="col-12 md:col-3 p-2">
                        <div className="bg-gray-50 p-3 border-round">
                            <span className="block text-600 text-sm mb-2">Payback Period</span>
                            <span className="text-900 text-xl">
                                {metrics?.paybackPeriod || 0} years
                            </span>
                        </div>
                    </div>
                    <div className="col-12 md:col-3 p-2">
                        <div className="bg-gray-50 p-3 border-round">
                            <span className="block text-600 text-sm mb-2">Total Return</span>
                            <span className="text-900 text-xl">
                                {formatCurrency(metrics?.totalReturn)}
                            </span>
                        </div>
                    </div>
                </div>

                

                {/* Add new Financial Ratios section */}
           
                <div className="grid mt-4">
                    {/* Property Performance Ratios */}
                    <div className="col-12 md:col-6">
                        <div className="p-3 border-1 border-round surface-border h-full">
                            <h4 className="text-md font-medium mb-3">Property Performance</h4>
                            <div className="grid">
                                <div className="col-12 p-2">
                                    <div className="flex justify-content-between">
                                        <span className="text-600">Rent to Value</span>
                                        <span className="text-900">{formatPercent(financialRatios.rentToValue)}</span>
                                    </div>
                                </div>
                                <div className="col-12 p-2">
                                    <div className="flex justify-content-between">
                                        <span className="text-600">Gross Rent Multiplier</span>
                                        <span className="text-900">{formatNumber(financialRatios.grossRentMultiplier)}</span>
                                    </div>
                                </div>
                                <div className="col-12 p-2">
                                    <div className="flex justify-content-between">
                                        <span className="text-600">Operating Expense Ratio</span>
                                        <span className="text-900">{formatPercent(financialRatios.operatingExpenseRatio)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Debt & Coverage Ratios */}
                    <div className="col-12 md:col-6">
                        <div className="p-3 border-1 border-round surface-border h-full">
                            <h4 className="text-md font-medium mb-3">Debt & Coverage</h4>
                            <div className="grid">
                                <div className="col-12 p-2">
                                    <div className="flex justify-content-between">
                                        <span className="text-600">Debt Coverage Ratio</span>
                                        <span className={`text-900 ${financialRatios.debtCoverageRatio < 1.2 ? 'text-red-600' : 'text-green-600'}`}>
                                            {formatNumber(financialRatios.debtCoverageRatio)}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 p-2">
                                    <div className="flex justify-content-between">
                                        <span className="text-600">Debt Yield</span>
                                        <span className="text-900">{formatPercent(financialRatios.debtYield)}</span>
                                    </div>
                                </div>
                                <div className="col-12 p-2">
                                    <div className="flex justify-content-between">
                                        <span className="text-600">Break Even Ratio</span>
                                        <span className="text-900">{formatPercent(financialRatios.breakEvenRatio)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>

                {/* Return Composition Chart */}
                <div className="mt-4">
                    <h3 className="text-lg font-medium mb-3">Return Composition</h3>
                    <div style={{ width: '100%', height: '300px' }}>
                        <ResponsiveContainer>
                            <BarChart 
                                data={chartData}
                                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis 
                                    tickFormatter={(value) => `${value}%`}
                                />
                                <Tooltip 
                                    formatter={(value) => [`${value.toFixed(2)}%`, '']}
                                    contentStyle={{ fontSize: 12 }}
                                />
                                <Legend />
                                <Bar 
                                    dataKey="cashflow" 
                                    stackId="a" 
                                    fill="#4338ca" 
                                    name="Cash Flow Return"
                                />
                                <Bar 
                                    dataKey="appreciation" 
                                    stackId="a" 
                                    fill="#16a34a" 
                                    name="Appreciation Return"
                                />
                                <Bar 
                                    dataKey="equity" 
                                    stackId="a" 
                                    fill="#eab308" 
                                    name="Equity Return"
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                {/* Optional Help Text */}
                <div className="text-center mt-3">
                    <span className="text-500 text-xs">* Based on projected cash flows and appreciation</span>
                </div>
            </div>
        </div>
    );
};

export default ReturnAnalysis;