import React, { useState, useEffect } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputNumber } from "primereact/inputnumber";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primeicons/primeicons.css";

function CashOnCashReturnCalculator({
  purchaseprice,
  rentalestimate,
  selectedRowData,
  onCashOnCashReturnChange,
}) {
  const [purchasePrice, setPurchasePrice] = useState(purchaseprice || 0);
  const [downPaymentPercent, setDownPaymentPercent] = useState();
  const [monthlyRent, setMonthlyRent] = useState(rentalestimate || 0);
  const [interestRate, setInterestRate] = useState(
    selectedRowData?.latestMortgageRates?.pmms30 || 7.5
  );
  const [propertyTaxRate, setPropertyTaxRate] = useState(
    parseFloat(
      selectedRowData?.propertyTaxes?.Property_Tax_Rate?.replace("%", "")
    ) || 1.1
  );
  const [loanTermYears, setLoanTermYears] = useState();
  const [hoafee, setHOAFee] = useState(selectedRowData?.hoa || 0);
  const [maintenancePercent, setMaintenancePercent] = useState(10);
  const [operatingExpensePercent, setOperatingExpensePercent] = useState(10);
  const [vacancyRatePercent, setVacancyRatePercent] = useState(
    selectedRowData?.rental_vacancy_rate || 6
  );
  const [cashOnCashReturn, setCashOnCashReturn] = useState(null);
  const [annualPreTaxCashFlow, setAnnualPreTaxCashFlow] = useState(null);
  const [holdingPeriodYears, setHoldingPeriodYears] = useState();
  const [appreciationRate, setAppreciationRate] = useState(3);
  const [sellingCostsPercent, setSellingCostsPercent] = useState(6);
  const [insuranceRatePercent, setInsuranceRatePercent] = useState(0.005);
  const [roi, setROI] = useState(null);

  useEffect(() => {
    setPurchasePrice(purchaseprice || 0);
    setMonthlyRent(rentalestimate || 0);
    const savedSettings = JSON.parse(localStorage.getItem("settings"));
    setDownPaymentPercent(savedSettings?.downpayment ?? 25);
    setLoanTermYears(savedSettings?.loanterm ?? 30);
    setHoldingPeriodYears((savedSettings?.holdPeriod ?? 0) || 5);
    setVacancyRatePercent(selectedRowData?.rental_vacancy_rate || 6);
    setHOAFee(selectedRowData?.hoa || 0);
  }, [purchaseprice, rentalestimate, vacancyRatePercent]);

  useEffect(() => {
    const calculateReturns = () => {
      const downPayment = purchasePrice * (downPaymentPercent / 100);
      const loanAmount = purchasePrice - downPayment;
      const monthlyInterestRate = interestRate / 12 / 100;
      const numberOfPayments = loanTermYears * 12;

      const monthlyMortgagePayment =
        (loanAmount * monthlyInterestRate) /
        (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));

      const grossAnnualRent = monthlyRent * 12;
      const annualVacancyLoss = grossAnnualRent * (vacancyRatePercent / 100);
      const netAnnualRent = grossAnnualRent - annualVacancyLoss;

      const annualMortgagePayment = monthlyMortgagePayment * 12;
      const annualMaintenanceCosts =
        monthlyRent * (maintenancePercent / 100) * 12;
      const annualOperatingExpenses =
        monthlyRent * (operatingExpensePercent / 100) * 12;

      const annualHOACosts = hoafee * 12;
      const annualPropertyTax = (purchasePrice * propertyTaxRate) / 100;
      // Insurance is 0.5% of purchase price annually
      const annualInsurance = purchasePrice * insuranceRatePercent;

      const annualPreTaxCashFlow =
        netAnnualRent -
        annualMortgagePayment -
        annualMaintenanceCosts -
        annualOperatingExpenses -
        annualHOACosts -
        annualPropertyTax -
        annualInsurance;

      const coC = (annualPreTaxCashFlow / downPayment) * 100;
      setAnnualPreTaxCashFlow(annualPreTaxCashFlow);
      setCashOnCashReturn(coC.toFixed(2));

      // ROI calculation
      const sellingPrice =
        purchasePrice *
        Math.pow(1 + appreciationRate / 100, holdingPeriodYears);
      const sellingCosts = sellingPrice * (sellingCostsPercent / 100);

      const totalIncome =
        annualPreTaxCashFlow * holdingPeriodYears +
        (sellingPrice - sellingCosts);
      const totalInvestmentCost =
        downPayment +
        annualMortgagePayment * holdingPeriodYears +
        annualMaintenanceCosts * holdingPeriodYears +
        annualOperatingExpenses * holdingPeriodYears;

      const roi =
        ((totalIncome - totalInvestmentCost) / totalInvestmentCost) * 100;
      setROI(roi.toFixed(2));
    };

    calculateReturns();
  }, [
    purchasePrice,
    downPaymentPercent,
    monthlyRent,
    interestRate,
    loanTermYears,
    maintenancePercent,
    operatingExpensePercent,
    vacancyRatePercent,
    holdingPeriodYears,
    appreciationRate,
    sellingCostsPercent,
    hoafee,
  ]);

  useEffect(() => {
    // Update the value in the host component when cashOnCashReturn or annualPreTaxCashFlow changes
    if (
      cashOnCashReturn !== null &&
      annualPreTaxCashFlow !== null &&
      roi !== null
    ) {
      onCashOnCashReturnChange(cashOnCashReturn, annualPreTaxCashFlow, roi);
    }
  }, [cashOnCashReturn, annualPreTaxCashFlow, roi]);

  return (
    <div>
      <Accordion>
        <AccordionTab
          header={`Analyze Cash on Cash Return${
            cashOnCashReturn !== null ? ` - ${cashOnCashReturn}%` : ""
          }`}
        >
          <div className="p-grid p-justify-center">
            <div className="p-col">
              <div className="form-row">
                <label htmlFor="purchasePrice">Purchase Price</label>
                <InputNumber
                  id="purchasePrice"
                  value={purchasePrice}
                  onValueChange={(e) => setPurchasePrice(e.value)}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                />
              </div>
            </div>
            <div className="p-col">
              <div className="form-row">
                <label htmlFor="downPaymentPercent">Down Payment (%)</label>
                <InputNumber
                  id="downPaymentPercent"
                  value={downPaymentPercent}
                  onValueChange={(e) => setDownPaymentPercent(e.value)}
                  mode="decimal"
                  minFractionDigits={0}
                />
              </div>
            </div>
            <div className="p-col">
              <div className="form-row">
                <label htmlFor="monthlyRent">Monthly Rent</label>
                <InputNumber
                  id="monthlyRent"
                  value={monthlyRent}
                  onValueChange={(e) => setMonthlyRent(e.value)}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                />
              </div>
            </div>
            <div className="p-col">
              <div className="form-row">
                <label htmlFor="HOAFee">Monthly HOA</label>
                <InputNumber
                  id="HOAFee"
                  value={hoafee}
                  onValueChange={(e) => setHOAFee(e.value)}
                  mode="decimal"
                  minFractionDigits={0}
                />
              </div>
            </div>
            <div className="p-col">
              <div className="form-row">
                <label htmlFor="interestRate">Interest Rate (%)</label>
                <InputNumber
                  id="interestRate"
                  value={interestRate}
                  onValueChange={(e) => setInterestRate(e.value)}
                  mode="decimal"
                  minFractionDigits={2}
                />
              </div>
            </div>
            <div className="p-col">
              <div className="form-row">
                <label htmlFor="loanTermYears">Loan Term (Years)</label>
                <InputNumber
                  id="loanTermYears"
                  value={loanTermYears}
                  onValueChange={(e) => setLoanTermYears(e.value)}
                  mode="decimal"
                  minFractionDigits={0}
                />
              </div>
            </div>
            <div className="p-col">
              <div className="form-row">
                <label htmlFor="propertyTaxRate">Property Taxes (%)</label>
                <InputNumber
                  id="propertyTaxRate"
                  value={propertyTaxRate}
                  onValueChange={(e) => setPropertyTaxRate(e.value)}
                />
              </div>
            </div>
            <div className="p-col">
              <div className="form-row">
                <label htmlFor="insuranceRate">Insurance (%)</label>
                <InputNumber
                  id="insuranceRate"
                  value={insuranceRatePercent}
                  onValueChange={(e) => setInsuranceRatePercent(e.value)}
                />
              </div>
            </div>
            <div className="p-col">
              <div className="form-row">
                <label htmlFor="maintenancePercent">
                  Maintenance Cost (% of Rent)
                </label>
                <InputNumber
                  id="maintenancePercent"
                  value={maintenancePercent}
                  onValueChange={(e) => setMaintenancePercent(e.value)}
                  mode="decimal"
                  minFractionDigits={0}
                />
              </div>
            </div>
            <div className="p-col">
              <div className="form-row">
                <label htmlFor="operatingExpensePercent">
                  Operating Expense (% of Rent)
                </label>
                <InputNumber
                  id="operatingExpensePercent"
                  value={operatingExpensePercent}
                  onValueChange={(e) => setOperatingExpensePercent(e.value)}
                  mode="decimal"
                  minFractionDigits={0}
                />
              </div>
            </div>
            <div className="p-col">
              <div className="form-row">
                <label htmlFor="vacancyRatePercent">Vacancy Rate (%)</label>
                <InputNumber
                  id="vacancyRatePercent"
                  value={vacancyRatePercent}
                  onValueChange={(e) => setVacancyRatePercent(e.value)}
                  mode="decimal"
                  minFractionDigits={0}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab
          header={`Calculate Full ROI Analysis${
            roi !== null ? ` - ${roi}%` : ""
          }`}
        >
          <div className="p-grid p-justify-center">
            <div className="p-col">
              <div className="form-row">
                <label htmlFor="holdingPeriodYears">
                  Holding Period (Years)
                </label>
                <InputNumber
                  id="holdingPeriodYears"
                  value={holdingPeriodYears}
                  onValueChange={(e) => setHoldingPeriodYears(e.value)}
                  mode="decimal"
                  minFractionDigits={0}
                />
              </div>
            </div>
            <div className="p-col">
              <div className="form-row">
                <label htmlFor="appreciationRate">Appreciation Rate (%)</label>
                <InputNumber
                  id="appreciationRate"
                  value={appreciationRate}
                  onValueChange={(e) => setAppreciationRate(e.value)}
                  mode="decimal"
                  minFractionDigits={2}
                />
              </div>
            </div>
            <div className="p-col">
              <div className="form-row">
                <label htmlFor="sellingCostsPercent">Selling Costs (%)</label>
                <InputNumber
                  id="sellingCostsPercent"
                  value={sellingCostsPercent}
                  onValueChange={(e) => setSellingCostsPercent(e.value)}
                  mode="decimal"
                  minFractionDigits={0}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  );
}

export default CashOnCashReturnCalculator;
